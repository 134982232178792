import {
    // ArrowPathIcon,
    PlusCircleIcon,
    XMarkIcon,
    ArrowLongLeftIcon
  } from "@heroicons/react/24/solid";
import { useState, useEffect } from "react";
// import { AppIndicator } from "react-bootstrap-icons";
import { useNavigate, useLocation } from "react-router-dom";
import { TextInput, SelectInput, DateInput } from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { FilterSection, PrimaryTable } from "../components/Tables";
import { useLoaderStore, useUserStore } from "../context";
import { useAlert, useApi, useDebounce } from "../hooks";
import constants from "../utils/constants";
import dateHelpers from "../utils/dateHelpers";
import { switchSortDirection } from "../utils/helpers";
import _ from "lodash";

export default function BusinessFollowupHistories() {
    const alert = useAlert();
    const { post } = useApi();
    const { setShowLoader } = useLoaderStore();
    // const { currentUser } = useUserStore();
    const navigate = useNavigate();
    const location = useLocation();
    const [filters, setFilters] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortDirection, setSortDirection] = useState(constants.SORT_DIRECTIONS.ASCENDING);
    const [sortField, setSortField] = useState("name");
    const [displayCount, setDisplayCount] = useState(constants.DISPLAY_PER_PAGE ?? 25);
    // const [states, setStates] = useState([]);
    const [pagedData, setPagedData] = useState(null);
    const { debounced: debouncedFilters } = useDebounce({ toDebounce: filters });
    const { debounced: debouncedDisplayCount } = useDebounce({
        toDebounce: displayCount,
    });
    // const { id, businessName } = useParams();
    const {id, businessName} = location.state;

    const followupStatuses = [
        {label: "In Process", value: 1}, 
        {label: "Declined", value: 2}, 
        {label: "No Authorized Personnel Available", value: 3}
    ];


    useEffect(() => {
        refreshList();
    }, [sortDirection, sortField, debouncedFilters, currentPage, debouncedDisplayCount]);


    function refreshList() {
        if (isLoading) return;
        setIsLoading(true);
        post("Business/GetBusinessFollowupHistories", {
            businessFollowupId: id,
            pageNumber: currentPage,
            pageSize: debouncedDisplayCount,
            sortDirection,
            sortField,
            isExact: false,
            ...debouncedFilters
        })
        .then(response => {            
            setPagedData(response);
        })
        .catch((err) => {
            alert("Error", err.statusText || "Error While Atempting To Refresh Session Data", "error");
            console.error(err);
        })
        .finally(() => {
            setIsLoading(false);
        })
    }

    const handleSort = (field) => {
        if (sortField === field) {
          setSortDirection(switchSortDirection(sortDirection));
        } else {
          setSortField(field);
        }
    }




    return (
        <div>
            {/* Header */}
            <div className="flex justify-between">
              <PageHeader title={`Manage Business Followups For ${businessName}`}/>
              <PlusCircleIcon 
                className="w-8 text-primaryColor hover:text-primaryColor/50 cursor-pointer"
                onClick={() => navigate("/businessFollowupHistory", {state: {id: 0, businessId: id, businessName: businessName}})}
              />
            </div>
            <div className="flex cursor-pointer hover:opacity-70 text-primaryColor my-2" onClick={() => navigate(`/businessFollowups`)}>
             <ArrowLongLeftIcon className="w-5 mr-2" /> Manage followups
            </div>

            {/* Filter Section */}
            <BusinessFollowupHistoryFilterSection filters={filters} setFilters={setFilters} followupStatuses={followupStatuses}/>

            <PrimaryTable
              isLoading={isLoading}
              filters={filters}
              totalCount={pagedData?.totalCount}
              displayCount={displayCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setDisplayCount={setDisplayCount}
              header={
                <PrimaryTable.Header>
                    <PrimaryTable.HeaderColumn 
                    display="Contacted By"                                  
                    />
                    <PrimaryTable.HeaderColumn
                    display="Contacted At"
                    value={filters.contactedAt}
                    onSort={() => handleSort("contactedAt")}
                    isSortingBy={sortField === "contactedAt"}
                    sortDirection={sortDirection}
                    />
                    <PrimaryTable.HeaderColumn
                    display="Followup Status"
                    onSort={() => handleSort("businessFollowupStatusId")}
                    isSortingBy={sortField === "businessFollowupStatusId"}
                    sortDirection={sortDirection}           
                    />
                    <PrimaryTable.HeaderColumn
                    display="Comments"
                    />
                </PrimaryTable.Header>
              }
            >
                {pagedData && pagedData.list ? (
                    _.map(pagedData.list, (x, index) => {                        
                        return (
                            <PrimaryTable.Row key={index} onClick={() => navigate(`/businessFollowupHistory`, {state: {id: x.id, businessId: id, businessName: businessName}})}>
                                <PrimaryTable.Cell>{x.contactedBy}</PrimaryTable.Cell>
                                <PrimaryTable.Cell>{dateHelpers.monthDayAndYear(x.contactedAt)}</PrimaryTable.Cell>
                                <PrimaryTable.Cell>{x.businessFollowupStatusDescription}</PrimaryTable.Cell>
                                <PrimaryTable.Cell>{x.comments}</PrimaryTable.Cell>
                            </PrimaryTable.Row>
                        );
                    })
                ) : null}
            </PrimaryTable>
        </div>                
    );
} 

const BusinessFollowupHistoryFilterSection = ({filters, setFilters, followupStatuses}) => {
    return (
        <FilterSection filters={filters}> 
            <div>
                <div className="mb-2">
                  <TextInput 
                    label="User Contacted By First or Last Name"
                    placeholder="User Contacted By First or Last Name"
                    value={filters.contactedBy}
                    onChange={(e) => {
                        setFilters({
                            ...filters,
                            contactedBy: e.target.value
                        })
                    }}
                    />
                </div>
                <div className="mb-2 flex">
                    <div style={{width: "90%"}}>
                        <SelectInput
                        label="Followup Status"
                        placeholder="Followup Status"
                        value={filters.businessFollowupStatusDescription}
                        options={followupStatuses}
                        onChange={(opt) => {
                            setFilters({
                                ...filters,
                            businessFollowupStatusDescription: opt,
                            businessFollowupStatus: opt.value
                            })                                      
                        }}
                        onClear={() => {
                            setFilters({
                                ...filters,
                                businessFollowupStatusDescription: null,
                                businessFollowupStatus: null
                            })
                        }}
                        />
                    </div>
                    <div style={{width: "10%"}}>
                        <XMarkIcon 
                            className="h-4 w-4 text-gray-400 mr-2 cursor-pointer mb-2"
                            onClick={() => {
                                setFilters({
                                    ...filters,
                                businessFollowupStatusDescription: null,
                                businessFollowupStatus: null
                                })
                            }}
                        />
                    </div>
                </div>
                <div className="my-5 flex">
                    <div style={{width: "90%"}}>
                        <DateInput 
                        label="Contacted At From"
                        value={filters.contactedAtFrom}                
                        onChange={(e) => {
                            setFilters({
                                ...filters,
                                contactedAtFrom: e.target.value
                            })
                        }}
                        />
                    </div>
                    <div style={{width: "10%"}}>
                        <XMarkIcon 
                            className="h-4 w-4 text-gray-400 mr-2 cursor-pointer mb-2"
                            onClick={() => {
                                setFilters({
                                    ...filters,
                                    contactedAtFrom: null
                                })
                            }}
                        />
                    </div>
                </div>
                <div className="my-5 flex">
                    <div style={{width: "90%"}}>
                        <DateInput 
                        label="Contacted At To"
                        value={filters.contactedAtTo}
                        onChange={(e) => {
                            setFilters({
                                ...filters,
                                contactedAtTo: e.target.value
                            })
                        }}
                        />
                    </div>
                    <div style={{width: "10%"}}>
                        <XMarkIcon 
                            className="h-4 w-4 text-gray-400 mr-2 cursor-pointer mb-2"
                            onClick={() => {
                                setFilters({
                                    ...filters,
                                   contactedAtTo: null
                                })
                            }}
                        />
                    </div>
                </div>
            </div>
        </FilterSection>
    );
}