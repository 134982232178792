import {
  ArrowPathIcon,
  PlusCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { TextInput, ToggleInput } from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { FilterSection, PrimaryTable } from "../components/Tables";
import { useLoaderStore } from "../context";
import { useAlert, useApi, useDebounce } from "../hooks";
import constants from "../utils/constants";
import dateHelpers from "../utils/dateHelpers";
import { switchSortDirection } from "../utils/helpers";

export default function Customers() {
  const navigate = useNavigate();
  const alert = useAlert();
  const { post, fetch } = useApi();
  const { setShowLoader } = useLoaderStore();

  const [filters, setFilters] = useState({ activeOnly: true });
  const [currentPage, setCurrentPage] = useState(1);
  const [sortDirection, setSortDirection] = useState(
    constants.SORT_DIRECTIONS.ASCENDING
  );
  const [sortField, setSortField] = useState("id");
  const [displayCount, setDisplayCount] = useState(
    constants.DISPLAY_PER_PAGE ?? 25
  );

  const { debounced: debouncedFilters } = useDebounce({ toDebounce: filters });
  const { debounced: debouncedDisplayCount } = useDebounce({
    toDebounce: displayCount,
  });

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    [
      "customer/customerList",
      currentPage,
      debouncedDisplayCount,
      debouncedFilters,
      sortDirection,
      sortField,
    ],
    ([
      url,
      currentPage,
      debouncedDisplayCount,
      debouncedFilters,
      sortDirection,
      sortField,
    ]) =>
      fetch(url, {
        pageNumber: currentPage,
        pageSize: debouncedDisplayCount,
        sortDirection,
        sortField,
        isExact: false,
        ...debouncedFilters,
      })
  );

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(switchSortDirection(sortDirection));
    } else {
      setSortField(field);
    }
  };

const toggleActiveStatus = (customer) => {
    setShowLoader(true);
    post(`customer/toggleCustomerActive/${customer.id}`)
      .then((res) => {
        alert("Success",
          res.message || "Customer active status toggled",
          "success");
        mutate();
      })
      .catch((err) => {
        alert("Error",
          err.data.message || "Customer active status could not be toggled",
          "error");
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
};
  // Insert toggleActiveStatus here

  return (
    <div>
      {/* Header */}
      <div className="flex justify-between">
        <PageHeader title="Manage Customers" />
        <PlusCircleIcon
          className="w-8 text-sandyOrange hover:text-yellow/50 cursor-pointer"
          onClick={() => navigate("/customers/0")}
        />
      </div>

      {/* Filter section */}
      <CustomerFilterSection filters={filters} setFilters={setFilters} />

      {/* Table */}
      <PrimaryTable
        isLoading={isLoading}
        filters={filters}
        totalCount={data?.totalCount}
        displayCount={displayCount}
        currentPage={currentPage}
        setDisplayCount={setDisplayCount}
        setCurrentPage={setCurrentPage}
        header={
          <PrimaryTable.Header>
            <PrimaryTable.HeaderColumn
              display="Id"
              value={filters.id}
              sortable
              onSort={() => handleSort("id")}
              isSortingBy={sortField === "id"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, id: "" })}
              onType={(e) => setFilters({ ...filters, id: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="First Name"
              value={filters.firstName}
              sortable
              onSort={() => handleSort("firstName")}
              isSortingBy={sortField === "firstName"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, firstName: "" })}
              onType={(e) => setFilters({ ...filters, firstName: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Last Name"
              value={filters.lastName}
              sortable
              onSort={() => handleSort("lastName")}
              isSortingBy={sortField === "lastName"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, lastName: "" })}
              onType={(e) => setFilters({ ...filters, lastName: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Username"
              value={filters.username}
              sortable
              onSort={() => handleSort("username")}
              isSortingBy={sortField === "username"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, username: "" })}
              onType={(e) => setFilters({ ...filters, username: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Email"
              value={filters.email}
              sortable
              onSort={() => handleSort("email")}
              isSortingBy={sortField === "email"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, email: "" })}
              onType={(e) => setFilters({ ...filters, email: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Phone"
              value={filters.phone}
              sortable
              onSort={() => handleSort("phone")}
              isSortingBy={sortField === "phone"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, phone: "" })}
              onType={(e) => setFilters({ ...filters, phone: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Timezone"
              value={filters.timezone}
              sortable
              onSort={() => handleSort("timezone")}
              isSortingBy={sortField === "timezone"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, timezone: "" })}
              onType={(e) => setFilters({ ...filters, timezone: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Hashed Password"
              value={filters.hashedPassword}
              sortable
              onSort={() => handleSort("hashedPassword")}
              isSortingBy={sortField === "hashedPassword"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, hashedPassword: "" })}
              onType={(e) => setFilters({ ...filters, hashedPassword: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Password Salt"
              value={filters.passwordSalt}
              sortable
              onSort={() => handleSort("passwordSalt")}
              isSortingBy={sortField === "passwordSalt"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, passwordSalt: "" })}
              onType={(e) => setFilters({ ...filters, passwordSalt: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Reset Password Token"
              value={filters.resetPasswordToken}
              sortable
              onSort={() => handleSort("resetPasswordToken")}
              isSortingBy={sortField === "resetPasswordToken"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, resetPasswordToken: "" })}
              onType={(e) => setFilters({ ...filters, resetPasswordToken: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Reset Password Token Requested At"
              value={filters.resetPasswordTokenRequestedAt}
              sortable
              onSort={() => handleSort("resetPasswordTokenRequestedAt")}
              isSortingBy={sortField === "resetPasswordTokenRequestedAt"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, resetPasswordTokenRequestedAt: "" })}
              onType={(e) => setFilters({ ...filters, resetPasswordTokenRequestedAt: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Reset Password Token Expires At"
              value={filters.resetPasswordTokenExpiresAt}
              sortable
              onSort={() => handleSort("resetPasswordTokenExpiresAt")}
              isSortingBy={sortField === "resetPasswordTokenExpiresAt"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, resetPasswordTokenExpiresAt: "" })}
              onType={(e) => setFilters({ ...filters, resetPasswordTokenExpiresAt: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Last Locked Out At"
              value={filters.lastLockedOutAt}
              sortable
              onSort={() => handleSort("lastLockedOutAt")}
              isSortingBy={sortField === "lastLockedOutAt"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, lastLockedOutAt: "" })}
              onType={(e) => setFilters({ ...filters, lastLockedOutAt: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Failed Attempt Count"
              value={filters.failedAttemptCount}
              sortable
              onSort={() => handleSort("failedAttemptCount")}
              isSortingBy={sortField === "failedAttemptCount"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, failedAttemptCount: "" })}
              onType={(e) => setFilters({ ...filters, failedAttemptCount: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Must Change Password"
              value={filters.mustChangePassword}
              sortable
              onSort={() => handleSort("mustChangePassword")}
              isSortingBy={sortField === "mustChangePassword"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, mustChangePassword: "" })}
              onType={(e) => setFilters({ ...filters, mustChangePassword: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Password Last Changed At"
              value={filters.passwordLastChangedAt}
              sortable
              onSort={() => handleSort("passwordLastChangedAt")}
              isSortingBy={sortField === "passwordLastChangedAt"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, passwordLastChangedAt: "" })}
              onType={(e) => setFilters({ ...filters, passwordLastChangedAt: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Created By User"
              value={filters.createdByUser}
              sortable
              onSort={() => handleSort("createdByUser")}
              isSortingBy={sortField === "createdByUser"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, createdByUser: "" })}
              onType={(e) => setFilters({ ...filters, createdByUser: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Last Updated At"
              value={filters.lastUpdatedAt}
              sortable
              onSort={() => handleSort("lastUpdatedAt")}
              isSortingBy={sortField === "lastUpdatedAt"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, lastUpdatedAt: "" })}
              onType={(e) => setFilters({ ...filters, lastUpdatedAt: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Last Updated By User"
              value={filters.lastUpdatedByUser}
              sortable
              onSort={() => handleSort("lastUpdatedByUser")}
              isSortingBy={sortField === "lastUpdatedByUser"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, lastUpdatedByUser: "" })}
              onType={(e) => setFilters({ ...filters, lastUpdatedByUser: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Deactivated By User"
              value={filters.deactivatedByUser}
              sortable
              onSort={() => handleSort("deactivatedByUser")}
              isSortingBy={sortField === "deactivatedByUser"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, deactivatedByUser: "" })}
              onType={(e) => setFilters({ ...filters, deactivatedByUser: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Last Logged On At"
              value={filters.lastLoggedOnAt}
              sortable
              onSort={() => handleSort("lastLoggedOnAt")}
              isSortingBy={sortField === "lastLoggedOnAt"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, lastLoggedOnAt: "" })}
              onType={(e) => setFilters({ ...filters, lastLoggedOnAt: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Marriage Status"
              value={filters.marriageStatus}
              sortable
              onSort={() => handleSort("marriageStatus")}
              isSortingBy={sortField === "marriageStatus"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, marriageStatus: "" })}
              onType={(e) => setFilters({ ...filters, marriageStatus: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Customer Type"
              value={filters.customerType}
              sortable
              onSort={() => handleSort("customerType")}
              isSortingBy={sortField === "customerType"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, customerType: "" })}
              onType={(e) => setFilters({ ...filters, customerType: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Biography"
              value={filters.biography}
              sortable
              onSort={() => handleSort("biography")}
              isSortingBy={sortField === "biography"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, biography: "" })}
              onType={(e) => setFilters({ ...filters, biography: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Date Of Birth"
              value={filters.dateOfBirth}
              sortable
              onSort={() => handleSort("dateOfBirth")}
              isSortingBy={sortField === "dateOfBirth"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, dateOfBirth: "" })}
              onType={(e) => setFilters({ ...filters, dateOfBirth: e.target.value })}
            />
            {/* Insert column headers here */}
            <PrimaryTable.HeaderColumn display="" />
          </PrimaryTable.Header>
        }
      >
        {data &&
          data.list &&
          data.list.map((x, i) => (
            <PrimaryTable.Row
              key={`${x.id}-${i}`}
              index={i}
              onClick={(e) => {
                if (!x.deactivatedAt) {
                  navigate(`/customers/${x.id}`);
                }
              }}
            >
              <PrimaryTable.Cell>{x.id}</PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {x.firstName}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {x.lastName}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {x.username}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {x.email}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {x.phone}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {x.timezone}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {x.hashedPassword}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {x.passwordSalt}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {x.resetPasswordToken}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {dateHelpers.monthDayAndYear(x.resetPasswordTokenRequestedAt)}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {dateHelpers.monthDayAndYear(x.resetPasswordTokenExpiresAt)}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {dateHelpers.monthDayAndYear(x.lastLockedOutAt)}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {x.failedAttemptCount}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {x.mustChangePassword ? "true" : "false"}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {x.passwordLastChangedAt}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {x.createdByUser}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {dateHelpers.monthDayAndYear(x.lastUpdatedAt)}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {x.lastUpdatedByUser}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {x.deactivatedByUser}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {dateHelpers.monthDayAndYear(x.lastLoggedOnAt)}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {constants.MARRIAGESTATUSS.find((marriageStatus) => marriageStatus.value === x.marriageStatus)?.label}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {constants.CUSTOMER_TYPES.find((customerType) => customerType.value === x.customerType)?.label}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {x.biography}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {dateHelpers.monthDayAndYear(x.dateOfBirth)}
              </PrimaryTable.Cell>
              {/* Insert cells here */}
              <PrimaryTable.Cell>
                <div className="flex">
{x.deactivatedAt ? (
    <ArrowPathIcon
        title="Activate"
        className="w-6 mx-1 text-white bg-green-500 rounded-md p-0.5 cursor-pointer"
        onClick={(e) => {
            e.stopPropagation();
            toggleActiveStatus(x);
        }}
    />
) : (
    <XMarkIcon
        title="Deactivate"
        className="w-6 mx-1 text-white bg-delete rounded-md p-0.5 cursor-pointer"
        onClick={(e) => {
            e.stopPropagation();
            toggleActiveStatus(x);
        }}
    />
)}
                  {/* Insert flip active status here */}
                </div>
              </PrimaryTable.Cell>
            </PrimaryTable.Row>
          ))}
      </PrimaryTable>
    </div>
  );
}

const CustomerFilterSection = ({ filters, setFilters }) => {
  return (
    <FilterSection filters={filters}>
      <div>
        <div className="mb-2">
          <ToggleInput
            label="Show active records only"
            enabled={filters.activeOnly}
            setEnabled={() =>
              setFilters({
                ...filters,
                activeOnly: !filters.activeOnly,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Id"
            placeholder="Id"
            value={filters.id}
            onChange={(e) =>
              setFilters({
                ...filters,
                id: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="First Name"
            placeholder="First Name"
            value={filters.firstName}
            onChange={(e) =>
              setFilters({
                ...filters,
                firstName: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Last Name"
            placeholder="Last Name"
            value={filters.lastName}
            onChange={(e) =>
              setFilters({
                ...filters,
                lastName: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Username"
            placeholder="Username"
            value={filters.username}
            onChange={(e) =>
              setFilters({
                ...filters,
                username: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Email"
            placeholder="Email"
            value={filters.email}
            onChange={(e) =>
              setFilters({
                ...filters,
                email: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Phone"
            placeholder="Phone"
            value={filters.phone}
            onChange={(e) =>
              setFilters({
                ...filters,
                phone: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Timezone"
            placeholder="Timezone"
            value={filters.timezone}
            onChange={(e) =>
              setFilters({
                ...filters,
                timezone: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Hashed Password"
            placeholder="Hashed Password"
            value={filters.hashedPassword}
            onChange={(e) =>
              setFilters({
                ...filters,
                hashedPassword: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Password Salt"
            placeholder="Password Salt"
            value={filters.passwordSalt}
            onChange={(e) =>
              setFilters({
                ...filters,
                passwordSalt: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Reset Password Token"
            placeholder="Reset Password Token"
            value={filters.resetPasswordToken}
            onChange={(e) =>
              setFilters({
                ...filters,
                resetPasswordToken: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Reset Password Token Requested At"
            placeholder="Reset Password Token Requested At"
            value={filters.resetPasswordTokenRequestedAt}
            onChange={(e) =>
              setFilters({
                ...filters,
                resetPasswordTokenRequestedAt: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Reset Password Token Expires At"
            placeholder="Reset Password Token Expires At"
            value={filters.resetPasswordTokenExpiresAt}
            onChange={(e) =>
              setFilters({
                ...filters,
                resetPasswordTokenExpiresAt: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Last Locked Out At"
            placeholder="Last Locked Out At"
            value={filters.lastLockedOutAt}
            onChange={(e) =>
              setFilters({
                ...filters,
                lastLockedOutAt: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Failed Attempt Count"
            placeholder="Failed Attempt Count"
            value={filters.failedAttemptCount}
            onChange={(e) =>
              setFilters({
                ...filters,
                failedAttemptCount: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Must Change Password"
            placeholder="Must Change Password"
            value={filters.mustChangePassword}
            onChange={(e) =>
              setFilters({
                ...filters,
                mustChangePassword: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Password Last Changed At"
            placeholder="Password Last Changed At"
            value={filters.passwordLastChangedAt}
            onChange={(e) =>
              setFilters({
                ...filters,
                passwordLastChangedAt: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Created By User"
            placeholder="Created By User"
            value={filters.createdByUser}
            onChange={(e) =>
              setFilters({
                ...filters,
                createdByUser: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Last Updated At"
            placeholder="Last Updated At"
            value={filters.lastUpdatedAt}
            onChange={(e) =>
              setFilters({
                ...filters,
                lastUpdatedAt: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Last Updated By User"
            placeholder="Last Updated By User"
            value={filters.lastUpdatedByUser}
            onChange={(e) =>
              setFilters({
                ...filters,
                lastUpdatedByUser: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Deactivated By User"
            placeholder="Deactivated By User"
            value={filters.deactivatedByUser}
            onChange={(e) =>
              setFilters({
                ...filters,
                deactivatedByUser: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Last Logged On At"
            placeholder="Last Logged On At"
            value={filters.lastLoggedOnAt}
            onChange={(e) =>
              setFilters({
                ...filters,
                lastLoggedOnAt: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Marriage Status"
            placeholder="Marriage Status"
            value={filters.marriageStatus}
            onChange={(e) =>
              setFilters({
                ...filters,
                marriageStatus: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Customer Type"
            placeholder="Customer Type"
            value={filters.customerType}
            onChange={(e) =>
              setFilters({
                ...filters,
                customerType: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Biography"
            placeholder="Biography"
            value={filters.biography}
            onChange={(e) =>
              setFilters({
                ...filters,
                biography: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Date Of Birth"
            placeholder="Date Of Birth"
            value={filters.dateOfBirth}
            onChange={(e) =>
              setFilters({
                ...filters,
                dateOfBirth: e.target.value,
              })
            }
          />
        </div>
        {/* Add filters here */}
      </div>
    </FilterSection>
  );
};
