import {
  BriefcaseIcon,
  BuildingOfficeIcon,
  ChartBarIcon,
  CheckBadgeIcon,
  CodeBracketIcon,
  DocumentCheckIcon,
  GiftIcon,
  // MegaphoneIcon,
  // ReceiptRefundIcon,
  // RssIcon,
  NewspaperIcon,
  ReceiptPercentIcon,
  UserGroupIcon
} from "@heroicons/react/24/solid";
import { useNavigate } from "react-router";
import useSWR from "swr";
import { InfoCard } from "../components/Displays";
import { useUserStore } from "../context";
import { useApi } from "../hooks";
import constants from "../utils/constants";

export default function Dashboard() {
  const { currentUser } = useUserStore();
  const { fetch } = useApi();
  const navigate = useNavigate();

  const isSysAdmin = currentUser.roles.some(
    (role) => role.typeOfRole === constants.ROLE_IDS.SYSTEM_ADMIN
  );
  const businessAdmin =
    currentUser.roles.some(
      (role) => role.typeOfRole === constants.ROLE_IDS.ADMIN
    ) && currentUser?.businessIdList?.length > 0;
  const orgAdmin =
    currentUser.roles.some(
      (role) => role.typeOfRole === constants.ROLE_IDS.ADMIN
    ) && currentUser?.organizationIdList?.length > 0;
  // TODO: admin of both business(es) and org(s)
  // TODO: const reportAdmin = currentUser.roles.some((role) => role.typeOfRole === 3);
  const patron = currentUser.roles.some(
    (role) => role.typeOfRole === constants.ROLE_IDS.PATRON
  );

  const { data, isLoading } = useSWR(
    isSysAdmin || businessAdmin || orgAdmin ? "admin/dashboardInfo" : null,
    fetch
  );

  const renderSysAdminView = () => (
    <div className="flex flex-wrap p-4">
      <InfoCard
        title="Manage Users"
        count={data?.userCount}
        onClick={() => navigate("/users")}
        Icon={UserGroupIcon}
        isLoading={isLoading}
        countSuffix="active"
      />
      <InfoCard
        title="Manage Businesses"
        count={data?.businessCount}
        onClick={() => navigate("/businesses")}
        Icon={BuildingOfficeIcon}
        isLoading={isLoading}
        countSuffix="active"
      />
      <InfoCard
        title="Manage Pending Businesses"
        onClick={() => navigate("/reviewBusinesses")}
        Icon={DocumentCheckIcon}
        isLoading={isLoading}
      />
      {/* Manage User Access Requests */}
      <InfoCard
        title="Manage Business Access Requests"
        count={data?.requestCount}
        onClick={() => navigate("/businessRequests")}
        Icon={CheckBadgeIcon}
        isLoading={isLoading}
      />
      <InfoCard
        title="Manage Organizations"
        count={data?.organizationCount}
        onClick={() => navigate("/Organizations")}
        Icon={BriefcaseIcon}
        isLoading={isLoading}
        countSuffix="active"
      />
      {/* Manage Org User Access Requests */}
      <InfoCard
        title="Manage Organization Access Requests"
        count={data?.requestCount}
        onClick={() => navigate("/orgRequests")}
        Icon={CheckBadgeIcon}
        isLoading={isLoading}
      />
      {/* reporting */}
      <InfoCard
        title="Reporting"
        count={data?.reportCount}
        onClick={() => navigate("/reporting")}
        Icon={ChartBarIcon}
        isLoading={isLoading}
      />
      <InfoCard
        title="Manage Gifts"
        onClick={() => navigate("/gifts")}
        Icon={GiftIcon}
        isLoading={isLoading}
      />
      <InfoCard
        title="Manage User Sessions"
        onClick={() => navigate("/sessions")}
        Icon={CodeBracketIcon}
        isLoading={isLoading}
      />
      <InfoCard
        title="Manage Business Followups"
        onClick={() => navigate("/businessFollowups")}
        Icon={NewspaperIcon}
        isLoading={isLoading}
          />
      <InfoCard
        title="Stripe Dashboard"
        onClick={() => window.open('https://dashboard.stripe.com/', '_blank')} 
        Icon={ReceiptPercentIcon}
        isLoading={isLoading}
        />
    </div>
  );

  const renderBusinessAdminView = () => (
    <div className="flex flex-wrap p-4">
      {/* Business Overview */}
      <InfoCard
        title="Business Overview"
        onClick={() => navigate(`/businesses`)}
        Icon={BuildingOfficeIcon}
        isLoading={isLoading}
      />
      {/* Manage User Access Requests */}
      <InfoCard
        title="Manage Access Requests"
        count={data?.requestCount}
        onClick={() => navigate("/businessRequests")}
        Icon={CheckBadgeIcon}
        isLoading={isLoading}
      />
      <InfoCard
        title="Reporting"
        count={data?.reportCount}
        onClick={() => navigate("/reporting")}
        Icon={ChartBarIcon}
        isLoading={isLoading}
      />
      <InfoCard
        title="Manage Gifts"
        onClick={() => navigate("/gifts")}
        Icon={GiftIcon}
        isLoading={isLoading}
      />
    </div>
  );

  const renderOrgAdminView = () => (
    <div className="flex flex-wrap p-4">
      {/* Org Overview */}
      <InfoCard
        title="Organization Overview"
        onClick={() => navigate(`/organizations`)}
        Icon={BriefcaseIcon}
        isLoading={isLoading}
      />
      {/* Manage User Access Requests */}
      <InfoCard
        title="Manage Access Requests"
        count={data?.requestCount}
        onClick={() => navigate("/orgRequests")}
        Icon={CheckBadgeIcon}
        isLoading={isLoading}
      />
      {/* Post to feed */}
{/*      <InfoCard
        title="Organization Feed"
        count={data?.feedCount}
        onClick={() => navigate("/orgFeed")}
        Icon={RssIcon}
        isLoading={isLoading}
      />
*/}      {/* Reporting */}
      <InfoCard
        title="Reporting"
        count={data?.reportCount}
        onClick={() => navigate("/reporting")}
        Icon={ChartBarIcon}
        isLoading={isLoading}
      />
    </div>
  );
  const renderPatronView = () => (
    <div className="flex flex-wrap p-4">
      <InfoCard
        title="Patron Overview"
        onClick={() => navigate(`/profile/${currentUser.id}`)}
        Icon={BriefcaseIcon}
        isLoading={isLoading}
      />
    </div>
  );

  return (
    <div className="-m-4">
      {/* Header */}
      <div className="w-full cursor-pointer relative flex flex-col px-4 py-8 
          bg-size-200 bg-pos-0 bg-gradient-to-tl from-primaryColor to-klinkLightGray h-1/3 min-h-1/3">
        <h1 className="text-white font-bold text-2xl">
          Welcome, {currentUser.firstName}
        </h1>
        {/* Put relevant info to client in this section */}
      </div>
      <div>
        {isSysAdmin && renderSysAdminView()}
        {businessAdmin && !isSysAdmin && renderBusinessAdminView()}
        {orgAdmin && !isSysAdmin && renderOrgAdminView()}
        {patron && !isSysAdmin && renderPatronView()}
      </div>
    </div>
  );
}
