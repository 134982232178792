import {
  BriefcaseIcon,
  // GiftIcon,
  BuildingOfficeIcon,
  GiftTopIcon,
  UserGroupIcon,
  CalendarIcon
} from "@heroicons/react/24/solid";
import { useNavigate } from "react-router";
import { InfoCard } from "../components/Displays";
import { useUserStore } from "../context";
import constants from "../utils/constants";

export default function Reporting() {
  const { currentUser } = useUserStore();
  const navigate = useNavigate();

  const isSysAdmin = currentUser.roles.some(
    (role) => role.typeOfRole === constants.ROLE_IDS.SYSTEM_ADMIN
  );
  const isBusinessAdmin =
    currentUser.roles.some(
      (role) => role.typeOfRole === constants.ROLE_IDS.ADMIN
    ) && currentUser.businessIdList.length > 0;
  const isOrgAdmin =
    currentUser.roles.some(
      (role) => role.typeOfRole === constants.ROLE_IDS.ADMIN
    ) && currentUser.organizationIdList.length > 0;
  // TODO: const reportAdmin = currentUser.roles.some((role) => role.typeOfRole === 3);

  const renderSysAdminView = () => (
    <div className="flex flex-wrap p-4">
      {/* <InfoCard
        title="Gift Report"
        onClick={() => navigate("/GiftReporting")}
        Icon={GiftIcon}
      /> */}
      <InfoCard
        title="Gift Summary Report"
        onClick={() => navigate("/GiftSummaryReport")}
        Icon={GiftTopIcon}
      />
      <InfoCard
        title="Registration Report"
        onClick={() => navigate("/RegistrationReport")}
        Icon={UserGroupIcon}
      />
      <InfoCard
        title="Recommended Business Report"
        onClick={() => navigate("/RecoBusinessReport")}
        Icon={BuildingOfficeIcon}
      />
      <InfoCard
        title="Inactive Business Report"
        onClick={() => navigate("/InactiveBusinessReport")}
        Icon={CalendarIcon}
      />
      <InfoCard
        title="Custom Menu Items"
        onClick={() => navigate("/CustomMenuItemsReport")}
        Icon={BriefcaseIcon}
      />
    </div>
  );

  const renderBusinessAdminView = () => (
    <div className="flex flex-wrap p-4">
      <InfoCard
        title="Gift Summary Report"
        onClick={() => navigate("/GiftSummaryReport")}
        Icon={GiftTopIcon}
      />
    </div>
  );

  const renderOrgAdminView = () => (
    <div className="flex flex-wrap p-4">
      <InfoCard
        title="Gift Summary Report"
        onClick={() => navigate("/GiftSummaryReport")}
        Icon={GiftTopIcon}
      />
    </div>
  );

  const renderOrgAndBusinessAdminView = () => (
    <div className="flex flex-wrap p-4">
      <InfoCard
        title="Gift Summary Report"
        onClick={() => navigate("/GiftSummaryReport")}
        Icon={GiftTopIcon}
      />
    </div>
  );

  function renderCards() {
    if (isSysAdmin)
      return renderSysAdminView();
    if (isBusinessAdmin && isOrgAdmin)
      return renderOrgAndBusinessAdminView();
    if (isBusinessAdmin)
      return renderBusinessAdminView();
    if (isOrgAdmin)
      return renderOrgAdminView();
    return null;
  }

  return (
    <div className="-m-4">
      {/* Header */}
      <div className="w-full cursor-pointer relative flex flex-col px-4 py-8 
          bg-size-200 bg-pos-0 bg-gradient-to-tl from-primaryColor to-klinkLightGray h-1/3 min-h-1/3">
        <h1 className="text-white font-bold text-2xl">
          Welcome, {currentUser.firstName}
        </h1>
      </div>
      <div>
      {renderCards()}
      </div>
    </div>
  );
}
