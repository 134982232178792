import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import { PulseLoader } from "react-spinners";
import constants from "../../utils/constants";

export default function InfoCard({
  title,
  count,
  onClick,
  Icon = QuestionMarkCircleIcon,
  isLoading,
  countSuffix = 'total'
}) {
  return (
    <div className="lg:w-1/3 sm:w-1/2 w-full p-4" onClick={onClick}>
      <div className="bg-white hover:bg-backgroundColor p-4 shadow-md shadow-primaryColor/50 rounded-md w-full cursor-pointer flex justify-between">
        <div>
          <h6 className="font-bold mb-1">{title}</h6>
          {isLoading ? (
            <PulseLoader
              color={constants.APP_PRIMARY_COLOR}
              size={10}
              speedMultiplier={0.75}
            />
          ) : (
            <p className="text-sm text-gray-400">
              {count} {count ? countSuffix : ''}
            </p>
          )}
        </div>
        <Icon className="w-12 opacity-40 text-primaryColor" />
      </div>
    </div>
  );
}
