import { Menu } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

export default function InfoButton({ children }) {
  return (
    <Menu as="div" className="relative">
      <Menu.Button>
        <InformationCircleIcon className="w-4 text-gray-700 cursor-pointer ml-1" />
      </Menu.Button>
      <Menu.Items className="absolute p-2 left-0 z-10 w-48 origin-top bg-white divide-y divide-gray-100 rounded-md shadow-lg outline-none">
        <Menu.Item className="text-sm text-gray-700 py-1 px-4">
          {children}
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
}
