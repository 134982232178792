import {
    // ArrowPathIcon,
    // PlusCircleIcon,
    XMarkIcon
  } from "@heroicons/react/24/solid";
import { useState, useEffect } from "react";
import { AppIndicator } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { TextInput, ToggleInput, SelectInput } from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { PrimaryButton } from "../components/Buttons";
import { FilterSection, PrimaryTable } from "../components/Tables";
import { useLoaderStore, useUserStore } from "../context";
import { useAlert, useApi, useDebounce } from "../hooks";
import constants from "../utils/constants";
// import dateHelpers from "../utils/dateHelpers";
import { switchSortDirection } from "../utils/helpers";
import _ from "lodash";

  export default function BusinessFollowups() {
    const alert = useAlert();
    const { post, fetch } = useApi();
    const { setShowLoader } = useLoaderStore();
    // const { currentUser } = useUserStore();
    const navigate = useNavigate();
    const [filters, setFilters] = useState({activeOnly: true});
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortDirection, setSortDirection] = useState(constants.SORT_DIRECTIONS.ASCENDING);
    const [sortField, setSortField] = useState("name");
    const [displayCount, setDisplayCount] = useState(constants.DISPLAY_PER_PAGE ?? 25);
    const [states, setStates] = useState([]);
    const [pagedData, setPagedData] = useState(null);
    const { debounced: debouncedFilters } = useDebounce({ toDebounce: filters });
    const { debounced: debouncedDisplayCount } = useDebounce({
        toDebounce: displayCount,
    });

    useEffect(refreshList, [sortDirection, sortField, debouncedFilters, currentPage, debouncedDisplayCount]);
    useEffect(getStates, []);

    function refreshList() { 
        if (isLoading) return;
        setIsLoading(true);
        post("Business/GetPaginatedBusinessFollowups", {
            pageNumber: currentPage,
            pageSize: debouncedDisplayCount,
            sortDirection,
            sortField,
            isExact: false,
            ...debouncedFilters
        }).then(response => {            
            setPagedData(response);
        }).catch((err) => {
            alert("Error", err.statusText || "Error While Attempting To Refresh Session Data", "error");
            console.error(err);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function getStates() {
        fetch("User/GetStates")
        .then((response) => {            
            setStates(response);
        })
        .catch((err) => {
            alert("Error", err.statusText || "Error While Fetching State/Provinces", "error");
            console.error(err);
        })        
    }

    const handleSort = (field) => {
        if (sortField === field) {
          setSortDirection(switchSortDirection(sortDirection));
        } else {
          setSortField(field);
        }
    }

    return (
        <div>
            {/* Header */}
            <div className="flex justify-between">
                <PageHeader title="Manage Business Followups"/>
            </div>
            {/* Filter Section */}
            <BusinessFollowupFilterSection filters={filters} setFilters={setFilters} states={states}/>

            {/* Table */}
            <PrimaryTable
            isLoading={isLoading}
            filters={filters}
            totalCount={pagedData?.totalCount}
            displayCount={displayCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setDisplayCount={setDisplayCount}
            header={
                <PrimaryTable.Header>
                    <PrimaryTable.HeaderColumn 
                    display="Name"
                    value={filters.name}
                    onSort={() => handleSort("name")}
                    isSortingBy={sortField === "name"}
                    sortDirection={sortDirection}
                    onClear={() => setFilters({...filters, name: ""})}
                    onType={(e) => setFilters({...filters, name: e.target.value})}
                    />
                    <PrimaryTable.HeaderColumn
                    display="Logical Business Identifier"                    
                    />
                    <PrimaryTable.HeaderColumn 
                    display="State/Province"
                    isSortingBy={sortField === "stateProvince"}
                    sortDirection={sortDirection}
                    />
                </PrimaryTable.Header>
            }
            >
                {pagedData && pagedData.list ? (
                    _.map(pagedData.list, (x, index) => {
                        return (
                            <PrimaryTable.Row key={index} onClick={() => navigate(`/businessFollowupHistories`, {state: {id: x.id, businessName: x.name}})}>
                                <PrimaryTable.Cell>{x.name}</PrimaryTable.Cell>
                                <PrimaryTable.Cell>{x.logicalBusinessIdentifier}</PrimaryTable.Cell>
                                <PrimaryTable.Cell>{x.stateProvince}</PrimaryTable.Cell>
                            </PrimaryTable.Row>
                        );
                    })
                ) : null}
            </PrimaryTable>

        </div>
    );
  }


  const BusinessFollowupFilterSection = ({filters, setFilters, states}) => {      
    return (
    <FilterSection filters={filters}>
      <div>
        <PrimaryButton 
          text="Reset"
          backgroundColor="gray-500"
          className="float-right"
          small
          onClick={() => setFilters({})}                    
        />
                <div className="mb-2">
                    <ToggleInput
                        label="Show active records only"
                        enabled={filters.activeOnly}
                        setEnabled={() =>
                        setFilters({
                            ...filters,
                            activeOnly: !filters.activeOnly,
                        })
                        }
                    />
                </div>
                <div className="mb-2">
                    <TextInput 
                    label="Name"
                    placeHolder="Name"
                    value={filters.name}
                    onChange={(e) => {
                        setFilters({
                            ...filters,
                            name: e.target.value
                        })
                    }}
                    />
                </div>
                <div className="my-5 flex">
                    <div style={{width: "90%"}}>
                        <SelectInput 
                        label="State/Province"
                        placeHolder="State/Province"
                        value={filters.selectedState}
                        options={states}                        
                        onChange={(opt) => {
                            setFilters({
                                ...filters,
                                selectedState: opt,
                                stateProvince: opt.value
                            })
                        }}                        
                        />
                    </div>
                    <div style={{width: "10%"}}>
                        <XMarkIcon 
                            className="h-4 w-4 text-gray-400 mr-2 cursor-pointer mb-2"
                            onClick={() => {
                                setFilters({
                                    ...filters,
                                    selectedState: null,
                                    stateProvince: null
                                })
                            }}
                        />
                    </div>
                </div>
            </div>
        </FilterSection>
    );
  }