import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PrimaryButton } from "../components/Buttons";
import { CheckboxInput, PasswordInput, TextInput } from "../components/Inputs";
import { useLoaderStore, useUserStore } from "../context";
import { useAlert, useApi } from "../hooks";
// import constants from "../utils/constants";
import storage from "../utils/storage";

export default function Login() {
  const alert = useAlert();
  const { post } = useApi();
  const navigate = useNavigate();
  const [username, setUsername] = useState(
    storage.getItem(storage.ITEMS.username) ?? ""
  );
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const { login } = useUserStore();
  const { setShowLoader } = useLoaderStore();

  const executeSignIn = async (payload) => {
    setShowLoader(true);
    await post("public/weblogin", payload)
      .then((res) => {
        if (res.success) {
          let user = res.message.user || {};
          let token = res.message?.token || undefined;
          let refreshToken = res.message?.refreshToken || undefined;
          login(user, token, rememberMe, refreshToken);
          navigate("/");
        }
      })
      .catch((err) => {
        alert(
          "Unsuccessful login attempt",
          err.data.message || "Invalid credentials",
          "error"
        );
        console.error(err);
        navigate("/");
      })
      .finally(() => setShowLoader(false));
  };

  const handleSignIn = async () => {
    if (!isValid()) return;
    let payload = { username, password, rememberMe };
    if ("geolocation" in navigator) {
      new Promise(function(resolve, _reject) {
        navigator.geolocation.getCurrentPosition(function(position) {
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
        }, (posError) => {
          console.error('geolocation failure', posError);
          resolve({latitude: null, longitude: null});
        }); 
      }).then(async function({latitude, longitude}) {
        payload.latitude = latitude;
        payload.longitude = longitude;
        await executeSignIn(payload);
      }).catch(async (positionError) => {
        console.error("Attempted and failed to capture location, proceeding with login", positionError);
        await executeSignIn(payload);
      });
    } else {
      console.warn('Location unavailable, proceeding with login');
      await executeSignIn(payload);
    }
  };

  const isValid = () => {
    if (!username || !username.trim() || username.trim().length <= 1) {
      alert("Invalid input", "Please enter a username", "warning");
      return false;
    }
    if (!password || !password.trim()) {
      alert("Invalid input", "Please enter a password", "warning");
      return false;
    }
    return true;
  };

  return (
    <div>
      <h1 className="text-3xl font-bold">
        Business &amp; Organization Sign In
      </h1>
      {/* <h5 className="mt-1 mb-6">
        Or{" "}
        <Link
          to="/register"
          className="text-klinkYellow hover:text-klinkOrange/50"
        >
          create a business or organization account
        </Link>
      </h5> */}

      <div className="w-60% h-1 space-y-6 mt-2">
        <div>
          <TextInput
            label="Username or Email"
            name="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <PasswordInput
            label="Password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSignIn();
            }}
          />
          <div className="flex justify-between mt-2">
            <CheckboxInput
              label="Remember me"
              name="rememberMe"
              checked={rememberMe}
              setChecked={() => setRememberMe((rememberMe) => !rememberMe)}
            />
            <Link
              to="/forgotPassword"
              className="text-klinkOrange hover:text-klinkOrange/50 text-sm"
            >
              Forgot my password
            </Link>
          </div>
        </div>
        <PrimaryButton text="Sign In" onClick={handleSignIn} />
        <footer><Link
          to="/TermsOfService"
          className="text-primaryColor hover:text-primaryColor/50"
        >
          Terms of Service
        </Link></footer>
      </div>
    </div>
  );
}
