import { Label } from ".";
import { classNames } from "../../utils/helpers";

export default function DateInput({
  label,
  subLabel,
  name,
  placeholder = "",
  required = false,
  value,
  onChange,
  min,
  max,
}) {
  return (
    <>
      {/* Label/required asterisk */}
      {label && <Label label={label} required={required} />}
      <div className={classNames("flex relative", label ? "mt-1" : "")}>
        {/* Input */}
        <input
          type="time"
          name={name}
          className="p-2 rounded-md shadow-md focus:outline-none block w-full text-sm border-gray-300 placeholder-gray-300"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          readOnly={!onChange}
          min={min}
          max={max}
        />
      </div>
      {/* Sublabel */}
      {subLabel && (
        <div className="mt-1 text-gray-400 text-xs text-right">{subLabel}</div>
      )}
    </>
  );
}
