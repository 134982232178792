import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { GoBack, PrimaryButton } from "../components/Buttons";
import { CollapsibleSection } from "../components/Cards";
import { SelectInput, TextInput, NumberInput, CheckboxInput, DateInput } from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { useLoaderStore } from "../context";
import { useAlert, useApi } from "../hooks";
import constants from "../utils/constants";
import { convertToLabelValueList, convertToLabelValue } from "../utils/helpers";

export default function Customer() {
  let { id } = useParams();
  const navigate = useNavigate();
  const alert = useAlert();
  const isCreatingNew = id === "0";

  const { fetch, post } = useApi();
  const { setShowLoader } = useLoaderStore();

  const [customer, setCustomer] = useState({ id });

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    !isCreatingNew ? `customer/customerInfo/${id}` : null,
    fetch,
    {
      revalidateOnFocus: false,
      onSuccess: () => setShowLoader(false),
      onError: () => setShowLoader(false),
    }
  );

  const {data: createdByUserData } = useSWR(
    "user/getusersSimple",
    (url) => fetch(url, { sortField: "" }),
  );
  const {data: lastUpdatedByUserData } = useSWR(
    "user/getusersSimple",
    (url) => fetch(url, { sortField: "" }),
  );
  const {data: deactivatedByUserData } = useSWR(
    "user/getusersSimple",
    (url) => fetch(url, { sortField: "" }),
  );
  // add swr calls here

  useEffect(() => {
    if (!isCreatingNew && !data) setShowLoader(true);
  }, []);

  useEffect(() => {
    if (!data) return;

    setCustomer(data);
  }, [data]);


  const handleChange = (property, value) => {
    setCustomer({
      ...customer,
      [property]: value,
    });
  };

  const handleSave = () => {
    if (!isValid()) return;

    setShowLoader(true);
    post("customer/saveCustomer", customer)
      .then((res) => {
        alert("Success", "Saved customer", "success");
        navigate("/customers");
      })
      .catch((err) => {
        alert(
          "Unable to save customer",
          err.data.message || "Please try again",
          "error"
        );
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const isValid = () => {
    if (!customer.firstName || !customer.firstName.trim()) {
      alert("Form invalid", "First Name must have a value", "warning");
      return false;
    }
    if (!customer.firstName || !customer.firstName.trim()) {
      alert("Form invalid", "First Name must have a value", "warning");
      return false;
    }
    if (!customer.lastName || !customer.lastName.trim()) {
      alert("Form invalid", "Last Name must have a value", "warning");
      return false;
    }
    if (!customer.firstName || !customer.firstName.trim()) {
      alert("Form invalid", "First Name must have a value", "warning");
      return false;
    }
    if (!customer.lastName || !customer.lastName.trim()) {
      alert("Form invalid", "Last Name must have a value", "warning");
      return false;
    }
    if (!customer.username || !customer.username.trim()) {
      alert("Form invalid", "Username must have a value", "warning");
      return false;
    }
    if (!customer.firstName || !customer.firstName.trim()) {
      alert("Form invalid", "First Name must have a value", "warning");
      return false;
    }
    if (!customer.lastName || !customer.lastName.trim()) {
      alert("Form invalid", "Last Name must have a value", "warning");
      return false;
    }
    if (!customer.username || !customer.username.trim()) {
      alert("Form invalid", "Username must have a value", "warning");
      return false;
    }
    if (!customer.email || !customer.email.trim()) {
      alert("Form invalid", "Email must have a value", "warning");
      return false;
    }
    if (!customer.firstName || !customer.firstName.trim()) {
      alert("Form invalid", "First Name must have a value", "warning");
      return false;
    }
    if (!customer.lastName || !customer.lastName.trim()) {
      alert("Form invalid", "Last Name must have a value", "warning");
      return false;
    }
    if (!customer.username || !customer.username.trim()) {
      alert("Form invalid", "Username must have a value", "warning");
      return false;
    }
    if (!customer.email || !customer.email.trim()) {
      alert("Form invalid", "Email must have a value", "warning");
      return false;
    }
    if (!customer.hashedPassword || !customer.hashedPassword.trim()) {
      alert("Form invalid", "Hashed Password must have a value", "warning");
      return false;
    }
    if (!customer.firstName || !customer.firstName.trim()) {
      alert("Form invalid", "First Name must have a value", "warning");
      return false;
    }
    if (!customer.lastName || !customer.lastName.trim()) {
      alert("Form invalid", "Last Name must have a value", "warning");
      return false;
    }
    if (!customer.username || !customer.username.trim()) {
      alert("Form invalid", "Username must have a value", "warning");
      return false;
    }
    if (!customer.email || !customer.email.trim()) {
      alert("Form invalid", "Email must have a value", "warning");
      return false;
    }
    if (!customer.hashedPassword || !customer.hashedPassword.trim()) {
      alert("Form invalid", "Hashed Password must have a value", "warning");
      return false;
    }
    if (!customer.passwordSalt || !customer.passwordSalt.trim()) {
      alert("Form invalid", "Password Salt must have a value", "warning");
      return false;
    }
    if (!customer.firstName || !customer.firstName.trim()) {
      alert("Form invalid", "First Name must have a value", "warning");
      return false;
    }
    if (!customer.lastName || !customer.lastName.trim()) {
      alert("Form invalid", "Last Name must have a value", "warning");
      return false;
    }
    if (!customer.username || !customer.username.trim()) {
      alert("Form invalid", "Username must have a value", "warning");
      return false;
    }
    if (!customer.email || !customer.email.trim()) {
      alert("Form invalid", "Email must have a value", "warning");
      return false;
    }
    if (!customer.hashedPassword || !customer.hashedPassword.trim()) {
      alert("Form invalid", "Hashed Password must have a value", "warning");
      return false;
    }
    if (!customer.passwordSalt || !customer.passwordSalt.trim()) {
      alert("Form invalid", "Password Salt must have a value", "warning");
      return false;
    }
    if (typeof customer.mustChangePassword !== 'boolean') {
      alert("Form invalid", "Must Change Password must have a value", "warning");
      return false;
    }
    if (!customer.firstName || !customer.firstName.trim()) {
      alert("Form invalid", "First Name must have a value", "warning");
      return false;
    }
    if (!customer.lastName || !customer.lastName.trim()) {
      alert("Form invalid", "Last Name must have a value", "warning");
      return false;
    }
    if (!customer.username || !customer.username.trim()) {
      alert("Form invalid", "Username must have a value", "warning");
      return false;
    }
    if (!customer.email || !customer.email.trim()) {
      alert("Form invalid", "Email must have a value", "warning");
      return false;
    }
    if (!customer.hashedPassword || !customer.hashedPassword.trim()) {
      alert("Form invalid", "Hashed Password must have a value", "warning");
      return false;
    }
    if (!customer.passwordSalt || !customer.passwordSalt.trim()) {
      alert("Form invalid", "Password Salt must have a value", "warning");
      return false;
    }
    if (typeof customer.mustChangePassword !== 'boolean') {
      alert("Form invalid", "Must Change Password must have a value", "warning");
      return false;
    }
    if (!customer.createdByUser) {
      alert("Form invalid", "Created By User must have a value", "warning");
      return false;
    }
    if (!customer.firstName || !customer.firstName.trim()) {
      alert("Form invalid", "First Name must have a value", "warning");
      return false;
    }
    if (!customer.lastName || !customer.lastName.trim()) {
      alert("Form invalid", "Last Name must have a value", "warning");
      return false;
    }
    if (!customer.username || !customer.username.trim()) {
      alert("Form invalid", "Username must have a value", "warning");
      return false;
    }
    if (!customer.email || !customer.email.trim()) {
      alert("Form invalid", "Email must have a value", "warning");
      return false;
    }
    if (!customer.hashedPassword || !customer.hashedPassword.trim()) {
      alert("Form invalid", "Hashed Password must have a value", "warning");
      return false;
    }
    if (!customer.passwordSalt || !customer.passwordSalt.trim()) {
      alert("Form invalid", "Password Salt must have a value", "warning");
      return false;
    }
    if (typeof customer.mustChangePassword !== 'boolean') {
      alert("Form invalid", "Must Change Password must have a value", "warning");
      return false;
    }
    if (!customer.createdByUser) {
      alert("Form invalid", "Created By User must have a value", "warning");
      return false;
    }
    if (!customer.lastUpdatedAt) {
      alert("Form invalid", "Last Updated At must have a value", "warning");
      return false;
    }
    if (!customer.firstName || !customer.firstName.trim()) {
      alert("Form invalid", "First Name must have a value", "warning");
      return false;
    }
    if (!customer.lastName || !customer.lastName.trim()) {
      alert("Form invalid", "Last Name must have a value", "warning");
      return false;
    }
    if (!customer.username || !customer.username.trim()) {
      alert("Form invalid", "Username must have a value", "warning");
      return false;
    }
    if (!customer.email || !customer.email.trim()) {
      alert("Form invalid", "Email must have a value", "warning");
      return false;
    }
    if (!customer.hashedPassword || !customer.hashedPassword.trim()) {
      alert("Form invalid", "Hashed Password must have a value", "warning");
      return false;
    }
    if (!customer.passwordSalt || !customer.passwordSalt.trim()) {
      alert("Form invalid", "Password Salt must have a value", "warning");
      return false;
    }
    if (typeof customer.mustChangePassword !== 'boolean') {
      alert("Form invalid", "Must Change Password must have a value", "warning");
      return false;
    }
    if (!customer.createdByUser) {
      alert("Form invalid", "Created By User must have a value", "warning");
      return false;
    }
    if (!customer.lastUpdatedAt) {
      alert("Form invalid", "Last Updated At must have a value", "warning");
      return false;
    }
    if (!customer.lastUpdatedByUser) {
      alert("Form invalid", "Last Updated By User must have a value", "warning");
      return false;
    }
    // add validations here

    return true;
  };

  return (
    <div>
      {/* Header */}
      <PageHeader
        title={
          isCreatingNew || !customer ? "Create new customer" : `Manage customer ${customer.id}`
        }
      />
      <GoBack display="Manage all customers" navigateTo="/customers" />

      <div className="bg-gray-100 shadow-md rounded-md shadow-primaryColor/25">
        {/* Form */}
        <CollapsibleSection title="Info">
          <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4">
            <div>
              <TextInput
                label="First Name"
                value={customer?.firstName ?? ""}
                onChange={(e) => handleChange("firstName", e.target.value)}
                required
                maxLength={30}
              />
            </div>
            <div>
              <TextInput
                label="Last Name"
                value={customer?.lastName ?? ""}
                onChange={(e) => handleChange("lastName", e.target.value)}
                required
                maxLength={30}
              />
            </div>
            <div>
              <TextInput
                label="Username"
                value={customer?.username ?? ""}
                onChange={(e) => handleChange("username", e.target.value)}
                required
                maxLength={150}
              />
            </div>
            <div>
              <TextInput
                label="Email"
                value={customer?.email ?? ""}
                onChange={(e) => handleChange("email", e.target.value)}
                required
                maxLength={150}
              />
            </div>
            <div>
              <TextInput
                label="Phone"
                value={customer?.phone ?? ""}
                onChange={(e) => handleChange("phone", e.target.value)}
                maxLength={15}
              />
            </div>
            <div>
              <TextInput
                label="Timezone"
                value={customer?.timezone ?? ""}
                onChange={(e) => handleChange("timezone", e.target.value)}
                maxLength={30}
              />
            </div>
            <div>
              <TextInput
                label="Hashed Password"
                value={customer?.hashedPassword ?? ""}
                onChange={(e) => handleChange("hashedPassword", e.target.value)}
                required
                maxLength={75}
              />
            </div>
            <div>
              <TextInput
                label="Password Salt"
                value={customer?.passwordSalt ?? ""}
                onChange={(e) => handleChange("passwordSalt", e.target.value)}
                required
                maxLength={75}
              />
            </div>
            <div>
              <TextInput
                label="Reset Password Token"
                value={customer?.resetPasswordToken ?? ""}
                onChange={(e) => handleChange("resetPasswordToken", e.target.value)}
              />
            </div>
            <div>
              <DateInput
                label="Reset Password Token Requested At"
                value={customer?.resetPasswordTokenRequestedAt ?? ""}
                onChange={(e) => handleChange("resetPasswordTokenRequestedAt", e.target.value)}
              />
            </div>
            <div>
              <DateInput
                label="Reset Password Token Expires At"
                value={customer?.resetPasswordTokenExpiresAt ?? ""}
                onChange={(e) => handleChange("resetPasswordTokenExpiresAt", e.target.value)}
              />
            </div>
            <div>
              <DateInput
                label="Last Locked Out At"
                value={customer?.lastLockedOutAt ?? ""}
                onChange={(e) => handleChange("lastLockedOutAt", e.target.value)}
              />
            </div>
            <div>
              <NumberInput
                label="Failed Attempt Count"
                value={customer?.failedAttemptCount ?? ""}
                onChange={(e) => handleChange("failedAttemptCount", e.target.valueAsNumber)}
              />
            </div>
            <div>
              <CheckboxInput
                label="Must Change Password?"
                checked={customer?.mustChangePassword ?? ""}
                setChecked={(e) => handleChange("mustChangePassword", !customer?.mustChangePassword)}
              />
            </div>
            <div>
              <NumberInput
                label="Password Last Changed At"
                value={customer?.passwordLastChangedAt ?? ""}
                onChange={(e) => handleChange("passwordLastChangedAt", e.target.valueAsNumber)}
              />
            </div>
            <div>
              <SelectInput
                label="Created By User"
                options={convertToLabelValueList(createdByUserData)}
                onClear={() => handleChange("createdByUserId", undefined)}
                value={convertToLabelValue(createdByUserData?.find(x => x.id === customer?.createdByUserId))}
                onChange={(createdByUser) => handleChange("createdByUserId", createdByUser.value)}
                isSearchable
              />
            </div>
            <div>
              <DateInput
                label="Last Updated At"
                value={customer?.lastUpdatedAt ?? ""}
                onChange={(e) => handleChange("lastUpdatedAt", e.target.value)}
                required
              />
            </div>
            <div>
              <SelectInput
                label="Last Updated By User"
                options={convertToLabelValueList(lastUpdatedByUserData)}
                onClear={() => handleChange("lastUpdatedByUserId", undefined)}
                value={convertToLabelValue(lastUpdatedByUserData?.find(x => x.id === customer?.lastUpdatedByUserId))}
                onChange={(lastUpdatedByUser) => handleChange("lastUpdatedByUserId", lastUpdatedByUser.value)}
                isSearchable
              />
            </div>
            <div>
              <SelectInput
                label="Deactivated By User"
                options={convertToLabelValueList(deactivatedByUserData)}
                onClear={() => handleChange("deactivatedByUserId", undefined)}
                value={convertToLabelValue(deactivatedByUserData?.find(x => x.id === customer?.deactivatedByUserId))}
                onChange={(deactivatedByUser) => handleChange("deactivatedByUserId", deactivatedByUser.value)}
                isSearchable
              />
            </div>
            <div>
              <DateInput
                label="Last Logged On At"
                value={customer?.lastLoggedOnAt ?? ""}
                onChange={(e) => handleChange("lastLoggedOnAt", e.target.value)}
              />
            </div>
            <div>
              <SelectInput
                label="Marriage Status"
                options={constants.MARRIAGE_STATUSES}
                onClear={() => handleChange("marriageStatus", undefined)}
                value={constants.MARRIAGE_STATUSES.find(x => x.value === customer?.marriageStatus)}
                onChange={(marriageStatus) => handleChange("marriageStatus", marriageStatus.value)}
                isSearchable
              />
            </div>
            <div>
              <SelectInput
                label="Customer Type"
                options={constants.CUSTOMER_TYPES}
                onClear={() => handleChange("customerType", undefined)}
                value={constants.CUSTOMER_TYPES.find(x => x.value === customer?.customerType)}
                onChange={(customerType) => handleChange("customerType", customerType.value)}
                isSearchable
              />
            </div>
            <div>
              <TextInput
                label="Biography"
                value={customer?.biography ?? ""}
                onChange={(e) => handleChange("biography", e.target.value)}
                maxLength={1000}
              />
            </div>
            <div>
              <DateInput
                label="Date Of Birth"
                value={customer?.dateOfBirth ?? ""}
                onChange={(e) => handleChange("dateOfBirth", e.target.value)}
              />
            </div>
           {/* Put fields here */}
          </div>
        </CollapsibleSection>

        {/* Submit button */}
        <div className="mt-12 pb-4 px-4">
          <PrimaryButton
            text={isCreatingNew ? "Create" : "Save"}
            onClick={handleSave}
          />
        </div>
      </div>
    </div>
  );
}
