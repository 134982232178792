import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { classNames } from "../../utils/helpers";

export default function PublicLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const logo = require("../../assets/images/newlogo_horizontal.png");

  const isChildRoute = (path) => {
    return location.pathname.startsWith(path);
  }

  return (
    <div className="h-full w-full flex flex-col bg-primaryColor">
      <div
        onClick={() => navigate("/")}
        className="w-full cursor-pointer relative flex flex-col justify-center items-center px-4 py-8 
          bg-size-200 bg-pos-0 bg-gradient-to-tl from-primaryColor to-klinkYellow h-1/3 min-h-1/3">
        <div style={{ height: 350, width: 350 }}>
          <img src={logo.toString()} />
        </div>
      </div>
      <br />
      <div className={classNames("w-full lg:w-5/5 p-4 flex justify-center mx-auto flex-col", isChildRoute("/termsOfService") ? "" : "max-w-lg")}>
        <Outlet />
      </div>
      <section id="footer"></section>
    </div>
  );
}
