import { create } from "zustand";
import { JsonTryParse } from "../utils/helpers";
import storage from "../utils/storage";

const useUserStore = create((set, get) => ({
  currentUser: undefined,
  impersonating: false,
  setCurrentUser: (currentUser) => set(() => ({ currentUser })),
  setImpersonating: (impersonating) => set(() => ({ impersonating })),
  impersonate: (user, token) => {
    if(get().impersonating) return;

    const storageCurrentUser = storage.getItem(storage.ITEMS.currentUser);
    const storageCurrentUserJWT = storage.getItem(storage.ITEMS.jwt);
    storage.setItem(storage.ITEMS.storageCurrentUser, storageCurrentUser);
    storage.setItem(storage.ITEMS.storageJWT, storageCurrentUserJWT);
    storage.setItem(storage.ITEMS.currentUser, JSON.stringify(user));
    storage.setItem(storage.ITEMS.jwt, token);
    set(() => ({ impersonating: true, currentUser: user }));
  },
  stopImpersonating: () => {
    const storageCurrentUser = storage.getItem(storage.ITEMS.storageCurrentUser);
    const storageCurrentUserJWT = storage.getItem(storage.ITEMS.storageJWT);
    storage.setItem(storage.ITEMS.currentUser, storageCurrentUser);
    storage.setItem(storage.ITEMS.jwt, storageCurrentUserJWT);
    storage.removeItem(storage.ITEMS.storageCurrentUser);
    storage.removeItem(storage.ITEMS.storageJWT);
    set(() => ({
      impersonating: false,
      currentUser: JsonTryParse(storageCurrentUser),
    }));
  },
  login: (user, token, rememberMe, refreshToken) => {    
    set(() => ({ currentUser: user }));
    storage.setItem(storage.ITEMS.currentUser, JSON.stringify(user));
    storage.setItem(storage.ITEMS.jwt, token);    
    if (rememberMe) {
      storage.setItem(storage.ITEMS.username, user.username);
      storage.setItem(storage.ITEMS.refreshToken, refreshToken);
    }      
    else storage.removeItem(storage.ITEMS.username);
  },
  logout: () => {
    set(() => ({ currentUser: undefined }));
    storage.removeItem(storage.ITEMS.currentUser);
    storage.removeItem(storage.ITEMS.jwt);
    storage.removeItem(storage.ITEMS.storageCurrentUser);
    storage.removeItem(storage.ITEMS.storageJWT);
    storage.removeItem(storage.ITEMS.refreshToken);
    set(() => ({ impersonating: false }));
  },
}));

export default useUserStore;
