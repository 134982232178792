import _ from "lodash";
import React, { useState } from "react";
import { PrimaryButton } from "../components/Buttons";
import { DateInput, TextInput } from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { PrimaryTable, StaticFilterSection } from "../components/Tables";
import { useApi, useReportDownloadHelpers } from "../hooks";
import constants from "../utils/constants";
import dateHelpers from "../utils/dateHelpers";
import moment from "moment";

const InactiveBizTable = ({ data }) => {
  return (
    <PrimaryTable
      totalCount={data?.totalCount}
      displayCount={constants.DISPLAY_PER_PAGE}
      header={
        <PrimaryTable.Header>
          <PrimaryTable.HeaderColumn display="Business name" />
          <PrimaryTable.HeaderColumn display="Last Date Active" />
          <PrimaryTable.HeaderColumn display="City" />
          <PrimaryTable.HeaderColumn display="Address" />
          <PrimaryTable.HeaderColumn display="Phone Number" />
        </PrimaryTable.Header>
      }
    >
      {data &&
        _.map(data, (r, index) => (
          <PrimaryTable.Row key={`${r.BusinessName}-${index}`} index={index}>
            <PrimaryTable.Cell>{r.name}</PrimaryTable.Cell>
            <PrimaryTable.Cell>{r.lastDateActiveMDY}</PrimaryTable.Cell>
            <PrimaryTable.Cell>{r.city}</PrimaryTable.Cell>
            <PrimaryTable.Cell>{r.postalCode}</PrimaryTable.Cell>
            <PrimaryTable.Cell>{r.phone}</PrimaryTable.Cell>
          </PrimaryTable.Row>
        ))}
    </PrimaryTable>
  );
};

const InactiveBizSection = ({ setFilters, filters, onFiltersChange }) => {
  return (
    <StaticFilterSection filters={filters} showFilterCount={false}>
      <div className="grid grid-cols-12 gap-4 w-full p-4">
        <div className="col-span-3 min-w-full">
          <TextInput
            label="City"
            name="City"
            placeholder="Enter city"
            value={filters.city || ""}
            onChange={(e) =>
              onFiltersChange({ ...filters, city: e.target.value })
            }
          />
        </div>
        <div className="col-span-2 min-w-full">
          <DateInput
            label="Last Date Active"
            placeholder="Last Date Active"
            value={filters.activeFrom}
            onChange={(e) =>
              onFiltersChange({ ...filters, activeFrom: e.target.value })
            }
          />
        </div>
        <div className="col-span-7 min-w-full">
          <PrimaryButton 
            text="Reset"
            backgroundColor="gray-500"
            className="float-right mt-5"
            small
            onClick={() => setFilters({})}                    
          />
        </div>
      </div>
    </StaticFilterSection>
  );
};

export default function InactiveBusinessReport() {
  const { post } = useApi();
  const { downloadFileFromApiPost } = useReportDownloadHelpers();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tainted, setTainted] = useState(true);
  const [filters, setFilters] = useState({
    activeFrom: moment().format(dateHelpers.YMD)
  });

  function buildPayload() {
    return {
      City: filters.city,
      LastDateActive: filters.activeFrom,
    };
  }

  const onFiltersChange = (updatedFilters) => {
    setTainted(true);
    setFilters((prevFilters) => ({ ...prevFilters, ...updatedFilters }));
  };

  const downloadReport = async () => {
    downloadFileFromApiPost(
      "/report/GenerateInactiveBusinessReport",
      buildPayload(),
      "InactiveBusinessReport.xlsx"
    );
  };

  const executeReport = () => {
    setIsLoading(true);
    post("report/InactiveBusinessReport", buildPayload())
      .then((res) => {
        setData(res);
        setTainted(false);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  
  return (
    <div className="w-100 h-auto">
      <div className="flex justify-between">
        <PageHeader title="Inactive Business Reports" />
        <div className="flex flex-row justify-between">
          <PrimaryButton
            disabled={!tainted}
            onClick={executeReport}
            text={"Run Report"}
            className={"w-1/3 mr-2"}
          />
          <PrimaryButton
            onClick={downloadReport}
            text={"Download Report"}
            className={"w-1/3"}
          />
        </div>
      </div>

      <div className="w-full h-1/4 justify-center">
        <InactiveBizSection
          setFilters={setFilters}
          filters={filters}
          onFiltersChange={onFiltersChange}
        />
      </div>

      {data && !tainted && !isLoading && (
        <InactiveBizTable
          filters={filters}
          onFiltersChange={onFiltersChange}
          data={data}
        />
      )}
    </div>
  );
}
