import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { GoBack, PrimaryButton } from "../components/Buttons";
import { CollapsibleSection } from "../components/Cards";
import { SelectInput, TextInput, NumberInput } from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { useLoaderStore } from "../context";
import { useAlert, useApi } from "../hooks";
// import constants from "../utils/constants";
import { convertToLabelValueList, convertToLabelValue } from "../utils/helpers";

export default function BusinessLocation() {
  let { id } = useParams();
  const navigate = useNavigate();
  const alert = useAlert();
  const isCreatingNew = id === "0";

  const { fetch, post } = useApi();
  const { setShowLoader } = useLoaderStore();

  const [businessLocation, setBusinessLocation] = useState({ id });

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    !isCreatingNew ? `business/businessLocationInfo/${id}` : null,
    fetch,
    {
      revalidateOnFocus: false,
      onSuccess: () => setShowLoader(false),
      onError: () => setShowLoader(false),
    }
  );

  const {data: businessData } = useSWR(
    "business/getbusinessesSimple",
    (url) => fetch(url, { sortField: "" }),
  );
  // add swr calls here

  useEffect(() => {
    if (!isCreatingNew && !data) setShowLoader(true);
  }, []);

  useEffect(() => {
    if (!data) return;

    setBusinessLocation(data);
  }, [data]);


  const handleChange = (property, value) => {
    setBusinessLocation({
      ...businessLocation,
      [property]: value,
    });
  };

  const handleSave = () => {
    if (!isValid()) return;

    setShowLoader(true);
    post("business/saveBusinessLocation", businessLocation)
      .then((res) => {
        alert("Success", "Saved businessLocation", "success");
        navigate("/businessLocations");
      })
      .catch((err) => {
        alert(
          "Unable to save businessLocation",
          err.data.message || "Please try again",
          "error"
        );
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const isValid = () => {
    if (!businessLocation.name || !businessLocation.name.trim()) {
      alert("Form invalid", "Name must have a value", "warning");
      return false;
    }
    if (!businessLocation.name || !businessLocation.name.trim()) {
      alert("Form invalid", "Name must have a value", "warning");
      return false;
    }
    if (!businessLocation.business) {
      alert("Form invalid", "Business must have a value", "warning");
      return false;
    }
    // add validations here

    return true;
  };

  return (
    <div>
      {/* Header */}
      <PageHeader
        title={
          isCreatingNew || !businessLocation ? "Create new businessLocation" : `Manage businessLocation ${businessLocation.id}`
        }
      />
      <GoBack display="Manage all businessLocations" navigateTo="/businessLocations" />

      <div className="bg-gray-100 shadow-md rounded-md shadow-primaryColor/25">
        {/* Form */}
        <CollapsibleSection title="Info">
          <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4">
            <div>
              <TextInput
                label="Name"
                value={businessLocation?.name ?? ""}
                onChange={(e) => handleChange("name", e.target.value)}
                required
                maxLength={50}
              />
            </div>
            <div>
              <SelectInput
                label="Business"
                options={convertToLabelValueList(businessData)}
                onClear={() => handleChange("businessId", undefined)}
                value={convertToLabelValue(businessData?.find(x => x.id === businessLocation?.businessId))}
                onChange={(business) => handleChange("businessId", business.value)}
                isSearchable
              />
            </div>
            <div>
              <NumberInput
                label="Latitude"
                value={businessLocation?.latitude ?? ""}
                onChange={(e) => handleChange("latitude", e.target.valueAsNumber)}
              />
            </div>
            <div>
              <NumberInput
                label="Longitude"
                value={businessLocation?.longitude ?? ""}
                onChange={(e) => handleChange("longitude", e.target.valueAsNumber)}
              />
            </div>
           {/* Put fields here */}
          </div>
        </CollapsibleSection>

        {/* Submit button */}
        <div className="mt-12 pb-4 px-4">
          <PrimaryButton
            text={isCreatingNew ? "Create" : "Save"}
            onClick={handleSave}
          />
        </div>
      </div>
    </div>
  );
}
