import {
  CodeBracketIcon,
  GiftIcon,
  HomeIcon,
  NewspaperIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import {
  BriefcaseIcon,
  BuildingOfficeIcon,
  ChartBarIcon,
  CheckBadgeIcon,
  DocumentCheckIcon,
  ReceiptPercentIcon,
} from "@heroicons/react/24/solid";
import { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLayoutStore, useUserStore } from "../../context";
import constants from "../../utils/constants";
import { classNames } from "../../utils/helpers";

const SYSTEM_ADMIN_ITEMS = [
  { name: "Dashboard", icon: <HomeIcon />, href: "/", isHome: true },
  { name: "Users", icon: <UserGroupIcon />, href: "/users" },
  { name: "Businesses", icon: <BuildingOfficeIcon />, href: "/businesses" },
  {
    name: "Pending Businesses",
    icon: <DocumentCheckIcon />,
    href: "/reviewBusinesses",
  },
  { name: "Organizations", icon: <BriefcaseIcon />, href: "/organizations" },
  { name: "Reporting", icon: <ChartBarIcon />, href: "/reporting" },
  { name: "Manage Gifts", icon: <GiftIcon />, href: "/gifts" },
  { name: "Sessions", icon: <CodeBracketIcon />, href: "/sessions" },
  {
    name: "Business Followups",
    icon: <NewspaperIcon />,
      href: "/businessFollowups",
    },
  {
    name: "Stripe Dashboard",
      icon: <ReceiptPercentIcon />,
    href: "https://dashboard.stripe.com",
    externalUrl: "https://dashboard.stripe.com" ,
  }
];

const BUSINESS_ITEMS = [
  { name: "Dashboard", icon: <HomeIcon />, href: "/", isHome: true },
  { name: "Business", icon: <BuildingOfficeIcon />, href: "/businesses/" },
  {
    name: "Access Requests",
    icon: <CheckBadgeIcon />,
    href: "/businessRequests",
  },
  // { name: "Sales History", icon: <ReceiptPercentIcon />, href: "/salesHistory" },
  // { name: "Marketing", icon: <MegaphoneIcon />, href: "/marketing" },
  // { name: "Business Feed", icon: <RssIcon />, href: "/businessFeed" },
  { name: "Reporting", icon: <ChartBarIcon />, href: "/reporting" },
  { name: "Manage Gifts", icon: <GiftIcon />, href: "/gifts" },
];

const ORG_ITEMS = [
  { name: "Dashboard", icon: <HomeIcon />, href: "/", isHome: true },
  { name: "Organization", icon: <BriefcaseIcon />, href: "/organizations" },
  { name: "Access Requests", icon: <CheckBadgeIcon />, href: "/orgRequests" },
  // { name: "Marketing", icon: <MegaphoneIcon />, href: "/marketing" },
  // { name: "Organization Feed", icon: <RssIcon />, href: "/orgFeed" },
  { name: "Reporting", icon: <ChartBarIcon />, href: "/reporting" },
  // { name: "Send Gifts", icon: <GiftIcon />, href: "/gift" },
];

const NavItem = ({ item }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showSubItems, setShowSubItems] = useState(false);

  const [sidebarMenuOpen, setSidebarMenuOpen] = useLayoutStore((state) => [
    state.sidebarMenuOpen,
    state.setSidebarMenuOpen,
  ]);

  const isActive = item.isHome
    ? location.pathname === "/"
    : location.pathname.startsWith(item.href) && location.pathname !== "/";

  useEffect(() => {
    if (!sidebarMenuOpen) setShowSubItems(false);
  }, [sidebarMenuOpen]);

  return (
    <Fragment key={item.name + item.href + item.externalUrl}>
      <button  onClick={() => {
                  if (item.subItems) {
                      setSidebarMenuOpen(true);
                      setShowSubItems((show) => !show);
                  } else if (item.externalUrl) {
                      window.open(item.externalUrl, '_blank')
                  }

                  else {
                      navigate(item.href);
                  }
              }}
        key={item.name}
        className={classNames(
          isActive ? "bg-gray-100" : "text-gray-600 hover:text-gray-700",
          "group flex items-center p-6 h-8 text-sm font-large relative w-full mt-4"
        )}
      >
        {/* Left decoration rectangle*/}
        {isActive && (
          <div className="bg-darkerBlue/25 p-1 absolute left-0 h-full" />
        )}
        {/* Icon */}
        <div
          title={item.name}
          className={classNames(
            isActive
              ? "text-sandyOrange"
              : "text-gray-400 group-hover:text-burgundyRed",
            "ml-2 mr-3 flex-shrink-0 h-6 w-6"
          )}
          aria-hidden="true"
        >
          {item.icon}
        </div>
        {/* Item name */}
        {sidebarMenuOpen && (
          <div className="mx-2 whitespace-nowrap hover:text-burgundyRed">
            {item.name}
          </div>
        )}
      </button>

      {/* Submenu Items */}
      {showSubItems &&
        item.subItems &&
        item.subItems.map((subItem, i) => {
          const isActiveSubmenu = location.pathname.startsWith(subItem.href);

          return (
            <Link
              key={subItem.href + i}
              to={subItem.href}
              className={classNames(
                isActiveSubmenu ? "text-gray-900" : "",
                "justify-center flex text-sm text-gray-400 hover:text-gray-700 h-6 p-2 items-center"
              )}
            >
              {subItem.name}
            </Link>
          );
        })}
    </Fragment>
  );
};

export default function SideNavMenu() {
  const [sidebarMenuOpen] = useLayoutStore((state) => [state.sidebarMenuOpen]);
  const { currentUser } = useUserStore();
  const isSysAdmin = currentUser.roles.some(
    (role) => role.typeOfRole === constants.ROLE_IDS.SYSTEM_ADMIN
  );
  const businessAdmin =
    currentUser.roles.some(
      (role) => role.typeOfRole === constants.ROLE_IDS.ADMIN
    ) && currentUser?.businessIdList?.length > 0;
  const orgAdmin =
    currentUser.roles.some(
      (role) => role.typeOfRole === constants.ROLE_IDS.ADMIN
    ) && currentUser?.organizationIdList?.length > 0;
  // TODO: const reportAdmin = currentUser.roles.some((role) => role.typeOfRole === constants.ROLE_IDS.REPORTS);

  let roleBasedItems = [];
  if (isSysAdmin) {
    roleBasedItems = SYSTEM_ADMIN_ITEMS;
  } else if (businessAdmin) {
    roleBasedItems = BUSINESS_ITEMS;
  } else if (orgAdmin) {
    roleBasedItems = ORG_ITEMS;
  } else {
    return;
  }

  return (
    <Fragment>
      <div
        className={classNames(
          sidebarMenuOpen ? "w-64" : "w-20",
          "fixed top-16 flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white float-left h-[calc(100vh-4rem)] shadow-2xl z-10 transform duration-200"
        )}
      >
        <div className="flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
          <nav className="flex-1 space-y-3 bg-white" aria-label="Sidebar">
            {roleBasedItems.map((item, i) => (
              <NavItem key={item.href + i} item={item} />
            ))}
          </nav>
        </div>
        <hr />
      </div>
    </Fragment>
  );
}
