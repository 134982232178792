import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Square2StackIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import { ModalWrapper } from ".";
import { PrimaryButton } from "../Buttons";

export default function ButtonPromptModal({
  modalOpen,
  setModalOpen,
  title,
  message,
  buttonText,
  onButtonClick,
  Icon = Square2StackIcon
}) {

  return (
    <ModalWrapper open={modalOpen} setOpen={setModalOpen}>
      <div className="w-[85vw] lg:w-[35vw]">
        <div className="pb-4 text-center flex items-center flex-col">
          <Icon className="w-5 text-gray-500" />
          {title}
        </div>
        {message && (
          <div className="pb-8 mx-6 text-center text-sm text-gray-500">
            {message}
          </div>
        )}

        <div className="mb-8">
          <PrimaryButton
            text={buttonText}
            onClick={onButtonClick}
          />
        </div>

        <div className="mt-3">
          <PrimaryButton
            text="Close"
            backgroundColor="delete"
            onClick={setModalOpen}
          />
        </div>
      </div>
    </ModalWrapper>
  );
}
