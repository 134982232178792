import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { GoBack, PrimaryButton } from "../components/Buttons";
import { CollapsibleSection } from "../components/Cards";
import { SelectInput, DateInput } from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { useLoaderStore } from "../context";
import { useAlert, useApi } from "../hooks";
// import constants from "../utils/constants";
import { convertToLabelValueList, convertToLabelValue } from "../utils/helpers";

export default function CustomerLocationHistory() {
  let { id } = useParams();
  const navigate = useNavigate();
  const alert = useAlert();
  const isCreatingNew = id === "0";
  const { fetch, post } = useApi();
  const { setShowLoader } = useLoaderStore();
  const [customerLocationHistory, setCustomerLocationHistory] = useState({ id });

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    !isCreatingNew ? `customer/customerLocationHistoryInfo/${id}` : null,
    fetch,
    {
      revalidateOnFocus: false,
      onSuccess: () => setShowLoader(false),
      onError: () => setShowLoader(false),
    }
  );

  const {data: customerData } = useSWR(
    "customer/getcustomersSimple",
    (url) => fetch(url, { sortField: "" }),
  );
  const {data: businessLocationData } = useSWR(
    "business/getbusinessLocationsSimple",
    (url) => fetch(url, { sortField: "" }),
  );

  useEffect(() => {
    if (!isCreatingNew && !data) setShowLoader(true);
  }, []);

  useEffect(() => {
    if (!data) return;
    setCustomerLocationHistory(data);
  }, [data]);

  const handleChange = (property, value) => {
    setCustomerLocationHistory({
      ...customerLocationHistory,
      [property]: value,
    });
  };

  const handleSave = () => {
    if (!isValid()) return;

    setShowLoader(true);
    post("customer/saveCustomerLocationHistory", customerLocationHistory)
      .then((res) => {
        alert("Success", "Saved customerLocationHistory", "success");
        navigate("/customerLocationHistorys");
      })
      .catch((err) => {
        alert(
          "Unable to save customerLocationHistory",
          err.data.message || "Please try again",
          "error"
        );
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const isValid = () => {
    if (!customerLocationHistory.customer) {
      alert("Form invalid", "Customer must have a value", "warning");
      return false;
    }
    if (!customerLocationHistory.customer) {
      alert("Form invalid", "Customer must have a value", "warning");
      return false;
    }
    if (!customerLocationHistory.businessLocation) {
      alert("Form invalid", "Business Location must have a value", "warning");
      return false;
    }
    if (!customerLocationHistory.customer) {
      alert("Form invalid", "Customer must have a value", "warning");
      return false;
    }
    if (!customerLocationHistory.businessLocation) {
      alert("Form invalid", "Business Location must have a value", "warning");
      return false;
    }
    if (!customerLocationHistory.checkInAt) {
      alert("Form invalid", "Check In At must have a value", "warning");
      return false;
    }
    // add validations here

    return true;
  };

  return (
    <div>
      {/* Header */}
      <PageHeader
        title={
          isCreatingNew || !customerLocationHistory ? "Create new customerLocationHistory" : `Manage customerLocationHistory ${customerLocationHistory.id}`
        }
      />
      <GoBack display="Manage all customerLocationHistorys" navigateTo="/customerLocationHistorys" />

      <div className="bg-gray-100 shadow-md rounded-md shadow-primaryColor/25">
        {/* Form */}
        <CollapsibleSection title="Info">
          <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4">
            <div>
              <SelectInput
                label="Customer"
                options={convertToLabelValueList(customerData)}
                onClear={() => handleChange("customerId", undefined)}
                value={convertToLabelValue(customerData?.find(x => x.id === customerLocationHistory?.customerId))}
                onChange={(customer) => handleChange("customerId", customer.value)}
                isSearchable
              />
            </div>
            <div>
              <SelectInput
                label="Business Location"
                options={convertToLabelValueList(businessLocationData)}
                onClear={() => handleChange("businessLocationId", undefined)}
                value={convertToLabelValue(businessLocationData?.find(x => x.id === customerLocationHistory?.businessLocationId))}
                onChange={(businessLocation) => handleChange("businessLocationId", businessLocation.value)}
                isSearchable
              />
            </div>
            <div>
              <DateInput
                label="Check In At"
                value={customerLocationHistory?.checkInAt ?? ""}
                onChange={(e) => handleChange("checkInAt", e.target.value)}
                required
              />
            </div>
            <div>
              <DateInput
                label="Check Out At"
                value={customerLocationHistory?.checkOutAt ?? ""}
                onChange={(e) => handleChange("checkOutAt", e.target.value)}
              />
            </div>
           {/* Put fields here */}
          </div>
        </CollapsibleSection>

        {/* Submit button */}
        <div className="mt-12 pb-4 px-4">
          <PrimaryButton
            text={isCreatingNew ? "Create" : "Save"}
            onClick={handleSave}
          />
        </div>
      </div>
    </div>
  );
}
