import { useNavigate } from "react-router";
import { PrimaryButton } from "../components/Buttons";

export default function NotFound404() {
  const navigate = useNavigate();

  return (
    <>
      <main className="grid h-screen justify-center items-center px-6 lg:px-8 pb-80">
        <div className="text-center">
          <p className="text-base font-semibold text-primaryColor">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Page not found
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Sorry, we couldn’t find the page you’re looking for.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <PrimaryButton text="Go back home" onClick={() => navigate("/")} />
          </div>
        </div>
      </main>
    </>
  );
}
