import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { PrimaryButton } from "../components/Buttons";
import { PasswordInput } from "../components/Inputs";
import { useLoaderStore } from "../context";
import { useAlert, useApi } from "../hooks";
import { isValidPassword } from "../utils/helpers";
import { useNavigate } from "react-router";
import { ShieldExclamationIcon } from "@heroicons/react/24/solid";
import { ShieldCheckIcon } from "@heroicons/react/24/solid";
import storage from "../utils/storage";

export default function ResetPassword() {
  const alert = useAlert();
  const navigate = useNavigate();

  const { post, fetch } = useApi();
  const { token } = useParams();
  const { setShowLoader } = useLoaderStore();

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [successRedeem, setSuccessRedeem] = useState(false);

  const handleSubmit = async () => {
    if (!isValid()) return;

    setShowLoader(true);

    await post("public/resetPassword", { token, password: newPassword })
      .then((res) => {
        storage.setItem(storage.ITEMS.username, res.username);
        alert("Success!", "Password successfully reset", "success");
        setSuccessRedeem(true);
      })
      .catch((err) => {
        alert("Unsuccessful reset password attempt", "Could not reset password", "error");
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const isValid = () => {
    if (
      !newPassword ||
      !newPassword.trim() ||
      !newPasswordConfirmation ||
      !newPasswordConfirmation.trim()
    ) {
      alert(
        "Invalid input",
        "Please enter a valid password and confirmation password",
        "warning"
      );
      return false;
    }

    if (!isValidPassword(newPassword.trim())) {
      alert("Invalid input", "Password is not complex enough", "warning");
      return false;
    }

    if (newPassword.trim() !== newPasswordConfirmation.trim()) {
      alert("Invalid input", "Passwords do not match", "warning");
      return false;
    }

    return true;
  };

  async function isResetPasswordTokenValid(token) {
    setShowLoader(true);
    await fetch(`public/VerifyResetPasswordToken/${token}`)
    .then((response) => {
      setIsTokenValid(response);
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      setShowLoader(false);
    });
  }

  useEffect(() => {
    isResetPasswordTokenValid(token);
  }, [token]);

  return (
    <>
    {!isTokenValid ? (
      <>
        <h1 className="text-3xl font-bold text-black text-center">Invalid Token</h1>
        <ShieldExclamationIcon className="w-24 mx-auto text-red-700" />
        <h2 className="text-lg text-klinkGray text-center my-1">
          The token you are using is either invalid or expired.
          <span className="block">Please request a new password reset link.</span>
        </h2>
      </>
    ) : successRedeem ? (
      <>
        <h1 className="text-3xl font-bold text-black text-center">Success!</h1>
        <ShieldCheckIcon className="w-24 mx-auto text-lime-600" />
        <h2 className="text-lg text-klinkGray text-center my-1">
          Successfully reset password.
        </h2>
      </>
    ) : (
    <>
      <h1 className="text-3xl font-bold text-black">Reset Password</h1>
      <h5 className="mt-1 mb-6">
        Or{" "}
        <Link to="/" className="text-klinkMagenta hover:text-primaryColor/50">
          back to sign in
        </Link>
      </h5>

      <div className="w-full space-y-6">
        <div>
          <PasswordInput
            label="New Password"
            name="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            showInfo
          />
        </div>
        <div>
          <PasswordInput
            label="Retype New Password"
            name="retypeNewPassword"
            value={newPasswordConfirmation}
            onChange={(e) => setNewPasswordConfirmation(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSubmit();
            }}
          />
        </div>
        <PrimaryButton text="Change Password" onClick={handleSubmit} />
      </div>
      </>
    )}
    </>
  );
}
