// import _ from "lodash";
import constants from "../utils/constants";
// import { getCountryCallingCode } from "react-phone-number-input/input";
// import { useApi } from "../hooks";

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function JsonTryParse(str) {
  try {
    if (typeof str === "undefined") return undefined;
    return JSON.parse(str);
  } catch (e) {
    return undefined;
  }
}

export function isValidEmail(email) {
  const emailRegex = /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,}$/;
  return emailRegex.test(email);
}

export function isValidPassword(password) {
  const regex =
    /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+={}[\]:"|;',.<>/?])[A-Za-z\d!@#$%^&*()_\-+={}[\]:"|;',.<>/?]{8,}$/;
  return regex.test(password);
}

export function formatCurrency(v) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(v);
}

export function convertPenniesToDollars(pennyCount) {
  return pennyCount / 100;
}

export function switchSortDirection(currentDirection) {
  switch (currentDirection) {
    case constants.SORT_DIRECTIONS.ASCENDING:
      return constants.SORT_DIRECTIONS.DESCENDING;
    case constants.SORT_DIRECTIONS.DESCENDING:
    default:
      return constants.SORT_DIRECTIONS.ASCENDING;
  }
}

export function countDefinedKeys(object) {
  let count = 0;

  for (const key in object) {
    if (
      object.hasOwnProperty(key) &&
      object[key] !== undefined &&
      object[key] !== "" &&
      object[key] !== false
    ) {
      count++;
    }
  }
  return count;
}

export function convertToLabelValueList(list) {
  if (!list || !Array.isArray(list)) return [];

  return list.map((x) => ({ label: x.name, value: x.id }));
}

export function convertToLabelValue(obj) {
  if (!obj) return;
  return { label: obj.name, value: obj.id };
}

export function convertToIdNameList(list) {
  if (!list || !Array.isArray(list)) return [];
  return list.map((x) => ({ id: x.value, name: x.label }));
}

export function convertToIdName(obj) {
  if (!obj) return;
  return { id: obj.value, name: obj.label };
}

export function equivalentObjects(objA, objB) {
  if (!objA || !objB) return false; 
  
  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false; 
  }

  for (const key of keysA) {
    if (objA[key] !== objB[key]) {
      return false; 
    }
  }

  return true; 
}

export const triggerDownloadFromBlob = (blob, filename) => {
  const downloadUrl = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = downloadUrl;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  URL.revokeObjectURL(downloadUrl);
};