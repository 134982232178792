import useApi from './useApi';
import constants from '../utils/constants';
import { triggerDownloadFromBlob } from '../utils/helpers';

const useReportDownloadHelpers = () => {
  const { postBinary, fetchBinary } = useApi();

  const downloadFileFromApiPost = async (apiRoute, requestBody, filename, fileType = null) => {
    try {
      const response = await postBinary(apiRoute, requestBody);
      const blob = new Blob([response], {
        type: fileType ?? constants.MIME_TYPES.XLSX
      })
      triggerDownloadFromBlob(blob, filename);
    } catch (error) {
      console.error('Download failed:', error);
    }
  };

  const downloadFileFromApiFetch = async (apiRoute, filename, params = {}, fileType = null) => {
    try {
      const queryParams = new URLSearchParams(params);
      const urlWithParams = `${apiRoute}?${queryParams.toString() ? queryParams.toString() : ''}`;
      const response = await fetchBinary(urlWithParams);
      const blob = new Blob([response], {
        type: fileType ?? constants.MIME_TYPES.XLSX
      })
      triggerDownloadFromBlob(blob, filename);
    } catch (error) {
      console.error('Download failed:', error);
    }
  };

  return {
    downloadFileFromApiPost,
    downloadFileFromApiFetch
  }
}

export default useReportDownloadHelpers;