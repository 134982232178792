import {
  ArrowPathIcon,
  PlusCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { TextInput, ToggleInput } from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { FilterSection, PrimaryTable } from "../components/Tables";
import { useLoaderStore } from "../context";
import { useAlert, useApi, useDebounce } from "../hooks";
import constants from "../utils/constants";
// import dateHelpers from "../utils/dateHelpers";
import { switchSortDirection } from "../utils/helpers";

export default function BusinessLocations() {
  const navigate = useNavigate();
  const alert = useAlert();
  const { post, fetch } = useApi();
  const { setShowLoader } = useLoaderStore();

  const [filters, setFilters] = useState({ activeOnly: true });
  const [currentPage, setCurrentPage] = useState(1);
  const [sortDirection, setSortDirection] = useState(
    constants.SORT_DIRECTIONS.ASCENDING
  );
  const [sortField, setSortField] = useState("id");
  const [displayCount, setDisplayCount] = useState(
    constants.DISPLAY_PER_PAGE ?? 25
  );

  const { debounced: debouncedFilters } = useDebounce({ toDebounce: filters });
  const { debounced: debouncedDisplayCount } = useDebounce({
    toDebounce: displayCount,
  });

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    [
      "business/businessLocationList",
      currentPage,
      debouncedDisplayCount,
      debouncedFilters,
      sortDirection,
      sortField,
    ],
    ([
      url,
      currentPage,
      debouncedDisplayCount,
      debouncedFilters,
      sortDirection,
      sortField,
    ]) =>
      fetch(url, {
        pageNumber: currentPage,
        pageSize: debouncedDisplayCount,
        sortDirection,
        sortField,
        isExact: false,
        ...debouncedFilters,
      })
  );

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(switchSortDirection(sortDirection));
    } else {
      setSortField(field);
    }
  };

const toggleActiveStatus = (businessLocation) => {
    setShowLoader(true);
    post(`business/toggleBusinessLocationActive/${businessLocation.id}`)
      .then((res) => {
        alert("Success",
          res.message || "BusinessLocation active status toggled",
          "success");
        mutate();
      })
      .catch((err) => {
        alert("Error",
          err.data.message || "BusinessLocation active status could not be toggled",
          "error");
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
};
  // Insert toggleActiveStatus here

  return (
    <div>
      {/* Header */}
      <div className="flex justify-between">
        <PageHeader title="Manage BusinessLocations" />
        <PlusCircleIcon
          className="w-8 text-sandyOrange hover:text-yellow/50 cursor-pointer"
          onClick={() => navigate("/businessLocations/0")}
        />
      </div>

      {/* Filter section */}
      <BusinessLocationFilterSection filters={filters} setFilters={setFilters} />

      {/* Table */}
      <PrimaryTable
        isLoading={isLoading}
        filters={filters}
        totalCount={data?.totalCount}
        displayCount={displayCount}
        currentPage={currentPage}
        setDisplayCount={setDisplayCount}
        setCurrentPage={setCurrentPage}
        header={
          <PrimaryTable.Header>
            <PrimaryTable.HeaderColumn
              display="Id"
              value={filters.id}
              sortable
              onSort={() => handleSort("id")}
              isSortingBy={sortField === "id"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, id: "" })}
              onType={(e) => setFilters({ ...filters, id: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Name"
              value={filters.name}
              sortable
              onSort={() => handleSort("name")}
              isSortingBy={sortField === "name"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, name: "" })}
              onType={(e) => setFilters({ ...filters, name: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Business"
              value={filters.business}
              sortable
              onSort={() => handleSort("business")}
              isSortingBy={sortField === "business"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, business: "" })}
              onType={(e) => setFilters({ ...filters, business: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Latitude"
              value={filters.latitude}
              sortable
              onSort={() => handleSort("latitude")}
              isSortingBy={sortField === "latitude"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, latitude: "" })}
              onType={(e) => setFilters({ ...filters, latitude: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Longitude"
              value={filters.longitude}
              sortable
              onSort={() => handleSort("longitude")}
              isSortingBy={sortField === "longitude"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, longitude: "" })}
              onType={(e) => setFilters({ ...filters, longitude: e.target.value })}
            />
            {/* Insert column headers here */}
            <PrimaryTable.HeaderColumn display="" />
          </PrimaryTable.Header>
        }
      >
        {data &&
          data.list &&
          data.list.map((x, i) => (
            <PrimaryTable.Row
              key={`${x.id}-${i}`}
              index={i}
              onClick={(e) => {
                if (!x.deactivatedAt) {
                  navigate(`/businessLocations/${x.id}`);
                }
              }}
            >
              <PrimaryTable.Cell>{x.id}</PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {x.name}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {x.business}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {x.latitude}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {x.longitude}
              </PrimaryTable.Cell>
              {/* Insert cells here */}
              <PrimaryTable.Cell>
                <div className="flex">
  {x.deactivatedAt ? (
    <ArrowPathIcon
        title="Activate"
        className="w-6 mx-1 text-green-900 rounded-md p-0.5 cursor-pointer"
        onClick={(e) => {
            e.stopPropagation();
            toggleActiveStatus(x);
        }}
    />
  ) : (
    <XMarkIcon
        title="Deactivate"
        className="w-6 mx-1 text-white bg-delete rounded-md p-0.5 cursor-pointer"
        onClick={(e) => {
            e.stopPropagation();
            toggleActiveStatus(x);
        }}
    />
  )}
                  {/* Insert flip active status here */}
                </div>
              </PrimaryTable.Cell>
            </PrimaryTable.Row>
          ))}
      </PrimaryTable>
    </div>
  );
}

const BusinessLocationFilterSection = ({ filters, setFilters }) => {
  return (
    <FilterSection filters={filters}>
      <div>
        <div className="mb-2">
          <ToggleInput
            label="Show active records only"
            enabled={filters.activeOnly}
            setEnabled={() =>
              setFilters({
                ...filters,
                activeOnly: !filters.activeOnly,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Id"
            placeholder="Id"
            value={filters.id}
            onChange={(e) =>
              setFilters({
                ...filters,
                id: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Name"
            placeholder="Name"
            value={filters.name}
            onChange={(e) =>
              setFilters({
                ...filters,
                name: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Business"
            placeholder="Business"
            value={filters.business}
            onChange={(e) =>
              setFilters({
                ...filters,
                business: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Latitude"
            placeholder="Latitude"
            value={filters.latitude}
            onChange={(e) =>
              setFilters({
                ...filters,
                latitude: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Longitude"
            placeholder="Longitude"
            value={filters.longitude}
            onChange={(e) =>
              setFilters({
                ...filters,
                longitude: e.target.value,
              })
            }
          />
        </div>
        {/* Add filters here */}
      </div>
    </FilterSection>
  );
};
