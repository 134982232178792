import {
  ArrowPathIcon,
  PlusCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { TextInput, ToggleInput } from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { PrimaryButton } from "../components/Buttons";
import { FilterSection, PrimaryTable } from "../components/Tables";
import { useLoaderStore } from "../context";
import { useAlert, useApi, useDebounce } from "../hooks";
import constants from "../utils/constants";
import { switchSortDirection } from "../utils/helpers";

export default function Organizations() {
  const navigate = useNavigate();
  const alert = useAlert();
  const { post, fetch } = useApi();
  const { setShowLoader } = useLoaderStore();

  const [filters, setFilters] = useState({ activeOnly: true });
  const [currentPage, setCurrentPage] = useState(1);
  const [sortDirection, setSortDirection] = useState(
    constants.SORT_DIRECTIONS.ASCENDING
  );
  const [sortField, setSortField] = useState("id");
  const [displayCount, setDisplayCount] = useState(
    constants.DISPLAY_PER_PAGE ?? 25
  );

  const { debounced: debouncedFilters } = useDebounce({ toDebounce: filters });
  const { debounced: debouncedDisplayCount } = useDebounce({
    toDebounce: displayCount,
  });

  useEffect(() => {
    // Reset page number and page size when filters change
    setCurrentPage(1);
    setDisplayCount(25);
  }, [filters]);

  const { data, isLoading, mutate } = useSWR(
    [
      "admin/OrgList",
      currentPage,
      debouncedDisplayCount,
      debouncedFilters,
      sortDirection,
      sortField,
    ],
    ([
      url,
      currentPage,
      debouncedDisplayCount,
      debouncedFilters,
      sortDirection,
      sortField,
    ]) =>
      fetch(url, {
        pageNumber: currentPage,
        pageSize: debouncedDisplayCount,
        sortDirection,
        sortField,
        isExact: false,
        ...debouncedFilters,
      })
  );

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(switchSortDirection(sortDirection));
    } else {
      setSortField(field);
    }
  };

  const toggleActiveStatus = (org) => {
    setShowLoader(true);
    post(`organization/toggleOrgActive/${org.id}`)
      .then((res) => {
        alert("Success", res.message || "Org active status toggled", "success");
        mutate();
      })
      .catch((err) => {
        alert(
          "Error",
          err.data.message || "Org active status could not be toggled",
          "error"
        );
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };
  // Insert toggleActiveStatus here

  return (
    <div className="w-100 h-auto">
      {/* Header */}
      <div className="flex justify-between">
        <PageHeader title="Manage Organizations" />
        <PlusCircleIcon
          className="w-8 text-sandyOrange hover:text-yellow/50 cursor-pointer"
          onClick={() => navigate("/organizations/0")}
        />
      </div>

      {/* Filter section */}
      <OrgFilterSection filters={filters} setFilters={setFilters} />

      {/* Table */}
      <PrimaryTable
        isLoading={isLoading}
        filters={filters}
        totalCount={data?.totalCount}
        displayCount={displayCount}
        currentPage={currentPage}
        setDisplayCount={setDisplayCount}
        setCurrentPage={setCurrentPage}
        header={
          <PrimaryTable.Header>
            <PrimaryTable.HeaderColumn
              display="Name"
              value={filters.name}
              sortable
              onSort={() => handleSort("name")}
              isSortingBy={sortField === "name"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, name: "" })}
              onType={(e) => setFilters({ ...filters, name: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Email"
              value={filters.email}
              sortable
              onSort={() => handleSort("email")}
              isSortingBy={sortField === "email"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, email: "" })}
              onType={(e) => setFilters({ ...filters, email: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Phone"
              value={filters.phone}
              sortable
              onSort={() => handleSort("phone")}
              isSortingBy={sortField === "phone"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, phone: "" })}
              onType={(e) => setFilters({ ...filters, phone: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Timezone"
              value={filters.timezone}
              sortable
              onSort={() => handleSort("timezone")}
              isSortingBy={sortField === "timezone"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, timezone: "" })}
              onType={(e) =>
                setFilters({ ...filters, timezone: e.target.value })
              }
            />
            <PrimaryTable.HeaderColumn
              display="Biography"
              value={filters.biography}
              sortable
              onSort={() => handleSort("biography")}
              isSortingBy={sortField === "biography"}
              sortDirection={sortDirection}
              colSpan="2"
              onClear={() => setFilters({ ...filters, biography: "" })}
              onType={(e) =>
                setFilters({ ...filters, biography: e.target.value })
              }
            />
          </PrimaryTable.Header>
        }
      >
        {data &&
          data.list &&
          data.list.map((x, i) => (
            <PrimaryTable.Row
              key={`${x.id}-${i}`}
              index={i}
              onClick={(e) => {
                if (!x.deactivatedAt) {
                  navigate(`/organizations/${x.id}`);
                }
              }}
            >
              <PrimaryTable.Cell>{x.name}</PrimaryTable.Cell>
              <PrimaryTable.Cell>{x.email}</PrimaryTable.Cell>
              <PrimaryTable.Cell>{x.phone}</PrimaryTable.Cell>
              <PrimaryTable.Cell>{x.timezone}</PrimaryTable.Cell>
              <PrimaryTable.Cell>{x.biography}</PrimaryTable.Cell>
              {/* Insert cells here */}
              <PrimaryTable.Cell>
                <div className="flex">
                  {x.deactivatedAt ? (
                    <ArrowPathIcon
                      title="Activate"
                      className="w-6 mx-1 text-white bg-green-500 rounded-md p-0.5 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleActiveStatus(x);
                      }}
                    />
                  ) : (
                    <XMarkIcon
                      title="Deactivate"
                      className="w-6 mx-1 text-white bg-delete rounded-md p-0.5 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleActiveStatus(x);
                      }}
                    />
                  )}
                  {/* Insert flip active status here */}
                </div>
              </PrimaryTable.Cell>
            </PrimaryTable.Row>
          ))}
      </PrimaryTable>
    </div>
  );
}

const OrgFilterSection = ({ filters, setFilters }) => {
  return (
    <FilterSection filters={filters}>
      <div>
        <PrimaryButton 
          text="Reset"
          backgroundColor="gray-500"
          className="float-right"
          small
          onClick={() => setFilters({})}                    
        />
        <div className="mb-2">
          <ToggleInput
            label="Show active records only"
            enabled={filters.activeOnly}
            setEnabled={() =>
              setFilters({
                ...filters,
                activeOnly: !filters.activeOnly,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Name"
            placeholder="Name"
            value={filters.name}
            onChange={(e) =>
              setFilters({
                ...filters,
                name: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Email"
            placeholder="Email"
            value={filters.email}
            onChange={(e) =>
              setFilters({
                ...filters,
                email: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Phone"
            placeholder="Phone"
            value={filters.phone}
            onChange={(e) =>
              setFilters({
                ...filters,
                phone: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Timezone"
            placeholder="Timezone"
            value={filters.timezone}
            onChange={(e) =>
              setFilters({
                ...filters,
                timezone: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Biography"
            placeholder="Biography"
            value={filters.biography}
            onChange={(e) =>
              setFilters({
                ...filters,
                biography: e.target.value,
              })
            }
          />
        </div>
        {/* Add filters here */}
      </div>
    </FilterSection>
  );
};
