import  TERMS_OF_SERVICE_DATA from "../utils/termsOfServiceConstant";
import _ from 'lodash';
import styles from './../css/TermsOfService.module.css';

export default function TermsOfService() {
  return (
    <div className={styles.termsOfService}>
    <section className={styles["tos-section"]}>
      <h1 style={{margin: 0, marginTop: "1.25rem"}}>Terms of Service</h1>
      <h2 style={{fontSize: "x-large", marginleft: "1px"}}>
        Last updated January 30, 2024
      </h2>
      <p>
        CYW, LLC, its subsidiaries and affiliates ("CYW", "we", "our", "us",
        "Klink-iT.com", "Klink-iT") and/or Company and/or our Company,) (as
        defined below), own and operate certain mobile applications, websites,
        mobile device applications and other tools, technology and programs
        (collectively identified as the "Platform" and/or the "Service" as well
        as services associated with or made available through the Platform
        and/or the Service (collectively, "the Services"). These terms of
        service govern and control your access to the Platform and Services and
        constitute a legal agreement between you and CYW, LLC and/or our
        Company. Read these terms carefully and do not agree to them if you do
        not understand them. If you don’t understand these terms, consult with
        an attorney that may be able to help you understand them before you
        agree to them.
      </p>
      <p>
        The primary function of our Company, Platform and Service is to provide
        an opportunity for properly registered and authorized users to engage in
        the selection and giving of a gift(s) to other registered and authorized
        users who may redeem your gift in the form of food, beverage,
        merchandise and other goods that may be offered by participating and
        also registered business entities (or affiliates). The participation by
        any gift giver, recipient and/or business entity is entirely voluntary
        but is allowed only within these terms of service. CYW, LLC and our
        Company may refer to itself and appear in this document as CYW, LLC, the
        Company, our Company, we, our, us, Klink-iT, Klink-iT.com, and may also
        do business under any of the subsidiary and/or affiliate names and
        references.
      </p>
      <p>
        We welcome you to our family of brands website located at Klink-iT.com,
        or our app and to any electronic access point of our Platform and
        Services and of our Affiliates that link to these Terms of Service
        (collectively, the "Website" and the "App" and "Apps"). These Terms of
        Service ("Terms") govern your use of: (i) our Website, (ii) our mobile
        applications ("Apps"), (iii) our social media applications and/or
        platforms (such as our Facebook applications), (iv) any of our
        merchandising channels, including, but not limited to, internet,
        telephone, text messaging (SMS and MMS), online orders, radio,
        television, or Company-owned or retail stores (both brick and mortar as
        well as virtual), and (v) any of our products and/or services that are
        made available to you through any of the foregoing (collectively,,
        together, the "Service"). The goal of the Platform and Service is to
        provide access to a wide selection of gift giving and/or gifting options
        including beverage, food, merchandise, events, and other products and
        services, as well as, information and ideas, to as wide an audience as
        possible. To ensure a safe, legal and pleasant environment for all of
        our users, we have established these Terms of Service and Use. In this
        way, you will know what you can expect from us and what we expect from
        you
      </p>
      <p style={{fontWeight: "bold"}}>
        THESE TERMS OF SERVICE ALSO APPLY TO ALL COMMERCE CHANNELS OF CYW, LLC,
        THE COMPANY AND ITS AFFILIATES, THE PLATFORM and THE SERVICE INCLUDING,
        BUT NOT LIMITED TO, OUR APP, OUR WEBSITE, THE INTERNET, TELEPHONE,
        RADIO, TELEVISION, MOBILE DEVICE, SOCIAL MEDIA AND PARTICIPATING RETAIL
        ESTABLISHMENTS. BY ACCESSING ANY OF THE COMPANY MERCHANDISING CHANNELS,
        AND ANY AREAS OF THE SERVICE, YOU AGREE TO BE LEGALLY BOUND, AND TO
        ABIDE, BY THESE TERMS OF SERVICE and USE. PLEASE BE AWARE THAT THESE
        TERMS OF USE CONTAIN A BINDING ARBITRATION AGREEMENT AND CLASS ACTION
        WAIVER THAT WAIVE YOUR RIGHT TO A COURT HEARING AND JURY TRIAL (to the
        extent such waiver is allowed at the time of your agreement in the legal
        jurisdiction of the State of North Carolina).
      </p>
      <p>
        CYW, LLC, its subsidiaries and affiliates ("CYW, LLC", "we", "our", "us"
        "Klink-iT" and/or Klink-iT.com) and/or the Company, our Company,
        Platform and/or Service do not sell, offer to sell or solicit sales of
        alcohol and/or products containing alcohol. We do not purchase, sell,
        manufacture, store, maintain, import, supply or keep any alcohol and/or
        alcoholic products. Any business and/or retailer and/or affiliate
        authorized and registered to use or Platform and Service that does offer
        any alcohol or alcoholic products that may be accessed through our
        Platform and/or Service expressly agrees to do so only in full
        compliance with both state and federal law at the time of such offering
        and in full compliance with these Terms of Service. Our Platform and
        Services are not designed or intended to facilitate any improper or
        illegal furnishing of inducements by any business, retailer,
        manufacturer, importer, supplier or any other entity of alcohol and/or
        alcoholic products. We are not a manufacturer, distributor, seller,
        retailer, supplier or any other furnisher of alcohol and/or alcoholic
        products. We may charge you a fee when you redeem a gift through our
        Platform and/or Service. We may charge any participating business and/or
        retailer a fee upon redemption of any gift purchased through our
        Platform and/or Service. The use of our Platform and/or Service is not
        conditioned and in no way requires the purchase, gifting, receipt,
        possession, use and/or consumption of alcohol and/or alcoholic products.
        Any fee charged and/or earned is exclusively for the use of the
        proprietary Platform and Service without regard to the type or nature of
        the gift given and/or received.
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>Age Restrictions</h2>
      <p>
        You must be 16 years or older and the age of majority in your place of
        residence to use, subscribe, or register as a member of (or 21 years or
        older in the event that you intend to or actually engage in a
        transaction wherein an order for a product containing alcohol) our
        Platform and Services.
      </p>
      <p>
        Some of the products offered by third party businesses and/or retailers
        and/or other entities accessible through our Platform and Service may
        contain alcohol (alcoholic beverage) and/or alcoholic products. You must
        be 21 years or older to use any aspect of our Platform and Service that
        involves any transaction of a product containing alcohol, which may be
        redeemed or converted at any designated retailer user, affiliate, bar,
        restaurant, brewery or other business for acceptance, redemption and/or
        processing. Similarly, the recipient of your transaction must be 21
        years or older to accept and/or redeem any gift containing alcohol. Any
        attempt(s) to buy, accept, transfer, redeem or otherwise exchange or
        transact for any product containing alcohol by anyone under 21 years of
        age shall be void and is in violation of these Terms of Service. Any
        participating business, affiliate, store, bar, restaurant, retail shop
        and/or any other business shall require adequate identification and age
        verification under the law prior to redemption of any gift containing
        alcohol. By placing your request for a product order containing alcohol,
        you certify under penalty of law that you and the intended recipient are
        at least 21 years old. Any willful misrepresentation of your, or the
        recipient's, age, to unlawfully obtain an alcoholic beverage and/or
        other product containing alcohol is a crime and the Company will
        cooperate with legal authorities to prosecute you and the recipient to
        the fullest extent of the law should you make the unfortunate choice of
        breaking the law and/or these terms of use.
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>Ownership, Trademarks and Patents</h2>
      <p>
        Our Company, Platform, Service, App, Website and the Service is operated
        by CYW, LLC. "Affiliates" are those persons or entities directly or
        indirectly controlling, controlled by, or under common control with CYW,
        LLC and Klink-iT and/or Klink-iT.com. One or more patents may apply to
        this Our Platform, Service, App, Website and the Service, including
        without limitation any service marks, trademarks, copyrights and
        patents. All other third-party trademarks, product names, and company
        names and logos and any other intellectual property appearing on the
        Platform and/or Service are the property of their respective owners.
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>Disclaimers and Limitation of Liability</h2>
      <p>
        EXCEPT AS OTHERWISE SPECIFICALLY SET FORTH TO THE CONTRARY HEREIN, AND
        TO THE FULLEST EXTENT PERMITTED BY LAW, YOU EXPRESSLY AGREE THAT USE OF
        THE PLATFORM and SERVICE IS AT YOUR SOLE RISK. NEITHER THE COMPANY, NOR
        ITS AFFILIATES, NOR ANY OF ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS,
        THIRD-PARTY SERVICE OR CONTENT PROVIDERS ("PROVIDERS"), MERCHANTS
        ("MERCHANTS"), SPONSORS ("SPONSORS"), LICENSORS ("LICENSORS"), OR THE
        LIKE (COLLECTIVELY, "ASSOCIATES"), WARRANT THAT THE PLATFORM or SERVICE
        WILL BE UNINTERRUPTED OR ERROR-FREE; NOR DO THEY MAKE ANY WARRANTY AS TO
        THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF SERVICE, OR AS TO THE
        ACCURACY, RELIABILITY, OR CURRENCY OF ANY INFORMATION CONTENT, SERVICE,
        OR MERCHANDISE PROVIDED THROUGH THE SERVICE.
      </p>
      <p>
        EXCEPT AS OTHERWISE SPECIFICALLY SET FORTH TO THE CONTRARY HEREIN, THE
        PLATFORM and SERVICE IS PROVIDED ON AN "AS IS," "AS AVAILABLE" BASIS AND
        THE COMPANY SPECIFICALLY DISCLAIMS WARRANTIES OF ANY KIND, EITHER
        EXPRESSED OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF TITLE
        OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR
        PURPOSE. NO ORAL ADVICE OR WRITTEN OR ELECTRONICALLY DELIVERED
        INFORMATION GIVEN BY THE COMPANY OR ITS AFFILIATES, OR ANY OF ITS
        OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, PROVIDERS, MERCHANTS, SPONSORS,
        LICENSORS, OR THE LIKE, SHALL CREATE ANY WARRANTY WHATSOEVER.
      </p>
      <p>
        EXCEPT AS OTHERWISE SPECIFICALLY SET FORTH TO THE CONTRARY HEREIN, TO
        THE FULLEST EXTENT PERMITTED BY LAW, UNDER NO CIRCUMSTANCES SHALL THE
        COMPANY, THE PLATFORM and SERVICE, ITS AFFILIATES, OR ANY OTHER PARTY
        INVOLVED IN CREATING, PRODUCING, MANUFACTURING, DISTRIBUTING, MARKETING,
        OR SELLING THE SERVICE, PRODUCTS, SERVICES OR THIS WEBSITE, BE LIABLE
        FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES,
        INCLUDING WITHOUT LIMITATION, LOST PROFITS THAT RESULT FROM THE USE OF
        OR INABILITY TO USE THE PLATFORM or SERVICE, INCLUDING BUT NOT LIMITED
        TO RELIANCE BY YOU ON ANY INFORMATION OBTAINED FROM THE PLATFORM or
        SERVICE OR THAT RESULT FROM MISTAKES, OMISSIONS, INTERRUPTIONS, DELETION
        OF FILES OR EMAIL, ERRORS, DEFECTS, VIRUSES, DELAYS IN OPERATION OR
        TRANSMISSION, OR ANY FAILURE OF PERFORMANCE, WHETHER OR NOT RESULTING
        FROM ACTS OF GOD, COMMUNICATIONS FAILURE, THEFT, DESTRUCTION, OR
        UNAUTHORIZED ACCESS TO THE COMPANY'S RECORDS, PROGRAMS, OR SERVICES
        (otherwise known as Hacking).
      </p>
      <p>
        UNLESS OTHERWISE SPECIFICALLY SET FORTH TO THE CONTRARY HEREIN, AND TO
        THE FULLEST EXTENT PERMITTED BY LAW, ALL PRICES FOR ANY AND ALL PRODUCTS
        OR SERVICES OFFERED ("OFFERS') ARE EXCLUSIVE OF APPLICABLE SERVICE AND
        SHIPPING CHARGES, FEES, SURCHARGES, AND FEDERAL, STATE AND LOCAL TAXES.
        ALL OF OUR PRODUCTS AND SERVICES ARE NON-TRANSFERABLE, ARE NOT FOR
        RESALE AND MAY NOT BE REDEEMED FOR CASH. PRICES AND CHARGES DISPLAYED ON
        OUR PLATFORM, OR SERVICE MAY DIFFER FROM PRICES THAT ARE AVAILABLE IN
        AFFILIATE AND/OR PARTICIPATING BUSINESS ENTITIES.
      </p>
      <p>
        THE COMPANY RESERVES THE RIGHT, WITHIN ITS SOLE DISCRETION, TO REFUSE TO
        ACCEPT AND PROCESS ANY AND ALL CUSTOMER GIFTS, TRANSACTIONS AND/OR
        REDEMPTIONS AND TO SUSPEND, DISCONTINUE, AND REFUSE THE USE OR
        ACCEPTANCE OF ANY AND ALL OFFERS OF ADVERTISING ERRORS AND/OR THE ACTUAL
        OR SUSPECTED MISUSE, FRAUD OR ILLEGALITY.
      </p>
      <p>
        YOU HEREBY ACKNOWLEDGE THAT THIS SECTION SHALL APPLY TO ALL PLATFORM
        SERVICES, INCLUDING, WITHOUT LIMITATION, PRODUCTS, SERVICES AND CONTENT
        AVAILABLE THROUGH ALL CHANNELS OF THE PLATFORM AND SERVICES AND THAT OF
        ANY AFFILIATES INCLUDING, BUT NOT LIMITED TO, THE INTERNET, WEBSITE,
        TELEPHONE, CATALOG, RADIO, TELEVISION, MOBILE DEVICE, MOBILE APPS,
        SOCIAL MEDIA PLATFORMS AND PARTICIPATING BUSINESSES, RETAIL STORES
        AND/OR ANY OTHER ENTITY. BECAUSE SOME STATES DO NOT ALLOW THE EXCLUSION
        OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN
        SUCH STATES LIABILITY IS LIMITED TO THE FULLEST EXTENT PERMITTED BY LAW.
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>Third Party Service Providers</h2>
      <p>
        Some aspects of the Platform and Service may be dependent upon third
        party service providers, such as a wireless carrier, third party
        application, social media platform or other entity. We do not control
        such third-party providers and are not responsible or liable for their
        acts or omissions. In addition, such third-party providers may have
        their own terms, policies and guidelines. You should become familiar
        with all such terms, policies or guidelines prior to using the Service
        through such third-party service providers.
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>Restrictions on use</h2>
      <p>
        You may not: (a) remove any copyright, trademark or other proprietary
        notices from any portion of the Platform; (b) reproduce, modify, prepare
        derivative works based upon, distribute, license, lease, sell, resell,
        transfer, publicly display, publicly perform, transmit, stream,
        broadcast or otherwise exploit the Platform or Services except as
        expressly permitted by CYW, LLC; (c) decompile, reverse engineer or
        disassemble the Platform except as may be permitted by applicable law;
        (d) link to, mirror or frame any portion of the Platform; (e) cause or
        launch any programs or scripts for the purpose of unduly burdening or
        hindering the operation and/or functionality of any aspect of the
        Platform or Services; or (f) attempt to gain unauthorized access to or
        impair any aspect of the Platform, Services, or their related systems or
        networks.
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>Prices, taxes, and fees</h2>
      <p>
        Third party businesses,retailers and other authorized entities set the
        price of their products as may be displayed on our Platform and Service,
        and those entities may be subject to tax collection requirements that
        require them to impose sales tax on your gift and/or the redemption
        thereof. The amount of the tax depends on things like the products
        ordered, delivery location, and the legal obligations of the third party
        entity and particularly their state of location.
      </p>
      <p>
        Our company does not charge a fee for the purchase and/or retention of a
        gift through our Platform or Service. All fees and revenue earned by our
        Company is earned and collected at the time of redemption of the gift
        and such fee is collected regardless of the nature of the gift. For
        example, our company does not make a distinction between food, beverage,
        merchandise and/or other goods or services. Our company does not make a
        distinction between alcoholic and non-alcoholic beverages in terms of
        whether a fee is charged upon redemption of a gift. No fees and or
        revenue are collected, held, controlled or converted by the Company
        until a gift is redeemed by the recipient and the choice of which type
        of product to redeem is in the sole discretion of the gift recipient. In
        the event that the charge to your payment method may incorrectly differ
        from the total amount gifted to the recipient, including subtotal fees,
        including tax, the excess amount is solely and completely the
        responsibility of the recipient and the Company (we) are not responsible
        for any excess amount incurred by the recipient. All payments will be
        processed by CYW, LLC’s designated payment processor, using the
        preferred payment method designated in your account. If you would like
        to use a different payment method or if there is a change in payment
        method, please update your billing information.
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>Returns, refunds, and exchanges</h2>
      <p>
        The laws in your state determine whether a return, refund or exchange is
        available to you. For example, you may be able to return a tainted or
        damaged product for a refund or credit, or exchange it for something
        else to the third party business. In some states, you can exchange a
        product you bought (or received as a gift) if you want something else.
        Our Company makes no representations concerning your ability to return a
        product to a third party business and any disputes regarding the
        quality, content and/or value of a gift redeemed at a third party
        business is a matter between the third party and the third party
        business. Because our Company’s business is limited to the facilitation
        of a gifting and redemption service, we will not intervene in disputes
        between gift recipients and businesses. If you purchase a gift that is
        not redeemed by the recipient within seven (7) days from the date of
        purchase, the full amount of the gift will be refunded to your account
        in a timely manner. No refunds of gifts that have been redeemed will be
        issued under any circumstances and you agree that the redemption of a
        gift you have sent to a recipient is not subject to refund under any
        circumstances once redeemed.
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>Promotional and referral codes</h2>
      <p>
        Our Company and third party businesses or entities, at their sole
        discretion, may make promotional offers available to you through our
        Platform and Service. You agree that promotional offers: (a) may only be
        used by the intended audience, for the intended purpose, and in a lawful
        manner; (b) may not be duplicated, sold or transferred in any manner, or
        made available to the general public, unless expressly permitted by CYW,
        LLC; (c) are subject to the specific terms that CYW, LLC and third party
        businesses establish for such promotional offer; (d) cannot be redeemed
        for cash or cash equivalent; and (e) are not valid for use after the
        date indicated in the offer or one year.
      </p>
      <p>
        Our Company reserves the right to modify or cancel an offer at any time.
        If you attempt to evade restrictions on offer redemption, we may decline
        to honor your gift.
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>Changes to gifts</h2>
      <p>
        If you wish to change your gift for any reason after it has been placed,
        you may do so at any time prior to redemption or partial redemption by
        the recipient, but we cannot guarantee that your changes will be
        effective depending on the timing of the gift and the redemption.
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>ID verification; other safety checks</h2>
      <p>
        It is the responsibility of the Business, Retailer, Restaurant, Bar,
        Shop and/or any other business entity and any provider that uses our
        Platform and Services to follow the law and exercise professional
        responsibility by using appropriate means to verify identification and
        age, and to determine whether it is otherwise safe and appropriate to
        furnish you and/or the recipient with alcohol. They may require a valid
        form of photo identification with birthdate and a signed acknowledgment
        confirming acceptance at the time redemption or delivery. If they cannot
        verify your age or identity, if you appear intoxicated, or if the
        situation is otherwise unsafe or inappropriate (for example, you are 25
        but appear to be in the company of those underage), they may refuse to
        furnish you with alcohol. By the business representative or employee
        entering the business redemption code and providing the gift, the
        business is accepting complete responsibility. Our company has no
        authority or control third party business, retailer, restaurant, bar
        shop or other business entity decision making.
      </p>
      <p>
        If we believe that your order is fraudulent or unlawful, we may alert
        the Business and suspend your use of the Platform and Service.
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>Retailer right to refuse redemption</h2>
      <ol>
        <li>
          <span style={{fontWeight: "bold", display: "block"}}>
          Retailer right to refuse, cancel and adjust orders</span>
          <span style={{display: "block"}}>
            A Retailer may refuse or cancel your order for any reason. If the
            product you want is not available, the Retailer will notify you and
            offer a reasonable substitute or possibly offer you a credit. If you
            accept the substitution, the redemption of your gift will be
            considered complete. All matters regarding refusals, cancellations
            and substitutions are between the recipient and the third party
            business where the gift is being received. Our Company will not
            intervene and rejects any liability or responsibility for disputes
            between those redeeming gifts and third party retailers/businesses.
          </span>
          <span style={{display: "block"}}>
            A third party business, retailer, shop, restaurant, bar or other
            entity may refuse to redeem your order if they believe that
            furnishing alcohol to you or the person accepting the order on your
            behalf is illegal, unsafe or inappropriate. If a redemption cannot
            be made for this reason, you may be allowed to retain your gift
            until such time as it can be redeemed in a legal and appropriate
            manner, up to 7 days for gifts that have not been partially redeemed
            or 24 hours on remaining gifts that have been partially received.
          </span>
        </li>
      </ol>
      <p>
        You will be required to register an account to use the Platform and
        Service. You must provide accurate, current and complete information
        during the registration process and at all times that you use the
        Platform and Service. You must update your information accurately,
        current and complete and you agree to hold CYW, LLW, our Company, our
        Platform and Service harmless for your failure to take action to keep
        your information accurate, current and complete.
      </p>
      <p>
        You are the sole authorized user of any account you create and
        responsible for all activities that occur under your account and/or
        password. You agree that you will monitor your account to prevent use by
        any person not authorized and that in particular you are the sole party
        responsible for monitoring your account use by anyone under the age of
        21. You may not authorize others to use your account or transfer/assign
        your account to anyone else. Should you suspect that any unauthorized
        activity has occurred on your account, you agree to immediately notify
        our Platform and Service by{" "}
        <a
          style={{textDecoration: "underline"}}
          href="mailto:ContactUs@klink-it.com"
          >CLICKING HERE</a>
        {" "}or sending an email to ContactUs@Klink-iT.com.
      </p>
      <p>
        CYW, LLC, the Company, the Platform and/or the Service will not be
        liable and you may well be liable for any losses, damages, liability,
        fees and expenses arising from someone else using your account. This
        applies regardless of whether and/or if you notify us of any
        unauthorized activity. If you do violate any of these Terms of Service,
        we may terminate your account without notice and take further legal
        action to prevent continued or repeated attempts to violate these Terms
        of Service. If your account is terminated and you somehow fraudulently
        create another account, our Company will file a lawsuit against you and
        seek all damages allowable under both state and federal law as well as
        possibly refer the matter for criminal charges if allowable under law at
        that time.
      </p>
      <p>
        If you wish to deactivate your account at any time, for any reason,
        please sign in to the Klink-iT app, navigate to profile settings and
        select Delete Account or send a request to ContactUs@Klink-iT.com.
      </p>
      <p>
        When you opt-in to any of the CYW, LLC Platform, Services, brands’
        marketing, promotional, and delivery notification text messaging
        services, including SMS and MMS (collectively the "SMS Service"), we
        will send you an SMS message to confirm your signup. Message frequencies
        and short codes may vary. You may cancel the SMS Service at any time.
        Reply "<span style={{fontWeight: "bold"}}>STOP</span>" to cancel. The SMS
        Service is provided by participating third-party mobile phone/wireless
        carriers. Message and data rates may apply. If you have any questions
        regarding your text or data plan, please contact your mobile
        phone/wireless carrier. Carriers are not liable for delayed or
        undelivered messages.
      </p>
      <p>
        Platform providers, such as Apple, Inc. and Google, Inc., that make our
        mobile App or Apps available for download ("Platform Providers") are not
        parties to these Terms of Service. Platform Providers have no obligation
        whatsoever to furnish any maintenance and support services with respect
        to our Platform and/or Service. In the event of any failure of an App to
        conform to the warranties herein, you may notify the applicable Platform
        Provider, and such Platform Provider may refund any purchases made
        through the App (if applicable). To the fullest extent permitted by law,
        Platform Providers will have no other warranty obligation whatsoever
        with respect to an App. You acknowledge that Platform Providers are not
        responsible for addressing any claims relating to an App (including our
        Platform, Service and/or App) or your possession and/or use of an App,
        including, but not limited to: (i) product liability claims; (ii) any
        claim that the App fails to conform to any applicable legal or
        regulatory requirement; and (iii) claims arising under consumer
        protection or similar legislation. The Platform Providers are third
        party beneficiaries of these Terms of Use, and Platform Providers will
        have the right (and will be deemed to have accepted the right) to
        enforce these Terms of Use against you as a third-party beneficiary
        thereof. You represent that you are not located in a country that is
        subject to a U.S. Government embargo, or that has been designated by the
        U.S. Government as a "terrorist supporting" country; and you are not
        listed on any U.S. Government list of prohibited or restricted parties.
      </p>
      <p>
        We may provide links and pointers to sites maintained by others
        ("Third-Party Sites") that are not affiliated with us and may be located
        in different states and that may be subject to different regulatory and
        other legal requirements. These links and pointers are only for your
        convenience and therefore you access them at your own risk.
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>Proprietary Rights</h2>
      <p>
        You acknowledge that the Platform and Service contains information,
        data, software, photographs, graphs, videos, typefaces, graphics, music,
        sounds, and other material (collectively "Content") that is protected by
        copyrights, trademarks, or other proprietary rights, and that these
        rights are valid and protected in all forms, media and technologies
        existing now or hereinafter developed. All Content is copyrighted as a
        collective work under the U.S. copyright laws, and the Company or its
        Affiliates or other third-party licensors may own a copyright in the
        selection, coordination, compilation, arrangement, and/or enhancement of
        such Content. You may not modify, remove, delete, augment, add to,
        publish, transmit, participate in the transfer or sale of, create
        derivative works from, or in any way exploit, for monetary gain or
        otherwise, any of the Content, in whole or in part. If no specific
        restrictions are displayed, you may use the Content only for your
        personal non-commercial use and make copies of select portions of the
        Content, provided that the copies are made only for your personal use
        and that you maintain any notices contained in the Content, such as all
        copyright notices, trademark legends, or other proprietary rights
        notices. Except as provided in the preceding sentence or as permitted by
        the fair use privilege under the U.S. copyright laws (see, e.g., 17
        U.S.C. Section 107), you may not upload, post, reproduce, or distribute
        in any way Content protected by copyright, or other proprietary right,
        without obtaining permission of the owner of the copyright or other
        proprietary right. In addition to the foregoing, use of any software
        Content shall be governed by the software license agreement accompanying
        such software.
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>Third Party Content on the Website</h2>
      <p>
        Our company and the Platform and/or Service is a displayer and Platform
        and not a creator or publisher of the Content supplied by third parties
        on the Platform and/or Service. Any opinions, advice, statements,
        services, offers, or other information that constitutes part of the
        Content expressed or made available by third parties, including
        Providers, Merchants, Sponsors, Licensors, or any user of the Platform
        or Service, are those of the respective authors or distributors and not
        of the Company or its Affiliates or any of its officers, directors,
        employees, or agents. NEITHER THE COMPANY NOR ITS AFFILIATES, NOR ANY OF
        THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, NOR ANY
        THIRD PARTY, INCLUDING ANY ASSOCIATES, OR ANY OTHER USER OF THE PLATFORM
        OR SERVICE, GUARANTEES THE ACCURACY, COMPLETENESS, OR USEFULNESS OF ANY
        CONTENT, NOR ITS MERCHANTABILITY OR FITNESS FOR ANY PARTICULAR PURPOSE.
      </p>
      <p>
        In many instances, the Content available through the Platform or Service
        represents the opinions and judgments of the respective user,
        subscriber, customer,, and is the sole creation of that entity. We
        neither endorse nor are responsible for the accuracy or reliability of
        any opinion, advice, submission, posting, or statement made on the
        Service by anyone other than authorized Company employees. Under no
        circumstances shall the Company, or its Affiliates, or any of their
        respective officers, directors, employees, or agents, be liable for any
        loss or damage caused by your reliance on any Content or other
        information obtained through the Platform or Service. It is your
        responsibility to evaluate the information, opinion, advice, or other
        Content available through the Platform or Service. Make good decisions.
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>Accessibility Statement</h2>
      <p>
        The Company is committed to making our Website's content accessible and
        user friendly to everyone. If you are having difficulty viewing or
        navigating the content on the Website, or notice any content, feature,
        or functionality that you believe is not fully accessible to people with
        disabilities, email our team at ContactUs@Klink-iT.com with "Disabled
        Access" in the subject line and provide a description of the specific
        feature you feel is not fully accessible or a suggestion for
        improvement. We take your feedback seriously and will consider it as we
        evaluate ways to accommodate all of our customers and our overall
        accessibility policies. Additionally, while we do not control third
        parties, we strongly encourage anyone with whom we associate to provide
        content that is accessible and user friendly.
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>Intellectual Property Agent</h2>
      <p>
        We respect the rights of all intellectual property holders and have
        adopted a policy that provides for the termination of user privileges
        and membership in appropriate circumstances of users who infringe the
        rights of property holders. While we reserve the right to terminate the
        access of users who infringe or otherwise violate the rights of others
        in our sole discretion, if you are a repeat offender, your access will
        be suspended or terminated. If you believe that your work has been
        copied in a way that constitutes copyright or trademark infringement,
        please provide the Company’s Copyright Agent (identified below) with the
        following information required by the Digital Millennium Copyright Act,
        17 U.S.C. Section 512 and/or any other information you contend relevant
        to your claim as violation of your and/or your agent/client(s)
        intellectual property rights:
      </p>
      <p>
        a. A physical or electronic signature of a person authorized to act on
        behalf of the owner of an exclusive right that is allegedly infringed;
        b. Identification of the copyrighted work claimed to have been
        infringed, or, if multiple copyrighted works at a single online site are
        covered by a single notification, a representative list of such works at
        that site; c. Identification of the material that is claimed to be
        infringing or to be the subject of infringing activity and that is to be
        removed or access to which is to be disabled, and information reasonably
        sufficient to permit us to locate the material; d. Information
        reasonably sufficient to permit us to contact the complaining party; e.
        A statement that the complaining party has a good faith belief that use
        of the material in the manner complained of is not authorized by the
        copyright owner, its agent, or the law; and f. A statement that the
        information in the notification is accurate, and under penalty of
        perjury, that the complaining party is authorized to act on behalf of
        the owner of an exclusive right that is allegedly infringed.
      </p>
      <p style={{marginBottom: "1rem"}}>
        The Company's Copyright Agent for notice of claims of copyright
        infringement on or regarding this Website can be reached as follows:
      </p>
      <p>
        <span style={{fontWeight: "bold"}}> Attn: </span>
        COPYRIGHT AGENT CYW, LLC
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>User Submissions</h2>
      <p>
        The Company does not claim ownership of any content you submit or make
        available for inclusion on the Platform or Service. However, with
        respect to all such Content, including, without limitation, creative
        ideas, suggestions, product and service reviews, content, postings,
        artwork, material or other submissions that you send, upload, transmit,
        post, submit or otherwise make available to us, whether via the Service,
        email, feedback, a public forum, social media, or otherwise
        (collectively, "User Submissions"), you hereby grant the Company and its
        Affiliates the worldwide, perpetual, royalty-free, irrevocable,
        transferable, non-exclusive right to use, communicate, reproduce,
        publish, display, perform, modify, alter, adapt, translate, sublicense,
        distribute, create derivative works from, commercialize, and exploit
        such User Submissions in any manner, including on the Service or any
        other web sites, in television programs, on radio, in books, magazines,
        articles, commentaries, and in any other medium now known or later
        developed without your consent. You also warrant that you own or
        otherwise control all of the rights to any User Submissions you submit
        or post on or to the Service or otherwise transmit to the Company and
        that our public posting and other public or private use of such User
        Submissions will not infringe the rights of any third party. You
        acknowledge that you are not entitled now, or in the future, to any
        compensation for any User Submissions you may submit or post.
      </p>
      <p>
        No User Submissions, regardless of how they may be marked, will be
        received by us in confidence, nor shall they be subject to any express
        or implied obligation of confidentiality. Neither the Company, nor its
        Affiliates, nor their respective officers, directors, agents or
        employees shall be liable for any use or disclosure of any User
        Submissions.
      </p>
      <p>
        Except as otherwise specifically set forth to the contrary herein, and
        to the fullest extent permitted by law, you and your successors and
        assigns hereby waive any and all rights and remedies you may have
        against the Company, or its Affiliates, or any of their respective
        officers, directors, employees, or agents now or in the future, and
        hereby release the Company, its Affiliates, and any of their respective
        officers, directors, agents and employees from any and all claims,
        demands, actions, causes of action, damages, obligations, losses and
        expenses of whatever kind, in each case relating to your providing,
        posting, transmitting or making available through the Service any User
        Submissions to the Company, and the Company’s receiving, evaluating, and
        utilizing the User Submissions.
      </p>
      <p>
        In addition, the Company may, in our sole discretion, at any time and
        without prior notice to you, suspend or terminate your participation in
        any public forum or any other portion of the Platform or Service, or the
        subscription or registration of any user who violates any of these Terms
        of Service, any of the rules, regulations or guidelines, or for any
        other behavior that we in our sole discretion believe is in breach of
        these Terms of Service, is in violation of any law, regulation and/or
        statute.
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>Use of Public Forums</h2>
      <p>
        A "public forum" means any publicly accessible message board, chat room,
        discussion group, folder, survey, contest, sweepstakes, user review and
        rate forum, live event, or other interactive service or promotion on or
        accessible via the Service, and includes both public boards and folders.
        You must use, subscribe, or register in accordance with instructions
        that you will find on the Service in order to participate or contribute
        to any public forum. You may not submit or post on any public forum, or
        send to any other public forum user or our employees, any material that
        is unlawful, harmful, threatening, abusive, harassing, defamatory,
        invades a person's privacy, violates any intellectual or other property
        rights, or is vulgar, obscene, sexually explicit, profane, hateful,
        racially, ethnically, or otherwise objectionable, including but not
        limited to any material that encourages conduct that would constitute a
        criminal offense, give rise to civil liability, or otherwise violate any
        applicable local, state, national, or international law. You agree not
        to use any false e-mail address, impersonate any person or entity, or
        otherwise mislead as to the origin of a communication or other Content,
        or attempt to do any such acts. You may not use any public forum in a
        commercial manner. You may not submit or post material that solicits
        funds, or that advertises or solicits goods or services. You may not
        submit or post any User Submissions or material that you know, or should
        have known, to be false. You may not submit or post messages regarding
        stocks or other securities. You may not submit, post, or transmit any
        information, software or other material that contains a virus or other
        harmful component.
      </p>
      <p>
        The Company is not responsible for any User Submissions or material
        appearing in any public forum on the Service, except for Content created
        by one of our identified authorized representatives. We do not screen
        User Submissions for libel, obscenity, invasion of privacy, copyright or
        trademark infringement, accuracy, or for any other reason. We retain,
        however, the rights set forth below in The Company's Rights section
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>
        Distribution/Uploading by Users of Third Party Content
      </h2>
      <p>
        Except as otherwise set forth in these Terms of Service, you agree not
        to upload to or otherwise distribute on the Platform or Service any
        Content created or owned by others which is subject to any copyright or
        other proprietary rights of any third party. The unauthorized submission
        or distribution of copyrighted or other proprietary third-party Content
        is illegal and could subject you to personal liability for damages. You,
        not the Company or any Associate, will be liable for any damages
        resulting from any infringement of copyrights or proprietary rights, or
        from any other harm arising from such submission by you.
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>The Company's Rights</h2>
      <p>
        The Company is not responsible for screening, policing, editing, or
        monitoring Content (including User Submissions). The Company (or its
        Affiliates) may elect, but is not obligated, to monitor, electronically
        or otherwise, areas of the Service and may disclose any Content
        (including User Submissions), records, or electronic communication of
        any kind and information you provide to the Company or its Affiliates,
        through the Service or otherwise, including all merchandising channels,
        (i) when we believe disclosure to be appropriate to comply with any law,
        regulation, or government or law enforcement request or to comply with
        judicial process; or (ii) if such disclosure is necessary or appropriate
        to operate the Service and/or the overall business of the Company and
        its Affiliates; or (iii) to protect the rights or property of the
        Company, users of the Service, Affiliates, subscribers, customers,
        recipients, Sponsors, Providers, Licensors, or Merchants. Subject to the
        "Copyright Agent" provisions above, if notified of allegedly infringing,
        defamatory, damaging, illegal, or offensive Content, the Company may
        investigate the allegation and determine in its sole discretion whether
        to remove or request the removal of such Content from the Service.
      </p>
      <p>
        The Company reserves the right to prohibit or remove conduct,
        communication, or Content (including User Submissions) from the Service,
        including any part thereof, for any or no reason, including, without
        limitation, Content that it deems in its sole discretion to be harmful
        to users of the Service, subscribers, customers, recipients, Associates,
        content or service providers, the Company or its Affiliates, or any
        rights of the Company or any third party, or to violate any applicable
        law. Notwithstanding the foregoing, neither the Company, nor its
        Affiliates, or Associates, can ensure prompt editing or removal of
        questionable Content after online posting. Accordingly, neither the
        Company, nor its Affiliates, nor any of their respective officers,
        directors, employees, agents or employees, nor any Provider, Merchant,
        Sponsor, or Licensor shall assume liability for any action or inaction
        with respect to conduct, communication, or Content (including User
        Submissions) on the Service.
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>Purchases and Transactions</h2>
      <p>
        The payment terms displayed on our Platform and Service (which may
        differ significantly from time to time and from business to business)
        are a part of these Terms of Service and govern all services and
        products offered through any Service. There are two types of
        transactions that CYW, LLC allows and facilitates: gifts and
        redemptions. A gift is a financial transaction in which an authorized
        user pays for and gives an item(s) to another authorized user without
        the expectation of return payment or anything else in return. Redemption
        or the redeeming of a gift is the receipt of the gift by another
        authorized user and the actual use and enjoyment of the gift. Gifts and
        Redemption in the context of our Platform and Service are confined to
        gifting and redeeming items through our Platform and Service and with
        authorized businesses and/or authorized organizations through our
        Platform and Service. Gifts are any fungible product to include
        beverages, food, giftcards, tickets and/or any other tangible product.
        All gifts must be legal to both buy, sell and possess in the location in
        which the gift is redeemed or it is in violation of these Terms of
        Service and is void.
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>Privacy Notice</h2>
      <p>
        We recognize and respect the importance of maintaining the privacy of
        our users, customers and subscribers and have established a privacy
        notice as a result. In our  Privacy Notice, which constitutes part of
        these Terms of Service, we describe why we gather information from
        users, customers, and subscribers, what information we collect, how we
        collect it, what we use the information for and how you can instruct us
        if you prefer to limit the use of information about you.
      </p>
      <p>
        Using your personal data in a fair and transparent way is important to
        Our Company. When you use our desktop or mobile websites and
        applications (the "Platform"), we collect information about you,
        including information that can be used to identify you ("Personal
        Information"). Additionally, we may obtain Personal Information about
        you from third party business entities and/or other subsidiaries and
        other third parties as described in this Privacy Notice. We collect this
        data to allow you to make authorized transactions, give gifts, redeem
        gifts and access other functionality on the Platform (the "Services"),
        as well as for the other purposes described in this Privacy Notice.
      </p>
      <p>
        This Privacy Notice is intended to help you understand: (A) What
        Personal Information we collect, how we collect it, how we use it, and
        how we share it, (B) The choices you have related to your Personal
        Information, and (C) How we protect your Personal Information.
      </p>
      <p>
        Please take a moment to review the terms of this Privacy Notice, which
        is incorporated into the Terms of Service governing your use of the
        Platform and Services,
        <span style={{fontWeight: "bold"}}
          >By accessing the Platform or using the Services, you agree that we
          may collect, process, and share your information (including Personal
          Information) consistent with this Privacy Notice and to the Terms of
          Service.
        </span>
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>1. Types of Information We Collect</h2>
      <p style={{fontWeight: "bold", marginBottom: "1rem"}}>
        The following information is collected by or on behalf of CYW, LLC, our
        Company, Platform and/or Service:
      </p>
      <ol style={{fontWeight: "bold", paddingLeft: "4.5rem"}}>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >User Account Information
          </span>
          When you register for the Platform or use our Services, we ask that
          you provide information that can be used to personally identify you.
          This includes details such as your full name, email address, phone
          number, date of birth, delivery address, and billing address.
        </li>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >User Credentials
            </span>
          When you register for the Platform, you will be asked to provide
          credentials to allow you to access your account. These credentials may
          include a username, password, security questions, and responses to
          security questions.
        </li>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >User Identifiers
          </span>
          We assign you one or more unique numbers or other identifiers that we
          use to identify you in our internal systems and recognize you over
          time and across different devices, websites, and online services.
          These identifiers may be used in conjunction with cookies and other
          Technical Information.
        </li>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >Payment Card Information
          </span>
          We collect and store the last four digits and expiration date of any
          payment card that you use to make a purchase on the Platform. However,
          we do not collect or store full payment card numbers on our systems.
          All payments are processed using a secure third party.
        </li>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >Precise Location Data
          </span>
          In some circumstances, we collect precise geolocation data
          (geolocation data sufficient to identify a street name and name of a
          city or town) from your device when you access the Platform.
        </li>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >User Content
          </span>
          We collect the data you submit when you contact customer support;
          provide ratings or feedback for products or retailers; or otherwise
          engage directly with our personnel. User content may include
          photographs, or audio or video recordings submitted by you in
          connection with a customer support request. This also includes
          metadata relating to the method you use to communicate with us.
        </li>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >Usage and Device Data
          </span>
          We collect certain information automatically when you access the
          Platform or by using cookies, as described below and in our Cookie
          Notice. This Technical Information may include:
          <ul style={{paddingLeft: "4.5rem", margin: 0, listStyleType: "disc"}}>
            <li>Your browser type and operating system</li>
            <li>
              Your Internet Protocol (IP) address, which is the number
              automatically assigned to your computer whenever you access the
              Internet and that can sometimes be used to derive your general
              geographic area
            </li>
            <li>Websites you visited before and after visiting the Platform</li>
            <li>Pages you view and links you click on within the Platform</li>
            <li>
              Information collected through cookies, web beacons, and other
              technologies
            </li>
            <li>
              Information about your interactions with e-mail messages, such as
              the links clicked on and whether the messages were opened or
              forwarded
            </li>
            <li>Standard server log information</li>
          </ul>
        </li>
        <li>
          We may combine Technical Information that does not identify you with
          Personal Information. If we do so, we will treat the combined
          information as Personal Information under this Privacy Notice.
        </li>
      </ol>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>2. How We Collect Information</h2>
      <p style={{fontWeight: "bold", marginBottom: "1rem"}}>
        We may collect information from you in the following ways:
      </p>
      <ol style={{paddingLeft: "4.5rem", fontWeight: "bold"}}>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >From You Directly
          </span>
          We receive Personal Information directly from you when you provide it
          to us, such as when you register for the Platform or place an order.
          Additionally, you may be required to provide Personal Information when
          you contact us for support or with questions so that we can respond
          appropriately.
        </li>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >Using Tracking Technologies
          </span>
          We (or our vendors and partners) may use automated means to collect
          information about you and the device that you use to access the
          Platform. These automated means include technologies such as cookies,
          web beacons, and similar technologies ("Tracking Technologies"). These
          technologies help us analyze trends, administer the Services, track
          your interaction with the Platform, understand your preferences and
          habits. Tracking technologies may collect both Personal Information
          and Technical Information. They also allow us to provide you with
          recommendations and content that are tailored to you. For more details
          about the Tracking Technologies that we use and your choices regarding
          this tracking, please see our Cookie Notice.
        </li>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >From Third Parties
          </span>
          We receive Personal Information from third parties that have legally
          obtained it, either directly from you or from other third
          parties.Third parties include Uber.
        </li>
      </ol>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>3. How We Use Your Information</h2>
      <p style={{fontWeight: "bold", marginBottom: "1rem"}}>
        We may use information that we collect from you for the following
        purposes, or as otherwise identified at the time of collection:
      </p>
      <ol style={{fontWeight: "bold", paddingLeft: "4.5rem"}}>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >Providing Services
          </span>
          We may use Personal Information to make the Platform and Services
          available to you. For example, we may use your Personal Information
          to:
          <ul style={{paddingLeft: "4.5rem", margin: 0, listStyleType: "disc"}}>
            <li>Create and update your account</li>
            <li>Facilitate your orders with Retailers</li>
            <li>Facilitate the fulfillment and delivery of your orders</li>
            <li>Facilitate your payment to Retailers</li>
            <li>Administer and manage your account</li>
            <li>Authenticate your identity</li>
            <li>Protect your account</li>
            <li>Provide you with updates about your orders and account</li>
            <li>
              Administer any contest or promotions, including winner
              notification and prize delivery
            </li>
          </ul>
        </li>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >Communicating with You
          </span>
          We may use your Personal Information to communicate with you. For
          example, we may send you text messages or other notifications (such as
          "push notifications" through your mobile device) about the status of
          your orders and deliveries. You may control how we communicate with
          you by adjusting your Notifications preferences in your Account
          Settings.
          <span style={{display: "block", margin: 0}}>
            CYW, LLC also uses the information we collect (which may include
            call recordings) to provide customer support, including to
            investigate and address user concerns and to monitor and improve our
            customer support responses and processes.
          </span>
        </li>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >Data Aggregation
          </span>
          We may use your Personal Information to create aggregated data records
          (collectively "Aggregate Data"). Some of this Aggregate Data is
          de-identified. We de-identify data by removing information (including
          any contact information) that would allow the remaining data to be
          linked back to you. We may use Aggregate Data for internal purposes,
          such as analyzing patterns, program usage, demographic trends, and
          user behavior patterns and preferences to improve our Services and for
          other lawful purposes.
        </li>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >Compliance, Protection, and Safety</span>
          We may use your Personal Information as we believe necessary to comply
          with any applicable laws, court orders, subpoenas; to respond to
          lawful requests by law enforcement, regulators, or government
          entities; to detect, prevent, investigate or combat possible crime,
          such as identity theft, or other fraudulent activity; to protect the
          security of the Platform and Services; to enforce or apply our online
          Terms of Service or other agreements; to protect our own rights or
          property or the rights, property, or safety of our users or others;
          and to investigate or address claims or disputes relating to the
          Platform and/or Services.
        </li>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >Research and Development
          </span>
          We use data for testing, research, analysis, product development and
          machine learning to improve the user experience. This helps us make
          our Services more convenient and easier to use, enhance the safety and
          security of the Services and develop new services and features.
          <span style={{display: "block", margin: 0}}
            >CYW, LLC and/or our Company performs the above activities on the
            grounds that they are necessary for purposes of our Company’s
            legitimate interests in improving and developing new services and
            features.
          </span>
        </li>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >Marketing and Advertising
          </span>
          CYW, LLC uses Personal Information to market its services, the
          services of Uber Parties, and other third-party partners. This may
          include:
          <ul style={{paddingLeft: "4.5rem", margin: 0, listStyleType: "disc"}}>
            <li>
              Sending emails, text messages, push notifications, and in-app
              messages or other communications marketing or advertising
              products, services, features, offers, promotions, sweepstakes,
              news and events.
              <span style={{marginTop: "1rem"}}
                >This may include using your location, order history, and usage
                data to send marketing communications that are personalized
                based on your observed or inferred interests and
                characteristics. For example, we may send in-app messages
                offering discounts or promos for products similar to those that
                you have previously ordered.
              </span>
            </li>
            <li>
              Displaying our Company advertising on third-party apps or
              websites. This includes using your location, order history, and
              usage data, and sharing Personal Information with advertising
              partners, to personalize such ads to your interests.
            </li>
            <li>
              Displaying third-party advertising on the Platform or in
              connection with the Services
              <span style={{marginTop: "1rem"}}
                >This includes ads for specific products. These ads (which are
                identified as "Sponsored" or "Ads"on the Platform) may include
                recommendations that are personalized based on your location and
                order history.
              </span>
              <span style={{marginTop: "1rem"}}
                >This also includes ads for other third-party products or
                services that are personalized based on your observed or
                inferred interests and characteristics, which we determine using
                data such as order history, usage data, demographic data, and
                device or user identifiers.
              </span>
            </li>
            <li>
              Measuring the effectiveness of our ads, and of third-party ads
              displayed on the Platform or in connection with the Services.
            </li>
          </ul>
        </li>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >Analytics
          </span>
          We may use analytics, machine learning, and data models ("Analytics")
          to support our data processing activities. Some of our Analytics rely
          upon Personal Information that we collect from you, your interactions
          and use of the Platform, and from third parties. Using this Personal
          Information, our Analytics allow us to tailor our Services to your
          specific needs and preferences, provide certain services to our
          partners, and develop new features.
        </li>
      </ol>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>
        4. How We Share and Disclose Your Information
      </h2>
      <p style={{fontWeight: "bold", marginBottom: "1rem"}}>
        We disclose Personal Information to the following entities, or as
        otherwise identified at the time of collection:
      </p>
      <ol style={{fontWeight: "bold", paddingLeft: "4.5rem"}}>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >Retailers and Drivers
          </span>
          If you use the Platform and/or Service, we share details about your
          identity with the retailers and third party entities that will be
          completing your transaction and/or fulfilling your order. These
          parties use your information to complete the transaction, the giving
          of a gift and the redemption of a gift.
        </li>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >Delivery Services
          </span>
          In some cases, retailers and/or third party entities may utilize third
          party services (such as Uber Eats) to complete your gift, transaction,
          redemption and/or to deliver your order. We may share your Personal
          Information with these third parties as part of the Services
        </li>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >Other Users of the Platform
          </span>
          If you place a gift order, the individual receiving the gift may be
          notified that it was sent on your behalf. Of course, if you give a
          gift, the third party business entity and the payment processing agent
          will be notified.
          <span style={{display: "block"}}
            >If you write a review on the Platform, the name you provide at the
            time you post the review will be visible to all other Platform
            users.
          </span>
        </li>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >Co-Branded Offerings</span>
          At certain times, we may provide you with access to deals, promotions,
          or opportunities ("Promotions") in association with our third party
          entities that are not part of CYW, LLC or our Company. Such Promotions
          will identify the partner and indicate if they have a Privacy Notice
          that applies to their collection and use of your information. If you
          register for their products and/or services, communicate with these
          partners, or download their content or applications as part of a
          Promotion, you may be providing your information to both our Company
          and the third party. If this is the case, we will provide disclosures
          as part of the Promotion. We are not responsible for our partner’s
          data practices and you should carefully review each partner’s Privacy
          Notices before participating in a Promotion that they are sponsoring.
        </li>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >Affiliates and Business Transfers</span>
          We may share your Personal Information with our authorized businesses
          that provide redemption of gifts, our subsidiaries, and affiliates for
          use consistent with this Privacy Notice.
          <span style={{display: "block"}}
            >Additionally, we may share your Personal Information in connection
            with, or during negotiations of, a transaction that alters the
            structure of our business, such as a reorganization, merger, sale,
            joint venture, assignment, transfer, change of control, or other
            disposition of all or a portion of our business, assets or stock,
            including a bankruptcy or similar proceeding.
          </span>
        </li>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >Service Providers
          </span>
          We provide data, including Personal Information, to vendors,
          consultants, research firms, and other service providers. These
          include:
          <ul style={{margin: 0, paddingLeft: "4.5rem", listStyleType: "disc"}}>
            <li>Payment processors and facilitators</li>
            <li>Background check and identity verification providers</li>
            <li>Cloud storage providers</li>
            <li>Data analytics providers</li>
            <li>
              Research partners, including those performing surveys or research
              projects in partnership with with our Company or on behalf of our
              Company
            </li>
            <li>
              Vendors that assist us to enhance the safety and security of its
              Platform, Service and/or apps
            </li>
            <li>
              Consultants, lawyers, accountants, and other professional service
              providers
            </li>
            <li>Insurance and financing partners</li>
          </ul>
        </li>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >Corporate Clients
          </span>
          If anyone gifts you anything on our Platform and/or Service, we may
          share your Personal Information with that person so it can verify that
          you received your gift.
        </li>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >Advertising Companies, Social Media Sites, and Brand Partners
          </span>
          We may provide your Personal Information to advertising companies
          (including marketing firms, audience analytics firms, advertising
          platforms, and consultants), social media platforms, and brands that
          we partner with so that they can better understand your purchasing
          habits and preferences. In some circumstances, we may also allow these
          companies to collect Personal Information directly from your browser
          or device through Tracking Technologies when you interact with the
          Platform. These companies may use the information we provide to
          determine how to direct advertisements and what individual or
          demographics to target, both on the Platform and elsewhere. They may
          also use this information to create or improve profiles about you that
          may be utilized by third party advertisers.
        </li>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >Compliance with Laws and Protection of Rights
          </span>
          We may disclose your Personal Information as we believe necessary to
          comply with any applicable laws, court orders, subpoenas; to respond
          to lawful requests by law enforcement, regulators, or government
          entities; to detect, prevent, investigate or combat possible crime,
          such as identity theft, or other fraudulent activity; to protect the
          security of the Platform and Services; to enforce or apply our online
          Terms of Service or other agreements; to protect our own rights or
          property or the rights, property, or safety of our users or others;
          and to investigate or address claims or disputes relating to the
          Services.
        </li>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >With Consent
          </span>
          We may share your data other than as described in this notice if we
          notify you and obtain your consent.
        </li>
      </ol>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>5. Data Retention and Deletion</h2>
      <p style={{fontWeight: "bold"}}>
        Our Company retains user data for as long as necessary for the purposes
        described above. This generally means that we retain your Personal
        Information for the life of your account, and delete it 60 days after
        you request account deletion or your account is deemed inactive. You may
        request deletion of your Personal Information at any time as described
        in more detail in the Choice and Transparency section of this Privacy
        Notice. Please note that we may retain your Personal Information after a
        deletion request due to legal or regulatory requirements.
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>6. Data Protection</h2>
      <p style={{fontWeight: "bold"}}>
        We maintain a number of administrative, physical, and technological
        measures to protect the confidentiality, privacy, and security of your
        Personal Information. These controls vary based on the nature of the
        information and industry standards. Unfortunately, no website, server or
        database is completely secure or "hacker proof." We therefore cannot
        guarantee that Personal Information you provide will not be disclosed,
        misused, or lost by accident or by the unauthorized acts of others.
      </p>
      <p style={{fontWeight: "bold"}}>
        Safeguarding your Personal Information is also your responsibility. We
        encourage you to change your passwords often, use a combination of
        letters and numbers, and make sure you are using a secure device to
        access the Platform. If you have reason to believe that the security of
        your account might have been compromised, or if you suspect someone else
        is using your account, please let us know immediately by sending an
        email to <span style={{color: "red"}}>ContactUs@Klink-iT.com</span>
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>7. Choice and Transparency</h2>
      <p style={{fontWeight: "bold", marginBottom: "1rem"}}>
        You may exercise control over your Personal Information in the following
        ways:
      </p>
      <ol style={{paddingLeft: "4.5rem", fontWeight: "bold"}}>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >Device Permissions
          </span>
          Most mobile device platforms (iOS, Android, etc.) have defined certain
          types of device data that apps cannot access without the device
          owner’s permission, and these platforms have different methods for how
          that permission can be obtained. Please check the available settings
          on your device or check with your provider.
        </li>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >Platform Inbox, Push Notification, and SMS Messaging
          </span>
          When you first register to use our Platform, you may authorize us to
          communicate with you through the Platform inbox, push notifications,
          and SMS messages. You can adjust these settings at any time in our App
          by clicking the "Account" tab and then clicking the "Notifications"
          link.
        </li>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}>
            Email communications
            </span>
          We include an "unsubscribe" link in each electronic newsletter or
          promotional email we send you. If you no longer wish to receive such
          communications from us, please click that link and follow any
          additional instructions.
        </li>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >Tracking Technologies
          </span>
          Most browsers let you remove or reject cookies. To do this, follow the
          instructions in your browser settings.If you do not accept cookies,
          you may experience some inconvenience in your use of the Platform. For
          example, we may not be able to recognize your computer or mobile
          device and you may need to log in every time you visit the Platform.
        </li>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >Updating and Correcting Your Information
          </span>
          If you are a registered Platform user, you have the ability to update
          or correct your Personal Information by accessing your profile page
          once you are logged in. If you are unable to make the necessary
          updates or corrections there, please request changes using our Data
          Rights Request Form.
        </li>
        <li>
          <span style={{textDecoration: "underline", display: "block"}}
            >Deleting your Information
          </span>
          You may request that we delete your Personal Information from our
          systems by emailing us at ContactUs@Klink-iT.com.
        </li>
      </ol>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>
        8. Notice to Users in States with Privacy Laws
      </h2>
      <p style={{fontWeight: "bold", marginBottom: "1rem"}}>
        If you are a resident of a state that has enacted specific laws that
        govern how companies can collect and process your Personal Information,
        please see our California Privacy Policy, which provides additional
        information, disclosures, and describes your rights under applicable
        laws. Our California Privacy Policy describes several rights available
        to you, including:
      </p>
      <ul style={{fontWeight: "bold", paddingLeft: "4.5rem", listStyleType: "disc"}}>
        <li>The right to access your Personal Information</li>
        <li>
          The right to obtain your Personal Information in a portable format
        </li>
        <li>
          The right to have your Personal Information deleted from our databases
        </li>
        <li>
          The right to "opt-out" from having your Personal Information "sold" or
          "shared", as those terms are defined under California Law.
        </li>
        <li>
          The right to "opt out" of personalized advertising on the Platform
        </li>
      </ul>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>
        9. Notice to Users Outside the United States and Canada
      </h2>
      <p style={{fontWeight: "bold"}}>
        Our Company is based in the United States and only offers the Services
        in the United States and Canada. Regardless of where you access the
        Platform or Services, the information collected as part of that use will
        be transferred to and maintained on servers located in the United
        States. Any Personal Information that we obtain about you will be stored
        in the United States where data protection laws may not be as protective
        as those applicable where you live. By using the Platform or Services,
        you consent to this collection, transfer, storage, and processing of
        information to and in the United States.
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>
        10. Personal Information About Underage Users and Children
      </h2>
      <p style={{fontWeight: "bold"}}>
        You must be at least sixteen (16) years of age to access the Platform
        and Services. We do not knowingly request or collect Personal
        Information from any person under the Minimum Age. If we suspect a user
        is below the Minimum Age, we will close their account and take steps to
        delete their information.
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>11. Contact US</h2>
      <p style={{fontWeight: "bold"}}>
        If you have any questions about this Privacy Notice or our privacy
        practices, or would like to contact our Privacy Officer, please send an
        email to ContactUs@Klink-iT.com.
        <span style={{display: "block", marginTop: "1rem"}}> CYW, LLC </span>
        <span style={{display: "block"}}> Attn: Privacy Officer </span>
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>12. Updates</h2>
      <p style={{fontWeight: "bold"}}>
        We may occasionally update this Privacy Notice. If we make material
        changes, we will notify users by updating the "last updated" date at the
        top of this Privacy Notice and sending notice through email or the
        Platform. Your continued use of the Platform or Services after we have
        posted the revised Privacy Notice constitutes your agreement to be bound
        by the revised Privacy Notice to the extent permitted by law.
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>Indemnity</h2>
      <p>
        Except as otherwise specifically set forth to the contrary herein, and
        to the fullest extent permitted by law, you agree to indemnify, defend
        and hold harmless the Company, its Affiliates and any of their
        respective officers, directors, employees or agents, from and against
        any and all liability, claims, costs and expenses (including, without
        limitation, reasonable legal fees and expenses), brought by any third
        party alleging, arising, related or resulting from (i) your use (or use
        by any third party using your account) of the Platform and/or Service,
        including in violation or breach of the Terms of Use, (ii) your User
        Submissions, (iii) your violation of any rights of a third party, or
        (iv) your violation of any applicable law, rule or regulation. This
        indemnification obligation will continue after you stop using the
        Service. We reserve the right, at our own expense, to assume the
        exclusive defense and control of any claim or matter otherwise subject
        to indemnification by you at your expense, and you shall not in any
        event settle or otherwise dispose of any claim or matter without our
        prior written consent.
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>
        Termination of Usage; Updates to Service
      </h2>
      <p>
        The Company may terminate your access, or suspend your access to all or
        part of the Service, without notice, for any reason or no reason,
        including conduct that the Company, in its sole discretion, believes is
        a violation or breach of these Terms of Service, is in violation of any
        applicable law or is harmful to the interests of another user, customer,
        recipient, subscriber, a third-party Associate, content or service
        provider, the Company or its Affiliates.
      </p>
      <p>
        We will not be liable if, for any reason, all or part of the Platform or
        Service is ever unavailable. We reserve the right at any time and from
        time to time to modify or discontinue, temporarily or permanently, the
        Platform or Service (or any part thereof), with or without notice. We
        undertake no obligation to update, amend, or clarify information on the
        Platform or Service, except as required by law. Please remember when
        reviewing information on the Platform or Service that such information
        may not represent the complete information available on a subject. In
        addition, subsequent events or changes in circumstances may cause
        existing information on the Platform or Service to become inaccurate or
        incomplete.
      </p>
      <p>
        On occasion, information on the Platform or Service may contain errors.
        We reserve the right to, at any time without prior notice, correct any
        errors, inaccuracies, or omissions, and to change or update information
        (including after you have submitted your order).
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>Dispute resolution</h2>
      <p style={{fontWeight: "bold", marginBottom: "1rem"}}>
        PLEASE READ THIS "DISPUTE RESOLUTION" SECTION CAREFULLY, AS IT MAY
        SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE OR
        PARTICIPATE IN A LAWSUIT FILED IN COURT.
      </p>
      <ol style={{paddingLeft: "4.5rem"}}>
        <li style={{fontWeight: "bold"}}>
          <span style={{display: "block", textDecoration: "underline"}}
            >Informal dispute resolution procedure.
          </span>
          <span style={{fontWeight: 500}}>
            A dispute might arise between you and CYW, LLC, our Company,
            Platform and/or Service. If that happens, our Company is committed
            to working with you to reach a reasonable resolution. For any issue
            or dispute that arises between you and our Company, both parties
            acknowledge and agree that they will first make a good faith effort
            to resolve it informally before initiating any formal dispute
            resolution proceeding in arbitration or otherwise. This includes
            first sending a written description of the dispute to the other
            party. For any dispute you initiate, you agree to send the written
            description of the dispute along with the email address associated
            with your account to the following email address:
            ContactUs@Klink-iT.com. For any dispute that our Company initiates,
            we will send our written description of the dispute to the email
            address associated with your account. The written description must
            be on an individual basis and provide at least the following
            information: your name; a description of the nature or basis of the
            claim or dispute; and the specific relief sought.
            <span style={{display: "block"}}
              >You and our Company then agree to negotiate in good faith about
              the dispute through an informal telephonic dispute resolution
              conference. The informal telephonic dispute resolution conferences
              shall be individualized such that a separate conference must be
              held each time either party intends to commence individual
              arbitration; multiple individuals initiating claims cannot
              participate in the same informal telephonic dispute resolution
              conference. If either party is represented by counsel, that
              party's counsel may participate in the informal telephonic dispute
              resolution conference, but the party also must appear at and
              participate in the conference. If the dispute is not resolved
              satisfactorily through this informal process within sixty (60)
              days after receipt of the written description of the dispute, you
              and Our Company agree to the further dispute resolution provisions
              below.
            </span>
            <span style={{display: "block"}}
              >The aforementioned informal dispute resolution process is a
              prerequisite and condition precedent to commencing any formal
              dispute resolution proceeding. The parties agree that any relevant
              limitations period and filing fee or other deadlines will be
              tolled while the parties engage in this informal dispute
              resolution process. Failure to engage in this process could result
              in the award of fees against you in arbitration.
            </span>
          </span>
        </li>
        <li style={{fontWeight: "bold"}}>
          <span style={{display: "block", textDecoration: "underline"}}
            >Mutual arbitration agreement.
          </span>
          <span style={{fontWeight: 500}}>
            You agree that all claims, disputes, or disagreements that may arise
            out of the interpretation or performance of these Terms (including
            their formation, performance, and breach) or payments by or to CYW,
            LLC, our Company, Platform and/or Service, or that in any way relate
            to the provision or use of the Platform or Services, your
            relationship with our Company or any other dispute with our Company,
            shall be resolved exclusively through binding arbitration in
            accordance with these terms of service (the "<span
              style={{fontWeight: "bold"}}
              >Agreement
            </span>"). This includes claims that arose, were asserted, or involve
            facts occurring before the existence of this or any prior Agreement
            as well as claims that may arise after the termination of this
            Agreement. This Agreement is governed by the Federal Arbitration Act
            ("FAA") in all respects, and is evidence of a transaction involving
            interstate commerce. You and our Company expressly agree that the
            FAA shall exclusively govern the interpretation and enforcement of
            this Agreement. If for whatever reason the rules and procedures of
            the FAA cannot apply, the state law governing arbitration agreements
            in the state in which you reside shall apply.
            <span style={{display: "block"}}
              >Except as set forth in these Terms of Service, the arbitrator,
              and not any federal, state or local court or agency, shall have
              exclusive authority to resolve all disputes arising out of or
              relating to the interpretation, applicability, enforceability or
              formation of these Terms and this Agreement, including, but not
              limited to any claim that all or any part thereof are void or
              voidable, whether a claim is subject to arbitration, and any
              dispute regarding the payment of JAMS administrative or arbitrator
              fees (including the timing of such payments and remedies for
              nonpayment). The arbitrator shall be empowered to grant whatever
              relief would be available in a court under law or in equity.
            </span>
            <span style={{display: "block"}}
              >Notwithstanding the parties' decision to resolve all disputes
              through arbitration, each party retains the right to (i) elect to
              have any claims resolved in small claims court on an individual
              basis for disputes and actions within the scope of such court’s
              jurisdiction; (ii) bring an action in state or federal court to
              protect its intellectual property rights ("intellectual property
              rights" means patents, copyrights, moral rights, trademarks, and
              trade secrets and other confidential or proprietary information,
              but not privacy or publicity rights); and (iii) seek a declaratory
              judgment, injunction, or other equitable relief in a court of
              competent jurisdiction regarding whether a party's claims are
              time-barred or may be brought in small claims court. Seeking such
              relief shall not waive a party's right to arbitration under this
              agreement, and any filed arbitrations related to any action filed
              pursuant to this paragraph shall automatically be stayed pending
              the outcome of such action. For clarity, this "Dispute Resolution"
              provision does not alter, amend, or affect any of the rights or
              obligations of the parties to any of our third party user
              agreements.
            </span>
            <span style={{display: "block"}}
              >You and our Company agree to submit to the personal jurisdiction
              of any federal or state court in Forsyth County, North Carolina,
              in order to compel arbitration, to stay proceedings pending
              arbitration, or to confirm, modify, vacate, or enter judgment on
              the award entered by the arbitrator; and in connection with any
              such proceeding, further agree to accept service of process by
              U.S. mail and hereby waive any and all jurisdictional and venue
              defenses otherwise available.
            </span>
            <span style={{display: "block"}}
              >Except as set forth below, if any provision of this Agreement is
              found by an arbitrator or court of competent jurisdiction to be
              invalid, the parties nevertheless agree that the arbitrator or
              court should endeavor to give effect to the parties' intentions as
              reflected in the provision, and the other provisions thereof
              remain in full force and effect.
            </span>
            <span style={{display: "block"}}
              >THE PARTIES UNDERSTAND THAT ARBITRATION MEANS THAT AN ARBITRATOR
              AND NOT A JUDGE OR JURY WILL DECIDE THE CLAIM, AND THAT RIGHTS TO
              PREHEARING EXCHANGE OF INFORMATION AND APPEALS MAY BE LIMITED IN
              ARBITRATION.
              <span style={{fontWeight: "bold"}}
                >YOU HEREBY ACKNOWLEDGE AND AGREE THAT YOU AND THE COMPANY ARE
                EACH WAIVING THE RIGHT TO A TRIAL BY JURY TO THE EXTENT
                PERMITTED BY LAW.
              </span>
            </span>
          </span>
        </li>
        <li style={{fontWeight: "bold"}}>
          <span style={{display: "block", textDecoration: "underline"}}
            >Class action and collective relief waiver.
          </span>
          <span style={{fontWeight: 500}}>
            YOU AND CYW, LLC, THE COMPANY, THE PLATFORM AN/OR SERVICE
            ACKNOWLEDGE AND AGREE THAT, TO THE EXTENT ALLOWED BY LAW, EXCEPT AS
            SET OUT BELOW, THERE SHALL BE NO RIGHT OR AUTHORITY FOR ANY DISPUTE
            TO BE ARBITRATED OR LITIGATED ON A CLASS, JOINT, COLLECTIVE OR
            CONSOLIDATED BASIS OR IN A PURPORTED REPRESENTATIVE CAPACITY ON
            BEHALF OF THE GENERAL PUBLIC (SUCH AS CLAIMS AS A PRIVATE ATTORNEY
            GENERAL OR FOR PUBLIC INJUNCTIVE RELIEF). EXCEPT AS SET OUT BELOW,
            UNLESS BOTH YOU AND CYW, LLC, THE COMPANY, THE PLATFORM AN/OR
            SERVICE OTHERWISE AGREE IN WRITING, THE ARBITRATOR MAY NOT PRESIDE
            OVER ANY FORM OF ANY CLASS, JOINT, COLLECTIVE OR REPRESENTATIVE
            PROCEEDING. THE ARBITRATOR MAY AWARD RELIEF (INCLUDING ANY
            DECLARATORY OR INJUNCTIVE RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL
            PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO RESOLVE AN
            INDIVIDUAL PARTY’S CLAIM. THE ARBITRATOR MAY NOT AWARD RELIEF FOR OR
            AGAINST ANYONE WHO IS NOT A PARTY TO THE PROCEEDING.
            <span style={{display: "block"}}
              >This Class Action and Collective Relief Waiver are an essential
              part of this "Dispute Resolution" section, and if it is deemed
              invalid or unenforceable with respect to a particular claim or
              dispute, neither you nor our Company may arbitrate such claim or
              dispute. Notwithstanding the foregoing, if a court or arbitrator
              (or where applicable, JAMS) determine that either the Class Action
              and Collective Relief Waiver or the provisions in Section 19(g)
              are not enforceable as to a particular claim or request for relief
              and all appeals from that decision have been exhausted (or the
              decision is otherwise final), then the parties agree that that
              particular claim or request for relief may proceed in court but
              shall be severed and stayed pending arbitration of the remaining
              claims. This provision does not prevent you or our Company from
              participating in a class-wide settlement of claims.
            </span>
          </span>
        </li>
        <li style={{fontWeight: "bold"}}>
          <span style={{display: "block", textDecoration: "underline"}}
            >Arbitration rules.
          </span>
          <span style={{fontWeight: 500}}>
            The arbitration will be administered by JAMS and resolved before a
            single arbitrator. If JAMS is not available to arbitrate, the
            parties will select an alternative arbitration provider, but in no
            event shall any arbitration be administered by the American
            Arbitration Association. Except as modified by this "Dispute
            Resolution" provision, JAMS will administer the arbitration in
            accordance with the JAMS Streamlined Arbitration Rules and
            Procedures for claims that do not exceed $250,000 and the JAMS
            Comprehensive Arbitration Rules and Procedures for claims exceeding
            $250,000, in each case applying the rules and procedures in effect
            at the time the arbitration is initiated, excluding any rules or
            procedures governing or permitting class or representative actions.
            You can find the JAMS rules and procedures here at this link. The
            Applicable JAMS rules and procedures are available at
            <a
              href="https://www.jamsadr.com/adr-rules-procedures/"
              style={{textDecoration: "underline"}}
              >https://www.jamsadr.com/adr-rules-procedures/
            </a>
            or by calling JAMS at (800) 352-5267.
          </span>
        </li>
        <li style={{fontWeight: "bold"}}>
          <span style={{display: "block", textDecoration: "underline"}}
            >Initiating arbitration.
          </span>
          <span style={{fontWeight: 500}}
            >Only after the parties have engaged in a good-faith effort to
            resolve the dispute in accordance with the Informal Dispute
            Resolution Procedure provision, and only if those efforts fail, then
            either party may initiate binding arbitration as the sole means to
            resolve claims. A party who desires to initiate arbitration must
            deliver to JAMS and the other party a demand for arbitration which
            satisfies the following requirements: (1) the name, telephone
            number, mailing address, and email address of the party seeking
            arbitration (if you are seeking arbitration, you must provide the
            email address associated with your authorized account with our
            Company, Platform and/or Service); (2) a statement of the legal
            claims being asserted and the factual bases of those claims; (3) a
            description of the remedy sought and a good-faith calculation of the
            amount in controversy (requests for injunctive relief or attorneys'
            fees shall not count toward the calculation of the amount in
            controversy unless such injunctive relief seeks the payment of
            money); (4) the party's original signature; and (5) the party's
            portion of the applicable filing fee. If the party seeking
            arbitration is represented by counsel, the demand for arbitration
            must also include counsel's name, firm, telephone number, mailing
            address, email address, and original signature.
            <span style={{display: "block"}}
              >If you are initiating arbitration, hard-copy service of the
              demand to our Company shall be made in accordance with JAMS's
              rules and procedures to the following address:
              ContactUs@Klink-iT.com.
            </span>
            <span style={{display: "block"}}
              >The arbitrator and/or JAMS may require amendment of any demand or
              counterclaim that does not satisfy these requirements. Where a
              party is represented by counsel, counsel's signature on the demand
              for arbitration or any other paper submitted to JAMS or the
              arbitrator constitutes a certification that such paper complies
              with the standard set forth in Federal Rule of Civil Procedure
              11(b). The arbitrator has the right to impose sanctions in
              accordance with the JAMS rules and procedures for any claims or
              submissions the arbitrator determines to violate such standard, as
              well as for a party's failure to comply with the Informal Dispute
              Resolution Procedure contemplated by this Agreement.
            </span>
          </span>
        </li>
        <li style={{fontWeight: "bold"}}>
          <span style={{display: "block", textDecoration: "underline"}}
            >Arbitration location and procedure.
          </span>
          <span style={{fontWeight: 500}}>
            Unless you and our Company otherwise agree, if you are a resident of
            the United States the arbitration will be conducted in the county
            where you reside, and if you are not a resident of the United States
            the arbitration shall be conducted in Forsyth County, North
            Carolina, United States of America. If the amount in controversy
            does not exceed $10,000 and you do not seek injunctive or
            declaratory relief, then the arbitration will be conducted solely on
            the basis of documents you and our Company submit to the arbitrator,
            unless the arbitrator determines that a hearing is necessary. If the
            amount in controversy exceeds $10,000 or seeks declaratory or
            injunctive relief, either party may request (or the arbitrator may
            determine) to hold a hearing, which shall be via videoconference or
            telephone conference unless the parties agree otherwise.
            <span style={{display: "block"}}
              >Subject to the applicable JAMS rules and procedures, the
              arbitrator will have the discretion to direct a reasonable
              exchange of information by the parties, consistent with the
              expedited, cost-efficient nature of the arbitration. The parties
              agree that the arbitrator may allow the filing of dispositive
              motions if they are likely to efficiently resolve or narrow issues
              in dispute. If a party timely serves an offer of judgment under
              Federal Rule of Civil Procedure 68, or any other state-law
              equivalent, and the judgment that the other party finally obtains
              is not more favorable than the unaccepted offer, then the other
              party shall pay the costs, including filing fees, incurred after
              the offer was made. Unless otherwise prohibited by law, all
              arbitration proceedings will be confidential and closed to the
              public and any parties other than you and the Company, and all
              records relating thereto will be permanently sealed, except as
              necessary to obtain court confirmation of the arbitration award
              (provided that the party seeking confirmation shall seek to file
              such records under seal to the extent permitted by law).
            </span>
          </span>
        </li>
        <li style={{fontWeight: "bold"}}>
          <span style={{display: "block", textDecoration: "underline"}}>
            Batch arbitration.
          </span>
          <span style={{fontWeight: 500}}>
            To increase the efficiency of administration and resolution of
            arbitrations, in the event 100 or more similar arbitration demands
            (those asserting the same or substantially similar facts, and
            seeking the same or substantially similar relief) presented by or
            with the assistance or coordination of the same law firm(s) or
            organization(s) are submitted to JAMS (or another arbitration
            provider selected in accordance with Section 19(d) if JAMS is
            unavailable) against our Company within reasonably close proximity,
            the arbitration provider shall (i) administer the arbitration
            demands in batches of 100 demands per batch (to the extent there are
            fewer than 100 arbitration demands left over after the batching
            described above, a final batch will consist of the remaining
            demands); (ii) designate one arbitrator for each batch; and (iii)
            provide for a single filing fee due per side per batch. Arbitrator
            selection for each batch shall be conducted to the greatest extent
            possible in accordance with the applicable JAMS rules and procedures
            for such selection, and the arbitrator will determine the location
            where the proceedings will be conducted. You agree to cooperate in
            good faith with our Company and the arbitration provider to
            implement such a "batch approach" or other similar approach to
            provide for an efficient resolution of claims, including the payment
            of single filing and administrative fees for batches of claims. This
            "Batch Arbitration" provision shall in no way be interpreted as
            authorizing class arbitration of any kind. Our Company does not
            agree or consent to class arbitration, private attorney general
            arbitration, or arbitration involving joint or consolidated claims
            under any circumstances, except as set forth herein.
            </span>
        </li>
        <li style={{fontWeight: "bold"}}>
          <span style={{display: "block", textDecoration: "underline"}}>
            Arbitrator's decision.
          </span>
          <span style={{fontWeight: 500}}>
            The arbitrator will render an award within the time frame specified
            in the applicable JAMS rules and procedures. The arbitrator's
            decision will include the essential findings and conclusions upon
            which the arbitrator based the award. Judgment on the arbitration
            award may be entered in any court having jurisdiction thereof. The
            arbitrator will have the authority to award monetary damages on an
            individual basis and to grant, on an individual basis, any
            non-monetary remedy or relief available to an individual to the
            extent available under applicable law, the arbitral forum's rules,
            and this Agreement. The arbitrator's award of damages and/or other
            relief must be consistent with controlling law and also must be
            consistent with the terms of the "Limitation of Liability" section
            above as to the types and the amounts of damages or other relief for
            which a party may be held liable. No arbitration award or decision
            will have any preclusive effect as to issues or claims in any
            dispute with anyone who is not a named party to the arbitration.
            <span style={{display: "block"}}>
              Attorneys' fees will be available to the prevailing party in the
              arbitration only if authorized under applicable substantive law
              governing the claims in the arbitration.
            </span>
          </span>
        </li>
        <li style={{fontWeight: "bold"}}>
          <span style={{display: "block", textDecoration: "underline"}}>Fees.</span>
          <span style={{fontWeight: 500}}>
            Pursuant to the applicable JAMS Rules and Consumer Arbitration
            Minimum Standards, you will be required to pay $250 to initiate an
            arbitration against our Company. To the extent the filing fee for
            the arbitration exceeds the cost of filing a lawsuit, the arbitrator
            may require our Company to pay the portion of that fee that exceeds
            the cost of filing suit. You are responsible for your own attorneys'
            fees unless the arbitration rules and/or applicable law provide
            otherwise. If the arbitrator finds the arbitration to be
            non-frivolous, Our Company will pay all of the actual filing and
            arbitrator fees for the arbitration, provided your claim does not
            exceed $75,000. For claims above $75,000, fees and costs will be
            determined in accordance with applicable JAMS rules. The arbitration
            rules permit you to recover attorneys’ fees in certain cases.
            <span style={{display: "block"}}>
              The parties agree that JAMS has discretion to modify the amount
              or timing of any administrative or arbitration fees due under
              JAMS's Rules where it deems appropriate provided that such
              modification does not increase the costs to you, and you waive any
              objection to such fee modification. The parties also agree that a
              good-faith challenge by either party to the fees imposed by JAMS
              does not constitute a default, waiver, or breach of this agreement
              or Terms of Service while such challenge remains pending before
              JAMS, the arbitrator, and/or a court of competent jurisdiction,
              and that any and all due dates for those fees shall be tolled
              during the pendency of such challenge.
            </span>
          </span>
        </li>
        <li style={{fontWeight: "bold"}}>
          <span style={{display: "block", textDecoration: "underline"}}>
            Right to opt-out of arbitration.
          </span>
          <span style={{fontWeight: 500}}>
            Any updates to these Terms do not provide you with a new opportunity
            to opt out of arbitration if you agreed to a previous version of the
            Terms and did not validly opt out of arbitration. Our Company will
            continue to honor any valid opt outs if you opted out of arbitration
            in a prior version of the Terms. If you create an account for the
            first time on or after June 9, 2021, you may opt out of arbitration.
            To opt out, you must notify our Company in writing no later than 30
            days after first becoming subject to the Dispute Resolution
            provisions of these Terms. Your notice must include your full name,
            mailing address, the email address associated with your account, a
            clear statement that you want to opt out of this Mutual Arbitration
            Agreement, and your original signature. The notice cannot be signed
            by your attorney, agent, or other representative, and you may only
            opt out on behalf of yourself individually. You must send your
            opt-out notice by U.S. Postal Service certified mail to Att.
            Klink-iT P.O. Box 1351, Clemmons, NC. 27012-1351.
            <span style={{display: "block"}}>
              If you opt out of this Agreement, all other parts of this
              Agreement will continue to apply to you. Opting out of this
              Arbitration Agreement has no effect on any other arbitration
              agreements that you may have entered into nor does it otherwise
              alter any of the other Terms of Service now in effect or that you
              may enter into in the future with our Company. If you do not
              timely opt out of this Agreement, such action shall constitute
              mutual acceptance of the terms of this Agreement by you and Our
              Company.
            </span>
          </span>
        </li>
      </ol>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>Disclaimer of warranties</h2>
      <p>
        THE PLATFORM AND SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE." CYW,
        LLC and our Company DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES,
        EXPRESS, IMPLIED, OR STATUTORY, NOT EXPRESSLY SET OUT IN THESE TERMS,
        INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE AND NON-INFRINGEMENT. IN ADDITION, CYW, LLC MAKES NO
        REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING THE RELIABILITY,
        TIMELINESS, QUALITY, SUITABILITY, OR AVAILABILITY OF THE PLATFORM AND
        SERVICES OR GOODS REQUESTED THROUGH THE USE OF THE PLATFORM AND
        SERVICES, OR THAT THE PLATFORM AND SERVICES WILL BE UNINTERRUPTED OR
        ERROR-FREE.
      </p>
      <p>
        CYW, LLC and our Company DOES NOT GUARANTEE THE QUALITY, SUITABILITY,
        SAFETY OR ABILITY OF ANY THIRD PARTY. YOU AGREE THAT THE ENTIRE RISK
        ARISING OUT OF YOUR USE OF THE PLATFORM OR SERVICES, AND ANY SERVICE OR
        GOOD REQUESTED IN CONNECTION THEREWITH, REMAINS SOLELY WITH YOU, TO THE
        MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.
      </p>
      <p>
        OUR COMPANY DOES NOT CONTROL, MANAGE OR DIRECT ANY THIRD-PARTY PROVIDERS
        INCLUDING RETAILERS, DELIVERY SERVICES, AND DELIVERY DRIVERS. THIRD
        PARTY PROVIDERS ARE NOT ACTUAL AGENTS, APPARENT AGENTS, OSTENSIBLE
        AGENTS, OR EMPLOYEES OF CYW, LLC and/or OUR COMPANY, PLATFORM OR
        SERVICE.
      </p>
      <p>
        OUR COMPANY DOES NOT CONTROL, ENDORSE OR TAKE RESPONSIBILITY FOR ANY
        USER CONTENT OR THIRD-PARTY CONTENT AVAILABLE ON OR LINKED TO BY THE
        PLATFORM OR SERVICES. CYW, LLC CANNOT AND DOES NOT REPRESENT OR WARRANT
        THAT THE PLATFORM, SERVICES, OR SERVERS ARE FREE OF VIRUSES OR OTHER
        HARMFUL COMPONENTS.
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>Limitation of liability</h2>
      <p>
        CYW, LLC and/or OUR COMPANY SHALL NOT BE LIABLE FOR INDIRECT,
        INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES,
        INCLUDING LOST PROFITS, LOST DATA, PERSONAL INJURY, OR PROPERTY DAMAGE
        RELATED TO, IN CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY USE OF
        THE PLATFORM OR SERVICES, REGARDLESS OF THE NEGLIGENCE (EITHER ACTIVE,
        AFFIRMATIVE, SOLE, OR CONCURRENT) OF CYW, LLC EVEN IF CYW, LLC HAS BEEN
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
      </p>
      <p>
        CYW, LLC and/or OUR COMPANY SHALL NOT BE LIABLE FOR ANY DAMAGES,
        LIABILITY OR LOSSES ARISING OUT OF: (i) YOUR USE OF OR RELIANCE ON THE
        PLATFORM OR SERVICES OR YOUR INABILITY TO ACCESS OR USE THE PLATFORM OR
        SERVICES; OR (ii) ANY TRANSACTION OR RELATIONSHIP BETWEEN YOU AND ANY
        THIRD PARTY PROVIDER, EVEN IF OUR COMPANY HAS BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES. CYW, LLC SHALL NOT BE LIABLE FOR DELAY OR
        FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND OUR REASONABLE
        CONTROL. YOU ACKNOWLEDGE THAT RETAILERS, DELIVERY SERVICES, AND DELIVERY
        DRIVERS ARE NOT OSTENSIBLE AGENTS, APPARENT AGENTS, ACTUAL AGENTS, OR
        EMPLOYEES OF CYW, LLC or our COMPANY.
      </p>
      <p>
        THE LIMITATIONS AND DISCLAIMERS IN THIS SECTION DO NOT PURPORT TO LIMIT
        LIABILITY OR ALTER YOUR RIGHTS AS A CONSUMER THAT CANNOT BE EXCLUDED
        UNDER APPLICABLE LAW. BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW
        THE EXCLUSION OF OR THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
        INCIDENTAL DAMAGES, IN SUCH STATES OR JURISDICTIONS, CYW, LLC’s
        LIABILITY SHALL BE LIMITED TO THE EXTENT PERMITTED BY LAW. THIS
        PROVISION SHALL HAVE NO EFFECT ON CYW, LLC’s CHOICE OF LAW PROVISION SET
        FORTH BELOW.
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>Indemnification</h2>
      <p>
        You agree to indemnify and hold CYW, LLC and its affiliates and their
        officers, directors, employees, and agents harmless from and against any
        and all actions, claims, demands, losses, liabilities, costs, damages,
        and expenses (including attorneys' fees), arising out of or in
        connection with: (a) your use of the Platform and Services or goods
        obtained through your use of the Platform and Services; (b) your breach
        or violation of any of these Terms; (c) use of your User Content; or (d)
        your violation of the rights of any third party, including Retailers,
        delivery services, and delivery drivers.
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>Applicable law</h2>
      <p>
        The laws of North Carolina, excluding its conflicts of law rules, govern
        these Terms to the extent not preempted by The Federal Arbitration Act.
        The state and federal courts located in the state of North Carolina
        shall have jurisdiction over all claims or disputes that are not subject
        to arbitration. Solicitations, offers, advertisements and communications
        are void where prohibited.
      </p>
      <p>
        You must comply with any local, state or national laws applicable to
        your use of the Platform and Services, such as rules and regulations
        regarding the sale, service, transportation, import, shipment, purchase
        or delivery of alcoholic beverages and other alcohol containing
        products. You must comply with all applicable U.S. or other export and
        re-export restrictions.
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>Notice</h2>
      <p>
        Where CYW, LLC requires that you provide an e-mail address, you are
        responsible for providing your most current email address. In the event
        that the last e-mail address you provided is not valid, or for any
        reason is not capable of delivering to you any notices required or
        permitted by these Terms, the dispatch of the e-mail containing such
        notice will nonetheless constitute effective notice. You may give notice
        to CYW, LLC by sending an email to ContactUs@Klink-iT.com Such notice
        shall be deemed given on the next business day after such e-mail is
        actually received.
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>Other terms</h2>
      <p>
        You may not assign these Terms without prior written approval. CYW, LLC
        may assign these Terms without your consent to: (a) a subsidiary or
        affiliate; (b) an acquirer of CYW, LLC’s equity, business or assets; or
        (c) a successor by merger. Any purported assignment by you in violation
        of this section shall be void. No joint venture, partnership,
        employment, or agency relationship exists between you, our Company or
        any third party as a result of these Terms or Service and/or the
        Platform and Services. If any provision of these Terms is held to be
        invalid or unenforceable, such provision shall be struck and the
        remaining provisions shall be enforced to the fullest extent under law.
        Any failure to enforce any right or provision in these Terms shall not
        constitute a waiver of such right or provision unless acknowledged and
        agreed to by in writing.
      </p>
    </section>
    <section className={styles["tos-section"]}>
      <h2 className={styles["tos-section-title"]}>Updates</h2>
      <p>
        CYW, LLC may make changes to these Terms from time to time. If changes
        are made, it will provide you with notice of such changes, such as by
        sending an email, providing a notice through the Platform and/or
        Service, or updating the date at the top of these Terms. Unless
        otherwise stated in the updated Terms, the amended Terms will be
        effective immediately and your continued access to and use of the
        Platform and Service after such notice will confirm your acceptance of
        the changes. If you do not agree to the amended Terms, you must stop
        accessing and using the Platform Service.
      </p>
      <p>
        Please note that our Mobile Apps are available to be downloaded within
        the United States and Canada.
      </p>
    </section>
    </div>
  );
}
