import React, { useState } from "react";
import { useAlert, useApi } from "../../hooks";
import { TextAreaInput, TextInput } from "../Inputs";
import ModalWrapper from "./ModalWrapper";

function ContactUsModal({ modalOpen, setModalOpen }) {
  const api = useApi();
  const alert = useAlert();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleContactUsSubmit = () => {
    api
      .post("/public/SendEmail", {
        fullName: `${firstName} ${lastName}`,
        fromEmail: email,
        message: message,
      })
      .then(() => {
        setModalOpen(false);
        alert("Message sent successfully!", "", "success");
      })
      .catch((error) => {
        console.error(error);
        alert("Error sending message. Please try again later.");
      });
  };

  return (
    <ModalWrapper open={modalOpen} setOpen={setModalOpen}>
      <div className="w-[80vw] lg:w-[50vw] flex flex-col text-center">
        <div>
          <TextInput
            label={"First Name"}
            maxlength={50}
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
            className="modal-input w-1/2 bg-gray-100 p-1"
          />
        </div>
        <div>
          <TextInput
            label="Last name"
            maxlength={50}
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className=" modal-input w-1/2 bg-gray-100 p-1"
            style={{ margin: "auto" }}
          />
        </div>
        <div className="mb-3 flex flex-col">
          <TextInput
            label={"Email"}
            maxLength={50}
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="modal-input w-1/2 bg-gray-100 p-1"
          />
        </div>
        <div className="mb-3 flex flex-col">
          <TextAreaInput
            label={"Message"}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className=" modal-input w-1/2 bg-gray-100 p-1"
            maxlength={250}
            placeholder="Please enter your message"
            style={{ margin: "auto" }}
          />
        </div>
        <div>
          <button
            onClick={handleContactUsSubmit}
            className="w-1/4 bg-klinkOrange text-white py-2 font-semibold uppercase tracking-wide text-lg"
            style={{ marginTop: "1vh", borderRadius: "5px" }}>
            Submit
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}

export default ContactUsModal;
