import {
    // ArrowPathIcon,
    // PlusCircleIcon,
    // XMarkIcon,
    ArrowLongLeftIcon
  } from "@heroicons/react/24/solid";
import { useState, useEffect } from "react";
// import { AppIndicator } from "react-bootstrap-icons";
import { useNavigate, useLocation } from "react-router-dom";
import { PrimaryButton } from "../components/Buttons";
import { CollapsibleSection } from "../components/Displays";
import { SelectInput, DateInput, TextAreaInput } from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { useLoaderStore, useUserStore } from "../context";
import { useAlert, useApi } from "../hooks";
// import constants from "../utils/constants";
import dateHelpers from "../utils/dateHelpers";
import _ from "lodash";

export default function BusinessFollowupHistory() {    
    const navigate = useNavigate();
    const location = useLocation();
    const {id, businessId, businessName} = location.state;    
    const alert = useAlert();
    const isCreatingNew = id === 0;
    const { currentUser } = useUserStore();
    const { fetch, post } = useApi();
    const { setShowLoader } = useLoaderStore();
    const [businessFollowupHistory, setBusinessFollowupHistory] = useState({
        contactedAt: dateHelpers.getSelectedDate(new Date(), "YYYY-MM-DD"),
        businessFollowupStatus: {label: "In Process", value: 1},
    });
    const [users, setUsers] = useState([]);

    const followupStatuses = [
        {label: "In Process", value: 1},
        {label: "Declined", value: 2}, 
        {label: "No Authorized Personnel Available", value: 3}
    ];

    useEffect(() => {
        getSysAdmins();
        if (!isCreatingNew) {
            getFollowup();
        }
    }, []);

    function getSysAdmins() {
        fetch("User/GetUsersSimple", {isSysAdmin: true})
        .then((response) => {
            let simpleList = convertList(response);
            let user = _.find(simpleList, {value: currentUser.id});           
            setUsers(simpleList);
            setBusinessFollowupHistory({
                ...businessFollowupHistory,
                contactedBy: user
            });
        })
        .catch((err) => {
            console.error(err);
        });
    }

    function getFollowup() {
        post("Business/GetBusinessFollowupHistories", {id: id, activeOnly: true, businessFollowupId: businessId})
        .then((response) => {            
            let followup = response.list[0];
            setBusinessFollowupHistory({
                ...followup,
                contactedBy: {label: `${followup.contactedBy}`, value: followup.contactedByUserId},
                businessFollowupStatus: {label: followup.businessFollowupStatusDescription, value: followup.businessFollowupStatus}
            });
        })
        .catch((err) => {
            console.error(err);
        });
    }

    function convertList(list) {
        let formattedList = _.map(list, (x) => {
            return {label: `${x.firstName} ${x.lastName}`, value: x.id}
        });     
        return formattedList;
    }

    const handleChange = (property, value) => {
        setBusinessFollowupHistory({
            ...businessFollowupHistory,
            [property]: value
        })
    }

    const isValid = () => {
        if (!businessFollowupHistory.contactedBy) {
            alert("Invalid Input", "Please Select a value for Contacted By", "warning");
            return false;
        }
        if (!businessFollowupHistory.contactedAt) {
            alert("Invalid Input", "Please Select a value for Contacted At", "warning");
            return false;
        }
        if (!businessFollowupHistory.businessFollowupStatus) {
            alert("Invalid Input", "Please Select a value for Followup Status", "warning");
            return false;
        }
        if (!businessFollowupHistory.comments 
            || !businessFollowupHistory.comments.trim() 
            || !businessFollowupHistory.comments.trim().length
        ) {
            alert("Invalid Input", "Please Enter a Comment", "warning");
            return false;
        }
        return true;
    }

    const handleSave = () => {
        if (!isValid()) return;

        let payload = {
            id: id,
            businessFollowupStatus: businessFollowupHistory.businessFollowupStatus.value,
            contactedByUserId: businessFollowupHistory.contactedBy.value,
            businessFollowupId: businessId,
            contactedAt: businessFollowupHistory.contactedAt,
            comments: businessFollowupHistory.comments
        }        

        setShowLoader(true);
        post("Business/SaveBusinessFollowupHistory", payload)
        .then((response) => {            
            alert("Success", "Saved Followup History", "success");
            navigate("/businessFollowupHistories", {state: {id: businessId, businessName: businessName}});
        })
        .catch((err) => {
            alert("Unable to Save Followup", err.data.message || "Please Try Again", "error");
            console.error(err);
        })
        .finally(() => {
            setShowLoader(false);
        })
    }

    return (
        <div>
            {/* Header */}
            <PageHeader 
            title={
                isCreatingNew || !businessFollowupHistory ? "Add New Business Followup History Record" : "Update Business Followup History Record"
            }
            />            
            <div className="flex cursor-pointer hover:opacity-70 text-primaryColor my-2" onClick={() => navigate(`/businessFollowupHistories`, {state: {id: businessId, businessName: businessName}})}>
             <ArrowLongLeftIcon className="w-5 mr-2" />
                {`Manage followups for ${businessName}`}
            </div>

            <div className="bg-gray-100 shadow-md rounded-md shadow-primaryColor/25">
                {/* Form */}
                <CollapsibleSection title="Info">
                    <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4">
                        <div>
                            <SelectInput 
                            label="Contacted By"
                            value={businessFollowupHistory?.contactedBy}
                            options={users}
                            onChange={(e) => handleChange("contactedBy", e)}
                            />
                        </div>
                        <div>
                            <DateInput
                            label="Contacted At"
                            value={businessFollowupHistory?.contactedAt}
                            onChange={(e) => handleChange("contactedAt", e.target.value)}
                            />
                        </div>
                        <div>
                            <SelectInput
                            label="Followup Status"
                            value={businessFollowupHistory?.businessFollowupStatus}
                            onChange={(e) => handleChange("businessFollowupStatus", e)}
                            options={followupStatuses}
                            />
                        </div>
                        <div>
                            <TextAreaInput
                            label="Comments"
                            value={businessFollowupHistory?.comments}
                            onChange={(e) => handleChange("comments", e.target.value)}
                            maxLength={500}
                            />
                        </div>
                    </div>
                </CollapsibleSection>

                {/* Submit Button */}
                <div className="mt-5 pb-4 px-4" style={{width: "10%"}}>
                    <PrimaryButton 
                    text={isCreatingNew ? "Create" : "Save"}
                    onClick={handleSave}                    
                    />
                </div>
            </div>

        </div>
    );
}