import { XMarkIcon } from "@heroicons/react/24/solid";
import _ from "lodash";
import { useEffect, useState } from "react";
import { PrimaryButton } from "../components/Buttons";
import { TextInput, ToggleInput } from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { FilterSection, PrimaryTable } from "../components/Tables";
import { useLoaderStore } from "../context";
import { useAlert, useApi, useDebounce } from "../hooks";
import constants from "../utils/constants";
import dateHelpers from "../utils/dateHelpers";
import { switchSortDirection } from "../utils/helpers";

export default function Sessions() {
  const alert = useAlert();
  const { post } = useApi();
  const { setShowLoader } = useLoaderStore();
  const [filters, setFilters] = useState({ activeOnly: true });
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortDirection, setSortDirection] = useState(
    constants.SORT_DIRECTIONS.ASCENDING
  );
  const [sortField, setSortField] = useState("userId");
  const [displayCount, setDisplayCount] = useState(
    constants.DISPLAY_PER_PAGE ?? 25
  );
  const [pagedData, setPagedData] = useState(null);
  const { debounced: debouncedFilters } = useDebounce({ toDebounce: filters });
  const { debounced: debouncedDisplayCount } = useDebounce({
    toDebounce: displayCount,
  });

  useEffect(() => {
    setCurrentPage(1);
    setDisplayCount(25);
  }, [filters]);

  useEffect(() => {
    refreshList();
  }, [
    currentPage,
    debouncedDisplayCount,
    debouncedFilters,
    sortDirection,
    sortField,
  ]);

  function refreshList(pageNo = null) {
    if (isLoading) return;
    setIsLoading(true);
    post("user/getActiveSessions", {
      pageNumber: pageNo || currentPage,
      pageSize: debouncedDisplayCount,
      sortDirection,
      sortField,
      isExact: false,
      ...debouncedFilters,
    })
      .then((responseData) => {
        setPagedData(responseData);
      })
      .catch((err) => {
        alert(
          "Error",
          err.statusText || "Error While Attempting To Refresh Session Data",
          "error"
        );
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(switchSortDirection(sortDirection));
    } else {
      setSortField(field);
    }
  };

  const revokeSession = (userId) => {
    setShowLoader(true);
    post(`User/RevokeSession/${userId}`)
      .then(() => {
        alert(
          "Success",
          `Revoked Session For User With Id: ${userId}`,
          "success"
        );
        refreshList();
      })
      .catch((err) => {
        alert(
          "Error",
          err.data.message || "Error While Attempting To Revoke Session",
          "error"
        );
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  return (
    <div>
      {/* Header */}
      <div className="flex justify-between">
        <PageHeader title="Manage Sessions" />
      </div>
      {/* Filter Section */}
      <SessionFilterSection filters={filters} setFilters={setFilters} />

      {/* Table */}
      <PrimaryTable
        isLoading={isLoading}
        filters={filters}
        totalCount={pagedData?.totalCount}
        displayCount={displayCount}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setDisplayCount={setDisplayCount}
        header={
          <PrimaryTable.Header>
            <PrimaryTable.HeaderColumn
              display="User Id"
              value={filters.userId}
              sortable
              onSort={() => handleSort("userId")}
              isSortingBy={sortField === "userId"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, userId: "" })}
              onType={(e) => setFilters({ ...filters, userId: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Id"
              value={filters.id}
              onClear={() => setFilters({ ...filters, userId: "" })} // Not sure if necessary
            />
            <PrimaryTable.HeaderColumn
              display="IP Address"
              value={filters.ipAddress}
              onClear={() => setFilters({ ...filters, ipAddress: "" })}
            />
            <PrimaryTable.HeaderColumn
              display="Started At"
              value={filters.startedAt}
              onSort={() => handleSort("startedAt")}
              isSortingBy={sortField === "startedAt"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters })} // not sure if necessary
            />
            <PrimaryTable.HeaderColumn
              display="Expires At"
              value={filters.expiresAt}
              onSort={() => handleSort("expiresAt")}
              isSortingBy={sortField === "expiresAt"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, expiresAt: "" })} // Not Sure If Necessary
            />
            <PrimaryTable.HeaderColumn
              display="Revoked At"
              value={filters.revokedAt}
              onSort={() => handleSort("revokedAt")}
              isSortingBy={sortField === "revokedAt"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, revokedAt: "" })} // not sure if necessary
            />
            <PrimaryTable.HeaderColumn
              display="Revoked By User Id"
              value={filters.revokedByUserId}
              onSort={() => handleSort("revokedByUserId")}
              isSortingBy={sortField === "revokedByUserId"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, revokedByUserId: "" })} // not sure if necessary
            />
            <PrimaryTable.HeaderColumn display="Revoke Session" />
          </PrimaryTable.Header>
        }
      >
        {pagedData &&
          pagedData.list &&
          _.map(pagedData.list, (x, index) => {
            return (
              <PrimaryTable.Row key={index}>
                <PrimaryTable.Cell>{x.userId}</PrimaryTable.Cell>
                <PrimaryTable.Cell>{x.id}</PrimaryTable.Cell>
                <PrimaryTable.Cell>{x.ipAddress}</PrimaryTable.Cell>
                <PrimaryTable.Cell>
                  {dateHelpers.monthDayAndYear(x.startedAt)}
                </PrimaryTable.Cell>
                <PrimaryTable.Cell>
                  {dateHelpers.monthDayAndYear(x.expiresAt)}
                </PrimaryTable.Cell>
                <PrimaryTable.Cell>
                  {dateHelpers.monthDayAndYear(x?.revokedAt)}
                </PrimaryTable.Cell>
                <PrimaryTable.Cell>{x.revokedByUserId}</PrimaryTable.Cell>
                <PrimaryTable.Cell>
                  {x.revokedAt ? (
                    "Revoked"
                  ) : (
                    <XMarkIcon
                      title="Revoke Session"
                      className="w-6 mx-1 text-white bg-delete rounded-md p-0.5 cursor-pointer"
                      onClick={() => revokeSession(x.userId)}
                    />
                  )}
                </PrimaryTable.Cell>
              </PrimaryTable.Row>
            );
          })}
      </PrimaryTable>
    </div>
  );
}

const SessionFilterSection = ({ filters, setFilters }) => {
  return (
    <FilterSection filters={filters}>
      <div>
        <PrimaryButton 
          text="Reset"
          backgroundColor="gray-500"
          className="float-right"
          small
          onClick={() => setFilters({})}                    
        />
        <div className="mb-2">
          <ToggleInput
            label="Unrevoked and Unexpired Only"
            enabled={filters.activeOnly}
            setEnabled={() =>
              setFilters({
                ...filters,
                activeOnly: !filters.activeOnly,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="User Id"
            placeHolder="User Id"
            value={filters.userId}
            onChange={(e) =>
              setFilters({
                ...filters,
                userId: e.target.value,
              })
            }
          />
        </div>
        <div>
          <TextInput
            label="Revoked By User Id"
            name="Revoked By User Id"
            value={filters.revokedByUserId}
            onChange={(e) =>
              setFilters({
                ...filters,
                revokedByUserId: e.target.value,
              })
            }
          />
        </div>
      </div>
    </FilterSection>
  );
};
