import React from "react";
import { ModalWrapper } from ".";
import { PrimaryButton } from "../Buttons";
import dateHelpers from "../../utils/dateHelpers";
import { BuildingOfficeIcon } from "@heroicons/react/24/solid";

export default function BusinessDetailsModal({
  modalOpen,
  setModalOpen,
  business,
  handleReject,
  approveRequest,
}) {
  const DetailRow = ({ label, value }) => (
    <tr className="align-top ">
      <td className="text-left pr-2 font-bold pb-0.5 text-primaryColor">
        {label}
      </td>
      <td className="text-left pl-2 text-gray-500">{value ? value : "-"}</td>
    </tr>
  );

  return (
    <ModalWrapper open={modalOpen} setOpen={setModalOpen}>
      <div className="w-[85vw] lg:w-[35vw]">
        {business ? (
          <>
            <div className="pb-4 text-center flex items-center flex-col">
              <div className="flex flex-row justify-between gap-2 pb-3 ">
                {/* Reject */}
                {!business.deactivatedAt && (
                  <PrimaryButton
                    text="Reject"
                    backgroundColor="delete"
                    onClick={() => handleReject(business.id)}
                  />
                )}
                {/* Approve */}
                {!business.deactivatedAt && (
                  <PrimaryButton
                    text="Approve"
                    onClick={() => approveRequest(business.id)}
                  />
                )}
              </div>
              <div className="flex flex-row justify-between gap-2">
                <BuildingOfficeIcon className="w-5" />
                {business.name}
              </div>
            </div>
            <div className="pb-7 mx-6 text-center text-sm">
              {/* Display business details in a table */}
              <table className="table-auto w-full">
                <tbody>
                  <DetailRow label="EIN" value={business.ein} />
                  <DetailRow
                    label="Registered"
                    value={dateHelpers.monthDayAndYear(business.createdAt)}
                  />
                  <DetailRow label="Email" value={business.email} />
                  <DetailRow label="Phone" value={business.phone} />
                  <DetailRow label="Biography" value={business.biography} />
                  <DetailRow
                    label="Location"
                    value={[
                      business.address1,
                      business.address2 && `${business.address2}`,
                      business.address3 && `${business.address3}`,
                      [
                        business.city,
                        business.state && ` ${business.state}`,
                        business.state && business.postalCode
                          ? ` ${business.postalCode}`
                          : business.postalCode,
                      ]
                        .filter(Boolean)
                        .join(", "),
                      !business.state &&
                        business.city &&
                        ` ${business.postalCode}`,
                      business.country,
                    ]
                      .filter(Boolean)
                      .join("<br>")
                      .split("<br>")
                      .map((item, index) => (
                        <p key={index}>{item.replace(/,([^,]*)$/, " $1")}</p>
                      ))}
                  />
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div className="pb-8 mx-6 text-center text-sm text-gray-500">
            No information available.
          </div>
        )}
      </div>
    </ModalWrapper>
  );
}
