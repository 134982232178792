export default function RadioInput({
  id,
  label,
  subLabel,
  value,
  checked,
  groupName,
}) {
  return (
    <div className="relative flex items-start">
      <div className="flex items-center h-5 ">
        <input
          id={id}
          name={groupName}
          type="radio"
          value={value}
          checked={checked}
          className="cursor-pointer rounded border-none focus:ring-primaryColor h-4 w-4 border-gray-300 accent-primaryColor"
        />
      </div>
      {label && (
        <div className="ml-3 text-sm">
          <label htmlFor={id} className="font-normal text-gray-700">
            <span>{label}</span>
          </label>
          {subLabel && <p className="text-gray-400">{subLabel}</p>}
        </div>
      )}
    </div>
  );
}
