import { Label } from ".";
import { classNames } from "../../utils/helpers";

export default function PhoneInput({
  label,
  subLabel,
  name,
  placeholder = "",
  required = false,
  value,
  onChange,
}) {
  const formatPhoneNumber = (input) => {
    // Remove all non-digit characters from the input
    const cleanedInput = input.replace(/\D/g, "");

    // Format the cleanedInput to (XXX) XXX - XXXX
    let formattedNumber = "";
    if (cleanedInput.length >= 10) {
      formattedNumber = `(${cleanedInput.slice(0, 3)}) ${cleanedInput.slice(
        3,
        6
      )} - ${cleanedInput.slice(6, 10)}`;
    } else if (cleanedInput.length > 6) {
      formattedNumber = `(${cleanedInput.slice(0, 3)}) ${cleanedInput.slice(
        3,
        6
      )} - ${cleanedInput.slice(6)}`;
    } else if (cleanedInput.length > 3) {
      formattedNumber = `(${cleanedInput.slice(0, 3)}) ${cleanedInput.slice(
        3
      )}`;
    } else if (cleanedInput.length > 0) {
      formattedNumber = `(${cleanedInput}`;
    }

    return formattedNumber;
  };

  const unformatPhoneNumber = (formattedNumber) => {
    const unformattedNumber = formattedNumber.replace(/\D/g, "").slice(0, 10);
    return unformattedNumber;
  };

  return (
    <>
      {/* Label/required asterisk */}
      {label && <Label label={label} required={required} />}
      <div className={classNames("flex relative", label ? "mt-1" : "")}>
        {/* Input */}
        <input
          type="text"
          name={name}
          className={classNames(
            "p-2 rounded-md shadow-md focus:outline-none block w-full sm:text-sm border-gray-300 placeholder-gray-400",
            !onChange ? "bg-gray-200" : ""
          )}
          placeholder={placeholder}
          required={required}
          value={formatPhoneNumber(value)}
          onChange={(e) => onChange(unformatPhoneNumber(e.target.value))}
          readOnly={!onChange}
        />
      </div>
      {/* Sublabel */}
      {subLabel && (
        <div className="mt-1 text-gray-400 text-xs text-right">{subLabel}</div>
      )}
    </>
  );
}
