import { useNotificationStore } from "../context";

const useAlert = () => {
  const { setOpen, setTitle, setBody, setAutoClose, setType } =
    useNotificationStore();
  return (title, body, type, autoClose) => {
    setBody(body);
    setTitle(title);
    setOpen(true);
    setType(type);
    setAutoClose(autoClose !== undefined ? autoClose : true);
  };
};

export default useAlert;
