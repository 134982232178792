import {
  ChevronDownIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useId, useRef, useState } from "react";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en";
import "react-phone-number-input/style.css";
import { Label } from ".";
import { classNames } from "../../utils/helpers";

const CountryDropdown = ({
  selectedCountry,
  setSelectedCountry,
  setShowCountryDropdown,
}) => {
  const searchBarRef = useRef(null);
  const [filteredCountries, setFilteredCountries] = useState(getCountries());

  const handleSearchChange = (event) => {
    const searchString = event.target.value;
    const filtered = getCountries().filter(
      (countryCode) =>
        en[countryCode].toLowerCase().includes(searchString.toLowerCase()) ||
        countryCode.toLowerCase().includes(searchString.toLowerCase())
    );

    setFilteredCountries(filtered);
  };

  useEffect(() => {
    if (searchBarRef.current) searchBarRef.current.focus();
  }, []);

  return (
    <div className="z-10 shadow-lg absolute top-10 w-full bg-white max-h-48 overflow-y-scroll">
      <div className="flex bg-white py-2 px-4 sticky top-0 z-10 text-sm text-gray-400 border-b mb-2">
        <span className="absolute inset-y-0 left-0 pl-2 py-4 text-sm flex items-center">
          <MagnifyingGlassIcon className="w-4" />
        </span>
        <input
          type="text"
          ref={searchBarRef}
          className={classNames(
            "w-full pl-4 text-sm outline-none border-gray-300 placeholder-gray-400"
          )}
          placeholder="Search country"
          onChange={handleSearchChange}
          autoComplete="off"
        />
      </div>
      {filteredCountries?.map((x, i) => (
        <div
          key={`country-${i}`}
          className={classNames(
            selectedCountry === x
              ? "border-l-2 border-primaryColor bg-gray-100"
              : "",
            "flex justify-between px-4 py-1 text-sm text-gray-600 hover:bg-gray-100 cursor-pointer"
          )}
          onClick={() => {
            setSelectedCountry(x);
            setShowCountryDropdown(false);
          }}
        >
          <div>{en[x]}</div>
          <div className="text-primaryColor">+{getCountryCallingCode(x)}</div>
        </div>
      ))}
    </div>
  );
};

export default function TelephoneInput({
  value,
  name,
  onChange,
  label,
  placeholder = "",
  required = false,
  sideLabel,
  onSideLabelClick,
  selectedCountry,
  setSelectedCountry,
}) {
  const id = useId();
  const [showCountryDropdown, setShowCountryDropdown] = useState(false);

  useEffect(() => {
    const handler = (e) => {
      const telephoneInput = document.getElementById(`telephoneInput${id}`);
      if (
        telephoneInput &&
        !telephoneInput.contains(e.target) &&
        showCountryDropdown
      ) {
        setShowCountryDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => document.removeEventListener("mousedown", handler);
  }, [id, showCountryDropdown]);

  return (
    <div id={`telephoneInput${id}`}>
      {/* Label/required asterisk */}
      <div className="flex justify-between">
        {label && <Label label={label} required={required} />}
        {sideLabel && onSideLabelClick && (
          <div
            onClick={onSideLabelClick}
            className="text-blue-500 font-medium cursor-pointer"
          >
            {sideLabel}
          </div>
        )}
      </div>
      <div className={classNames("flex relative", label ? "mt-1" : "")}>
        <span className="absolute inset-y-0 left-0 pl-2 py-4 text-sm flex items-center">
          <div
            className="flex cursor-pointer"
            onClick={() => setShowCountryDropdown((prev) => !prev)}
          >
            {selectedCountry} +{getCountryCallingCode(selectedCountry)}{" "}
            {showCountryDropdown ? (
              <ChevronUpIcon className="w-6 ml-3 pr-2 border-r text-primaryColor" />
            ) : (
              <ChevronDownIcon className="w-6 ml-3 pr-2 border-r text-primaryColor" />
            )}
          </div>
        </span>
        <input
          type="text"
          name={name}
          className={classNames(
            "pl-28 p-2 rounded-md shadow-md w-full focus:outline-none text-sm border-gray-300 placeholder-gray-400",
            !onChange ? "bg-gray-200" : ""
          )}
          placeholder={placeholder}
          required={required}
          value={value}
          onChange={onChange}
          readOnly={!onChange}
          autoComplete="off"
        />
        {showCountryDropdown && (
          <CountryDropdown
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            setShowCountryDropdown={setShowCountryDropdown}
          />
        )}
      </div>
    </div>
  );
}
