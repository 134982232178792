import {
  CheckCircleIcon,
  // UserCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { handleSave } from ".";
import { PrimaryButton } from "../components/Buttons";
import { Pill } from "../components/Displays";
import { TelephoneInput, TextInput } from "../components/Inputs";
import { useLoaderStore, useUserStore } from "../context";
import { useAlert, useApi } from "../hooks";
import dateHelpers from "../utils/dateHelpers";

export default function Profile() {
  let { id } = useParams();
  const { currentUser } = useUserStore();
  const { setShowLoader } = useLoaderStore();
  const { fetch, post } = useApi();
  const alert = useAlert();
  const navigate = useNavigate();

  const [user, setUser] = useState(undefined);
  const [selectedCountry, setSelectedCountry] = useState("US");

  const { data } = useSWR(
    `user/getUserInfo/${id}`,
    fetch,
    {
      revalidateOnFocus: false,
      onSuccess: () => setShowLoader(false),
      onError: () => setShowLoader(false),
    }
  );

  useEffect(() => {
    if (currentUser.id !== Number(id)) {
      // profiles are only accessible to yourself (ie private)
      alert(
        "Error",
        "You do not have access to view this user's profile",
        "error"
      );
      navigate("/");
    } else {
      setShowLoader(true);
    }
  }, []);

  useEffect(() => {
    if (!data) return;
    setUser(data);
  }, [data]);

  const handleChange = (property, value) => {
    setUser({
      ...user,
      [property]: value,
    });
  };

  const getBusinessName = (businessId) => {
    const business = data?.businesses.find((b) => b.id === businessId);
    return business ? business.name : null;
  };

  const getOrgName = (orgId) => {
    const org = data?.orgs.find((o) => o.id === orgId);
    return org ? org.name : null;
  };

  return (
    <div className="-m-4">
      {/* Header */}
      <div className="bg-white shadow-lg h-[30vh] flex items-center justify-center space-x-4">
        <div>
          {/*<UserCircleIcon className="w-32 text-primaryColor" />*/}
          <img
            className="w-48 h-48 rounded-full"
            style={{objectFit: "cover"}}
            src={currentUser.imageUrl}
            alt="User Profile"
          />
        </div>
        <div>
          <div className="text-xl font-semibold mb-2">
            {currentUser.firstName} {currentUser.lastName}
          </div>
          <div className="text-gray-500">
            {currentUser.email} | {currentUser.username}
          </div>
          <div className="text-gray-500">{currentUser.timezone}</div>
        </div>
      </div>

      <div className="p-4">
        {/* Roles */}
        <div className="mt-6">
          <div className="space-x-2 space-y-2">
            {currentUser.roles.map((role, i) => (
              <Pill
                key={i}
                title={role.roleName}
                additionalData={
                  role.businessId
                    ? getBusinessName(role.businessId)
                    : role.orgId
                    ? getOrgName(role.orgId)
                    : null
                }
              />
            ))}
          </div>
        </div>

        {/* Info */}
        <div className="mt-6">
          <div className="">
            <div className="flex text-gray-500">
              Email verified{" "}
              {currentUser.emailVerified === true ? (
                <CheckCircleIcon className="w-6 ml-2 text-success" />
              ) : (
                <XCircleIcon className="w-6 ml-2 text-error" />
              )}
            </div>
            <div className="flex text-gray-500">
              Last logged on{" "}
              <div className="text-primaryColor ml-2">
                {dateHelpers.getRelativeTime(currentUser.lastLoggedOnAt)}
              </div>
            </div>
          </div>

          <div className="mt-4">
            {/* Form */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4">
              <div>
                <TextInput
                  label="First Name"
                  value={user?.firstName ?? ""}
                  onChange={(e) => handleChange("firstName", e.target.value)}
                  maxLength={30}
                  required
                />
              </div>
              <div>
                <TextInput
                  label="Last Name"
                  value={user?.lastName ?? ""}
                  onChange={(e) => handleChange("lastName", e.target.value)}
                  maxLength={30}
                  required
                />
              </div>
              <div>
                <TextInput
                  label="Email"
                  value={user?.email ?? ""}
                  onChange={(e) => handleChange("email", e.target.value)}
                  maxLength={50}
                  required
                />
              </div>
              <div>
                <TextInput
                  label="Username"
                  value={user?.username ?? ""}
                  onChange={(e) => handleChange("username", e.target.value)}
                  maxLength={30}
                  required
                />
              </div>
              <div>
                <TelephoneInput
                  name="phone"
                  label="Phone number"
                  value={user?.mobilePhoneNumber ?? ""}
                  onChange={(e) =>
                    handleChange("mobilePhoneNumber", e.target.value)
                  }
                  selectedCountry={selectedCountry}
                  setSelectedCountry={setSelectedCountry}
                  required
                />
              </div>
            </div>

            {/* Submit button */}
            <div className="mt-12">
              <PrimaryButton
                text="Save"
                onClick={() =>
                  handleSave(
                    user,
                    navigate,
                    alert,
                    post,
                    setShowLoader,
                    selectedCountry
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
