import { Fragment, useState, useEffect, useCallback } from "react";
import _ from 'lodash';
import { TextInput, ToggleInput, DateInput } from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { FilterSection, PrimaryTable } from "../components/Tables";
import { useLoaderStore } from "../context";
import { useAlert, useApi } from "../hooks";
import constants from "../utils/constants";
import dateHelpers from "../utils/dateHelpers";
import { switchSortDirection } from "../utils/helpers";
import { PrimaryButton } from "../components/Buttons";
import { RejectionModal, ButtonPromptModal } from "../components/Modals";
import { useUserStore } from "../context";
import { useNavigate } from "react-router-dom";
import { Paginator } from "../components/Pagination";
import BusinessDetailsModal from "../components/Modals/BusinessDetailsModal";

export default function ReviewBusinesses() {
  const alert = useAlert();
  const navigate = useNavigate();
  const { currentUser } = useUserStore();
  const { post, fetch } = useApi();
  const { setShowLoader } = useLoaderStore();
  const [rejectionModalOpen, setRejectionModalOpen] = useState(false);
  const [businessDetailsOpen, setbusinessDetailsOpen] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [rejectionId, setRejectionId] = useState(null);
  const [filters, setFilters] = useState({ activeOnly: true });
  const [debouncedFilters, setDebouncedFilters] = useState(filters);
  const [currentPage, setCurrentPage] = useState(1);
  const [pendingStripeBusinessId, setPendingStripeBusinessId] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [paginatedList, setPaginatedList] = useState(null);
  const [sortDirection, setSortDirection] = useState(constants.SORT_DIRECTIONS.ASCENDING);
  const [sortField, setSortField] = useState("id");
  const [debouncedSortData, setDebouncedSortData] = useState({sortField, sortDirection});
  const [displayCount, setDisplayCount] = useState(constants.DISPLAY_PER_PAGE ?? 25);
  const [debouncedDisplayCount, setDebouncedDisplayCount] = useState(displayCount);
  const [debouncedCurrentPage, setDebouncedCurrentPage] = useState(currentPage);

  const debounceSortData = useCallback(
    _.debounce((sortField, sortDirection) => {
      setDebouncedSortData({sortField, sortDirection});
    }, 600)
  , []);

  const debounceFilters = useCallback(
    _.debounce((filters) => {
      setDebouncedFilters(filters);
    }, 600)
  , []);

  const debounceDisplayCount = useCallback(
    _.debounce((displayCount) => {
      setDebouncedDisplayCount(displayCount);
    }, 600)
  , []);

  const debounceCurrentPage = useCallback(
    _.debounce((currentPage) => {
      setDebouncedCurrentPage(currentPage);
    }, 600)
  , []);

  const isSysAdmin = currentUser.roles.some(
    (role) => role.typeOfRole === constants.ROLE_IDS.SYSTEM_ADMIN
  );

  useEffect(() => debounceCurrentPage(currentPage), [currentPage])
  useEffect(() => debounceDisplayCount(displayCount), [displayCount])
  useEffect(() => debounceFilters(filters), [filters])
  useEffect(() => {
    debounceSortData(sortField, sortDirection);
  }, [sortField, sortDirection])
  useEffect(refreshData, [debouncedCurrentPage, debouncedSortData, debouncedDisplayCount, debouncedFilters])

  useEffect(() => {
    if (!isSysAdmin) {
      navigate("*");
    }
    setCurrentPage(1);
    setDisplayCount(25);
  }, [filters]);


  function refreshData() {
    if (isLoading) return;
    setIsLoading(true);
    fetch("admin/businessList", {
      Status: constants.BUSINESS_STATUSES.PENDING,
      pageNumber: debouncedCurrentPage,
      pageSize: debouncedDisplayCount,
      sortDirection: debouncedSortData.sortDirection,
      sortField: debouncedSortData.sortField,
      isExact: false,
      ...debouncedFilters
    }).then(res => {
      setPaginatedList(res);
    }).catch((err) => console.error(err))
    .finally(() => setIsLoading(false));
  }

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(switchSortDirection(sortDirection));
    } else {
      setSortField(field);
    }
  };

  const approveRequest = (businessId) => {
    setShowLoader(true);
    post("admin/ApproveBusiness", businessId)
      .then((res) => {
        alert(
          "Success",
          res.message || "Business has been approved",
          "success"
        );
        refreshData();
      })
      .catch((err) => {
        if (
          err?.data?.message === "Stripe ACH account is not yet configured."
        ) {
          // prompt with explanation and present send reminder email option
          setPendingStripeBusinessId(businessId);
        } else {
          alert(
            "Error",
            err.data.message || "Business could not be approved",
            "error"
          );
          console.error(err);
        }
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const handleReject = (businessId) => {
    setRejectionModalOpen(true);
    setRejectionId(businessId);
  };

  const getInputValue = (value) => {
    setInputValue(value);
  };

  const handleConfirm = () => {
    const rejectionData = {
      id: rejectionId,
      rejectionMessage: inputValue,
    };
    rejectRequest(rejectionData);
    setRejectionModalOpen(false);
  };

  const handlePress = (business) => {
    setbusinessDetailsOpen(true);
    setSelectedBusiness(business);
  };

  function onRemindOfStripeSetup() {
    setShowLoader(true);
    post(`admin/RemindBusinessOfStripeSetup/${pendingStripeBusinessId}`)
      .then((res) => {
        alert(
          "Success",
          res.message || "A Stripe setup reminder has been sent",
          "success"
        );
        setPendingStripeBusinessId(null);
        refreshData();
      })
      .catch((err) => {
        alert(
          "Error",
          err.data.message || "Business setup reminder failed to send",
          "error"
        );
        console.error(err);
      })
      .finally(() => setShowLoader(false));
  }

  const rejectRequest = (rejectionData) => {
    setShowLoader(true);
    post("admin/RejectBusiness", rejectionData)
      .then((res) => {
        alert(
          "Success",
          res.message || "Business has been rejected",
          "success"
        );
        refreshData();
      })
      .catch((err) => {
        alert(
          "Error",
          err.data.message || "Business could not be rejected",
          "error"
        );
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
        setRejectionModalOpen(false);
      });
  };

  return (
    <div className="w-100 h-auto">
      {/* Header */}
      <div className="flex justify-between">
        <PageHeader title="Manage Pending Businesses" />
      </div>
      <div className="hidden lg:block">
        {/* Filter section */}
        <BusinessFilterSection filters={filters} setFilters={setFilters} />
        {/* Table */}
        <PrimaryTable
          isLoading={isLoading}
          filters={filters}
          totalCount={paginatedList?.totalCount}
          displayCount={displayCount}
          currentPage={currentPage}
          setDisplayCount={setDisplayCount}
          setCurrentPage={setCurrentPage}
          header={
            <PrimaryTable.Header>
              <PrimaryTable.HeaderColumn
                display="Name"
                value={filters.name}
                sortable
                colSpan="2"
                onSort={() => handleSort("name")}
                isSortingBy={sortField === "name"}
                sortDirection={sortDirection}
                onClear={() => setFilters({ ...filters, name: "" })}
                onType={(e) => setFilters({ ...filters, name: e.target.value })}
              />
              <PrimaryTable.HeaderColumn
                display="EIN"
                value={filters.ein}
                sortable
                onSort={() => handleSort("ein")}
                isSortingBy={sortField === "ein"}
                sortDirection={sortDirection}
                onClear={() => setFilters({ ...filters, ein: "" })}
                onType={(e) => setFilters({ ...filters, ein: e.target.value })}
              />
              <PrimaryTable.HeaderColumn
                display="Email"
                value={filters.email}
                onClear={() => setFilters({ ...filters, email: "" })}
                onType={(e) =>
                  setFilters({ ...filters, email: e.target.value })
                }
              />
              <PrimaryTable.HeaderColumn
                display="Phone"
                value={filters.phone}
                onClear={() => setFilters({ ...filters, phone: "" })}
                onType={(e) =>
                  setFilters({ ...filters, phone: e.target.value })
                }
              />
              <PrimaryTable.HeaderColumn
                display="Location"
                value={filters.location}
                onClear={() => setFilters({ ...filters, location: "" })}
                onType={(e) =>
                  setFilters({ ...filters, location: e.target.value })
                }
              />
              <PrimaryTable.HeaderColumn
                display="Registered"
                value={filters.createdAt}
                sortable
                onSort={() => handleSort("createdAt")}
                isSortingBy={sortField === "createdAt"}
                sortDirection={sortDirection}
                onClear={() => setFilters({ ...filters, createdAt: "" })}
                onType={(e) =>
                  setFilters({ ...filters, createdAt: e.target.value })
                }
              />
              <PrimaryTable.HeaderColumn display="Biography" />
              {/* Insert column headers here */}
            </PrimaryTable.Header>
          }
        >
          {paginatedList && paginatedList.list
            ? _.map(paginatedList.list, (x, i) => (
              <PrimaryTable.Row key={`${x.id}-${i}`} index={i}>
                <PrimaryTable.Cell>{x.name}</PrimaryTable.Cell>
                <PrimaryTable.Cell replaceClassName="py-0 px-2 border border-gray-100">
                  <div className="flex flex-row justify-between">
                    {!x.deactivatedAt && (
                      <Fragment>
                        <PrimaryButton
                          text="Reject"
                          backgroundColor="delete"
                          className="mr-2 mt-1"
                          onClick={() => handleReject(x.id)}
                        />
                        <PrimaryButton
                          text="Approve"
                          className="mr-0 mt-1"
                          onClick={() => approveRequest(x.id)}
                        />
                      </Fragment>
                    )}
                  </div>
                </PrimaryTable.Cell>
                <PrimaryTable.Cell>{x.ein}</PrimaryTable.Cell>
                <PrimaryTable.Cell>{x.email}</PrimaryTable.Cell>
                <PrimaryTable.Cell>{x.phone}</PrimaryTable.Cell>
                <PrimaryTable.Cell>
                  {[
                    x.address1,
                    x.address2 && `${x.address2}`,
                    x.address3 && `${x.address3}`,
                    [
                      x.city,
                      x.state && ` ${x.state}`,
                      x.state && x.postalCode
                        ? ` ${x.postalCode}`
                        : x.postalCode,
                    ]
                      .filter(Boolean)
                      .join(", "),
                    !x.state && x.city && ` ${x.postalCode}`,
                    x.country,
                  ]
                    .filter(Boolean)
                    .join("<br>")
                    .split("<br>")
                    .map((item, index) => (
                      <p key={index}>{item.replace(/,([^,]*)$/, " $1")}</p>
                    ))}
                </PrimaryTable.Cell>
                {/* TODO: include link to open location in maps once integrated */}
                <PrimaryTable.Cell>
                  {dateHelpers.monthDayAndYear(x.createdAt)}
                </PrimaryTable.Cell>
                <PrimaryTable.Cell>{x.biography}</PrimaryTable.Cell>
              </PrimaryTable.Row>
            ))
            : null
          }
        </PrimaryTable>
        <ButtonPromptModal
          modalOpen={pendingStripeBusinessId ? true : false}
          setModalOpen={() => setPendingStripeBusinessId(null)}
          title="Stripe Setup Pending"
          message="This business has not yet completed their Stripe account setup."
          buttonText="Send Reminder Email"
          onButtonClick={onRemindOfStripeSetup}
        />
        <RejectionModal
          modalOpen={rejectionModalOpen}
          setModalOpen={setRejectionModalOpen}
          title="Deny Business Request?"
          message="Please provide a reason for denying this request. The user will be notified and can resubmit their request."
          maxLength={200}
          placeholder="This message will be visible to the requesting user."
          confirmButtonText="Deny Request"
          getInput={getInputValue}
          onConfirm={(e) => handleConfirm(e.target.value)}
        />
      </div>
      {/* mobile/smaller screen UI */}
      <div className="lg:hidden">
        <ul className="mb-4 space-y-3">
          {paginatedList?.list
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((x, index) => (
              <li
                key={index}
                onClick={() => handlePress(x)}
                className="bg-white hover:bg-backgroundColor p-3 shadow-md shadow-primaryColor/50 rounded-md w-full cursor-pointer transition duration-300"
              >
                {x.name}
              </li>
            ))}
        </ul>
        <Paginator
          count={paginatedList?.totalCount}
          display={displayCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setDisplay={setDisplayCount}
        />
        <BusinessDetailsModal
          modalOpen={businessDetailsOpen}
          setModalOpen={setbusinessDetailsOpen}
          business={selectedBusiness}
          handleReject={handleReject}
          approveRequest={approveRequest}
        />
      </div>
    </div>
  );
}

const BusinessFilterSection = ({ filters, setFilters }) => {
  return (
    <FilterSection filters={filters}>
      <div>
        <PrimaryButton 
          text="Reset"
          backgroundColor="gray-500"
          className="float-right"
          small
          onClick={() => setFilters({})}                    
        />
        <div className="mb-2">
          <ToggleInput
            label="Show active records only"
            enabled={filters.activeOnly}
            setEnabled={() =>
              setFilters({
                ...filters,
                activeOnly: !filters.activeOnly,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Name"
            placeholder="Name"
            value={filters.name}
            onChange={(e) =>
              setFilters({
                ...filters,
                name: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="EIN"
            placeholder="EIN"
            value={filters.ein}
            onChange={(e) =>
              setFilters({
                ...filters,
                ein: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Email"
            placeholder="Email"
            value={filters.email}
            onChange={(e) =>
              setFilters({
                ...filters,
                email: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Phone"
            placeholder="Phone"
            value={filters.phone}
            onChange={(e) =>
              setFilters({
                ...filters,
                phone: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Location"
            placeholder="Location"
            value={filters.location}
            onChange={(e) =>
              setFilters({
                ...filters,
                location: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <DateInput
            label="Registered Before"
            value={filters.Before}
            onChange={(e) =>
              setFilters({
                ...filters,
                Before: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <DateInput
            label="Registered Since"
            value={filters.Since}
            onChange={(e) =>
              setFilters({
                ...filters,
                Since: e.target.value,
              })
            }
          />
        </div>
        {/* Add filters here */}
      </div>
    </FilterSection>
  );
};
