import moment from "moment";

// Constants for date formats
const YMD = "YYYY-MM-DD";
const MDY = "MM/DD/YY";
const MDY4 = "MM/DD/YYYY";
//const YMDHMS = "YYYY-MM-DD HH:mm:ss";
const YMDHM = "YYYY-MM-DD HH:mm";
const MDYHMA = "M/D/YY h:mmA";

// Converts a date string to a Moment object
// @param date: The date string to convert
// @param fmt: Optional format string to specify the date format
// @returns: A Moment object representing the input date
const momentFromString = function (date, fmt = null) {
  if (fmt) {
    return moment(date, fmt, true);
  }
  if (date) {
    return moment(date);
  }
  return null;
};

// List of valid date formats for verification
const VALID_DATE_FORMAT_LIST = [MDY4, "M/D/YY", "MM/D/YY", MDY, "MM/D/YYYY", "M/D/YYYY", YMD];

// Calculates the age based on the provided birth date string
// @param dateString: The birth date in the format "YYYY-MM-DD"
// @returns: The calculated age
function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}


// Get the relative time string from the given UTC date-time string
// @param utcDateTime: The UTC date-time string to convert to relative time
// @returns: A user-friendly relative time string like "2 hours ago" or "5 days ago"
function getRelativeTime(utcDateTime) {
  if (!utcDateTime) {
    return "-";
  }

  // Convert UTC date-time to local date-time
  const localDateTime = moment.utc(utcDateTime).local();

  // Calculate the difference between the current local time and the local date-time
  const diffInMilliseconds = moment().diff(localDateTime);

  // Use moment.js to format the relative time string
  const duration = moment.duration(diffInMilliseconds);
  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  //const seconds = duration.seconds();

  if (years >= 1) {
    return years === 1 ? "1 year ago" : `${years} years ago`;
  } else if (months >= 1) {
    return months === 1 ? "1 month ago" : `${months} months ago`;
  } else if (days >= 1) {
    return days === 1 ? "1 day ago" : `${days} days ago`;
  } else if (hours >= 1) {
    return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
  } else if (minutes >= 1) {
    return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
  } else {
    return "Just now";
  }
}


// Verifies if a date string is valid based on a list of valid date formats
// @param s: The date string to verify
// @returns: A Moment object if the date is valid, otherwise null
const verifyDate = (s) => {
  if (!s) return null;
  const test = moment(s, VALID_DATE_FORMAT_LIST);
  return test.isValid() ? test : null;
};

// Helper functions for date formatting and parsing
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  MDY,
  YMD,
  MDY4,
  YMDHM,
  MDYHMA,
  verifyDate,

  // Checks if a date string is valid in the format "MM/DD/YYYY" or the provided format
  // @param dateString: The date string to check
  // @returns: True if the date string is valid, otherwise false
  isDateValid(dateString, fmt = "MM/DD/YYYY") {
    if (!dateString) {
      return false;
    }
    if (!moment(dateString, fmt, true).isValid()) {
      return false;
    }
    return true;
  },

  // Gets the selected date in the specified format or as a Moment object
  // @param date: The date string to convert
  // @param fmt: Optional format string to specify the date format
  // @returns: The formatted date string or a Moment object representing the input date
  getSelectedDate(date, fmt = null) {
    if (date) {
      return fmt ? moment(date).format(fmt) : moment(date);
    }
    return null;
  },

  // Formats a date to a short date string in the specified format
  // @param d: The date string to format
  // @param fmt: Optional format string to specify the date format
  // @returns: The formatted short date string
  toShortDateString(d, fmt = null) {
    if (!d) return "";
    return momentFromString(d, fmt).format("DD-MMM-YY");
  },

  // Formats a date to a date string in MM-DD-YY format
  // @param d: The date string to format
  // @param fmt: Optional format string to specify the date format
  // @returns: The formatted date string
  toMDYDateString(d, fmt = null) {
    if (!d) return "";
    return momentFromString(d, fmt).format("MM-DD-YY");
  },

  // Formats a date to a full date string in "MMMM Do YYYY, h:mma" format
  // @param d: The date string to format
  // @param fmt: Optional format string to specify the date format
  // @returns: The formatted full date string
  toFullDateString(d, fmt = null) {
    if (!d) return "";
    return momentFromString(d, fmt).format("MMMM Do YYYY, h:mma");
  },

  // Formats a date to a date string in "ddd, MM/DD/YY" format
  // @param d: The date string to format
  // @param fmt: Optional format string to specify the date format
  // @returns: The formatted date string
  toDateString(d, fmt = null) {
    if (!d) return "";
    return momentFromString(d, fmt).format("ddd, MM/DD/YY");
  },

  // Formats a date to a date string in "MM/DD/YY" format
  // @param d: The date string to format
  // @param fmt: Optional format string to specify the date format
  // @returns: The formatted date string
  toDateStringNoDayOfWeek(d, fmt = null) {
    if (!d) return "";
    return momentFromString(d, fmt).format("MM/DD/YY");
  },
  // Formats a date to a time string in "h:mma" format
  // @param d: The date string to format
  // @param fmt: Optional format string to specify the date format
  // @returns: The formatted time string
  toTimeString(d, fmt = null) {
    if (!d) return "";
    return momentFromString(d, fmt).format("h:mma");
  },

  // Converts a Moment object to a string in "YYYY-MM-DD" format
  // @param m: The Moment object to convert
  // @returns: The date string in "YYYY-MM-DD" format
  getStringFromMoment(m) {
    if (m) {
      return m.format("YYYY-MM-DD");
    }
    return null;
  },

  // Formats a date to a short date string in "MM/DD/YYYY" format
  // @param date: The date string to format
  // @param fmt: Optional format string to specify the date format
  // @returns: The formatted date string in "MM/DD/YYYY" format
  formatDateToShortDate(date, fmt = null) {
    if (date) {
      if (moment.isMoment(date)) {
        return date.format(MDY4);
      } else if (fmt === null) {
        return moment(date).format(MDY4);
      } else {
        return moment(date, fmt, true).format(MDY4);
      }
    }
    return date;
  },

  // Gets the time string from a Moment object in "hh:mm A" format
  // @param m: The Moment object containing time information
  // @returns: The formatted time string in "hh:mm A" format
  getTimeStringFromMoment(m) {
    if (!m) return "";
    return m.format("hh:mm A");
  },

  // Parses a date string from a specific format to another format
  // @param s: The date string to parse
  // @param fmt: The format of the input date string
  // @returns: The parsed date string in the specified format
  parseDatePickerDate(s, fmt = YMD) {
    const validated = verifyDate(s);
    if (validated) {
      return validated.format(fmt);
    } else {
      return s;
    }
  },

  // Gets a list of time slots in 30-minute intervals starting from 06:00 AM
  // @returns: An array of time strings in "hh:mm A" format
  getTimeRange() {
    const start = moment().startOf("day").hour(6).minute(0);
    const times = 14 * 2; // 14 hours * two 30 mins sessions/hour
    const timeRange = [];

    for (let i = 0; i < times; i++) {
      const toPrint = moment(start).add(30 * i, "minutes").format("hh:mm A");
      timeRange.push(toPrint);
    }

    return timeRange;
  },

  // Formats a date to a date string in "YYYY-MM-DD" format for server communication
  // @param date: The date string to format
  // @param fmt: Optional format string to specify the date format
  // @returns: The formatted date string in "YYYY-MM-DD" format
  formatDateForServer(date, fmt) {
    if (date) {
      if (moment.isMoment(date)) return date.format(YMD);
      return moment(date, fmt).format(YMD);
    }
    return date;
  },

  // Converts a time string from "hh:mm a" format to "YYYY-MM-DD HH:mm:ss" format for server communication
  // @param timeString: The time string to convert
  // @returns: The formatted time string in "YYYY-MM-DD HH:mm:ss" format
  timeConverterForServer(timeString) {
    return moment(timeString, "hh:mm a").format("YYYY-MM-DD HH:mm:ss");
  },

  // Converts a date-time string from "YYYY-MM-DDTHH:mm:ss" format to "hh:mma" format for client display
  // @param dateTimeString: The date-time string to convert
  // @returns: The formatted date-time string in "hh:mma" format
  timeConverterForClient(dateTimeString) {
    return moment(dateTimeString).format("hh:mma");
  },

  // Formats a date-time in "YYYY-MM-DDTHH:mm:ss" format for server communication
  // @param dateTime: The date-time string to format
  // @returns: The formatted date-time string in "YYYY-MM-DDTHH:mm:ss" format
  formatDateTimeForServer(dateTime) {
    if (moment.isMoment(dateTime)) return dateTime.format("YYYY-MM-DDTHH:mm:ss");
    return moment(dateTime).format("YYYY-MM-DDTHH:mm:ss");
  },

  // Converts a UTC date-time string to local date-time string with a specified format
  // @param utcDateTime: The UTC date-time string to convert
  // @param fmt: Optional format string to specify the date-time format
  // @returns: The converted local date-time string
  localDateTime(utcDateTime, fmt = null) {
    if (!utcDateTime) {
      return null;
    }
    let format = fmt || MDYHMA;
    return moment.utc(utcDateTime, "YYYY-M-DTh:mm:ss.SS").local().format(format);
  },

  // Converts a date-time string to a JavaScript Date object
  // @param datetime: The date-time string to convert
  // @returns: The JavaScript Date object
  dateFormatForClient(datetime) {
    if (datetime) {
      return moment(datetime).toDate();
    }
  },

  // Formats a date-time string to a specified format for client display
  // @param dateTime: The date-time string to format
  // @param fmt: Optional format string to specify the date-time format
  // @returns: The formatted date-time string
  dateTimeFormat(dateTime, fmt = null) {
    if (!fmt) fmt = "MM/DD/YYYY hh:mm:ss";
    if (dateTime) {
      return moment.utc(dateTime).format(fmt);
    }
    return dateTime;
  },

  // Formats a date-time string to "MM/DD/YYYY" format for client display
  // @param dateTime: The date-time string to format
  // @returns: The formatted date string in "MM/DD/YYYY" format
  monthDayAndYear(dateTime) {
    if (dateTime) {
      return moment.utc(dateTime).format(MDY4);
    }
    return dateTime;
  },

  momentFromString,
  getRelativeTime,
  getAge,
};

 
