import _ from "lodash";
import { useEffect, useState } from "react";
import { PrimaryButton } from "../components/Buttons";
import { TextInput, ToggleInput } from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { PrimaryTable, StaticFilterSection } from "../components/Tables";
import { useApi, useReportDownloadHelpers } from "../hooks";
import constants from "../utils/constants";
import dateHelpers from "../utils/dateHelpers";

const GiftReportTable = ({ filters, data }) => {
  // const handleSelectedGifts = (giftId) => {
  //   setSelectedGifts((prevSelected) =>
  //     prevSelected.includes(giftId)
  //     ? prevSelected.filter((id) => id !== giftId)
  //     : [...prevSelected, giftId]
  //   )
  // };

  return (
    <>
      <PrimaryTable
        totalCount={data?.totalCount}
        displayCount={constants.DISPLAY_PER_PAGE}
        header={
          <PrimaryTable.Header>
            {/* <PrimaryTable.HeaderColumn display="Select" /> */}
            <PrimaryTable.HeaderColumn display="To-User" />
            <PrimaryTable.HeaderColumn display="From-User" />
            <PrimaryTable.HeaderColumn display="From-Organization" />
            <PrimaryTable.HeaderColumn display="Business" />
            <PrimaryTable.HeaderColumn display="Amount" />
            <PrimaryTable.HeaderColumn display="Paid-Amount" />
            <PrimaryTable.HeaderColumn display="Paid-At" />
            <PrimaryTable.HeaderColumn display="Canceled-At" />
            <PrimaryTable.HeaderColumn display="Finalized-At" />
            <PrimaryTable.HeaderColumn display="Redemption-Started-At" />
            <PrimaryTable.HeaderColumn display="Failed-At" />
            <PrimaryTable.HeaderColumn display="Transaction-Id" />
          </PrimaryTable.Header>
        }
      >
        {data &&
          _.map(data.list, (gift, index) => (
            <PrimaryTable.Row
              key={`${gift.id}-${index}`}
              index={index}
              onClick={(e) => {
                // navigate to gift detail page
              }}
            >
              {/* <PrimaryTable.Cell>
                <input
                  type="checkbox"
                  checked={selectedGifts.includes(gift.id)}
                  onChange={() => handleSelectedGifts(gift.id)}
                />
              </PrimaryTable.Cell> */}
              <PrimaryTable.Cell>{gift.toUserUsername}</PrimaryTable.Cell>
              <PrimaryTable.Cell>{gift.fromUserUsername}</PrimaryTable.Cell>
              <PrimaryTable.Cell>{gift.fromOrganizationName}</PrimaryTable.Cell>
              <PrimaryTable.Cell>{gift.businessName}</PrimaryTable.Cell>
              <PrimaryTable.Cell>{gift.amount}</PrimaryTable.Cell>
              <PrimaryTable.Cell>{gift.paidAmount}</PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {dateHelpers.monthDayAndYear(gift.paidAt)}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {dateHelpers.monthDayAndYear(gift.cancelledAt)}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {dateHelpers.monthDayAndYear(gift.finalizedAt)}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {dateHelpers.monthDayAndYear(gift.redemptionStartedAt)}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {dateHelpers.monthDayAndYear(gift.paymentFailedAt)}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>{gift.paymentTransactionId}</PrimaryTable.Cell>
            </PrimaryTable.Row>
          ))}
      </PrimaryTable>
    </>
  );
};

const GiftReportFilterSection = ({ filters, onFiltersChange }) => {
  return (
    <StaticFilterSection filters={filters}>
      <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full p-4">
        <div className="col-span-1 min-w-full">
          <TextInput
            label="To User"
            placeholder="To User"
            value={filters.ToName}
            onChange={(e) =>
              onFiltersChange({
                ...filters,
                ToName: e.target.value,
              })
            }
          />
        </div>
        <div className="col-span-1 min-w-full">
          <TextInput
            label="From User"
            placeholder="From User"
            value={filters.FromName}
            onChange={(e) =>
              onFiltersChange({
                ...filters,
                FromName: e.target.value,
              })
            }
          />
        </div>
        <div className="col-span-1 min-w-full">
          <TextInput
            label="From Organization"
            placeholder="From Organization"
            value={filters.FromOrganization}
            onChange={(e) =>
              onFiltersChange({
                ...filters,
                FromOrganization: e.target.value,
              })
            }
          />
        </div>
        <div className="col-span-1 min-w-full">
          <TextInput
            label="Business"
            placeholder="Business"
            value={filters.Business}
            onChange={(e) =>
              onFiltersChange({
                ...filters,
                Business: e.target.value,
              })
            }
          />
        </div>
        <div className="col-span-1 min-w-full">
          <TextInput
            label="Amount"
            placeholder="Amount"
            value={filters.Amount}
            onChange={(e) =>
              onFiltersChange({
                ...filters,
                Amount: e.target.value,
              })
            }
          />
        </div>
        <div className="col-span-1 min-w-full">
          <TextInput
            label="Paid Amount"
            placeholder="Paid Amount"
            value={filters.PaidAmount}
            onChange={(e) =>
              onFiltersChange({
                ...filters,
                PaidAmount: e.target.value,
              })
            }
          />
        </div>
        <div className="col-span-1 min-w-full">
          <TextInput
            label="Paid At"
            placeholder="Paid At"
            value={filters.PaidAt}
            onChange={(e) =>
              onFiltersChange({
                ...filters,
                PaidAt: e.target.value,
              })
            }
          />
        </div>
        <div className="col-span-1 min-w-full">
          <TextInput
            label="Cancelled At"
            placeholder="Canceled At"
            value={filters.CancelledAt}
            onChange={(e) =>
              onFiltersChange({
                ...filters,
                CancelledAt: e.target.value,
              })
            }
          />
        </div>
        <div className="col-span-1 min-w-full">
          <TextInput
            label="Finalized At"
            placeholder="Finalized At"
            value={filters.FinalizedAt}
            onChange={(e) =>
              onFiltersChange({
                ...filters,
                FinalizedAt: e.target.value,
              })
            }
          />
        </div>
        <div className="col-span-1 min-w-full">
          <TextInput
            label="Redemption Started At"
            placeholder="Redemption Started At"
            value={filters.RedemptionStartedAt}
            onChange={(e) =>
              onFiltersChange({
                ...filters,
                RedemptionStartedAt: e.target.value,
              })
            }
          />
        </div>
        <div className="col-span-1 min-w-full">
          <TextInput
            label="Payment Failed At"
            placeholder="Payment Failed At"
            value={filters.PaymentFailedAt}
            onChange={(e) =>
              onFiltersChange({
                ...filters,
                PaymentFailedAt: e.target.value,
              })
            }
          />
        </div>
        <div className="col-span-1 min-w-full">
          <TextInput
            label="Payment Transaction Id"
            placeholder="Payment Transaction Id"
            value={filters.PaymentTransactionId}
            onChange={(e) =>
              onFiltersChange({
                ...filters,
                PaymentTransactionId: e.target.value,
              })
            }
          />
        </div>
        <div className="col-span-1 min-w-full">
          <ToggleInput
            label="Show active records only"
            enabled={filters.ActiveOnly}
            setEnabled={() =>
              onFiltersChange({
                ...filters,
                ActiveOnly: !filters.ActiveOnly,
              })
            }
          />
        </div>
      </div>
    </StaticFilterSection>
  );
};

export default function GiftReporting() {
  const { downloadFileFromApiFetch } = useReportDownloadHelpers();
  const { fetch } = useApi();
  const [data, setData] = useState(null);
  const [runReport, setRunReport] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tainted, setTainted] = useState(false);
  const [filters, setFilters] = useState({
    FromName: "",
    ToName: "",
    Amount: "",
    PaidAmount: "",
    PaidAt: "",
    CancelledAt: "",
    FinalizedAt: "",
    RedemptionStartedAt: "",
    PaymentFailedAt: "",
    PaymentTransactionId: "",
    Business: "",
    FromOrganization: "",
    ActiveOnly: false,
  });

  const onFiltersChange = (updatedFilters) => {
    setTainted(true);
    setFilters((prevFilters) => ({ ...prevFilters, ...updatedFilters }));
  };

  const downloadReport = async () => {
    downloadFileFromApiFetch(
      "/report/GenerateGiftReport",
      "GiftReports.xlsx",
      filters
    );
    setTainted(false);
    setRunReport(false);
  };

  useEffect(() => {
    setRunReport(false);
  }, []);

  const executeReport = () => {
    setIsLoading(true);
    fetch("admin/giftList", {
      ...filters,
    })
      .then((res) => {
        setData(res);
        setTainted(false);
        setRunReport(true);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // const downloadSelectedReports = async () => { // selected items report
  //   downloadFileFromApiPost('/report/GenerateSelectedGiftReport', selectedGifts, "SelectedGiftReports.xlsx");
  // };

  return (
    <div className="w-100 h-auto">
      <div className="flex justify-between">
        <PageHeader title="Gift Reports" />
        {/* <PrimaryButton
          text={"Download Selected Reports"}
          onClick={downloadSelectedReports}
        /> */}
        <div className="flex flex-row justify-between">
          <PrimaryButton
            disabled={!tainted}
            onClick={executeReport}
            text={"Run Report"}
            className={"w-1/3 mr-2"}
          />
          <PrimaryButton
            onClick={downloadReport}
            text={"Download Report"}
            className={"w-1/3"}
          />
        </div>
      </div>

      <div className="w-full h-1/4 justify-center">
        <GiftReportFilterSection
          filters={filters}
          onFiltersChange={onFiltersChange}
        />
      </div>

      {runReport && !isLoading && (
        <GiftReportTable
          filters={filters}
          onFiltersChange={onFiltersChange}
          data={data}
        />
      )}
    </div>
  );
}
