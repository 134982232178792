import axios, { default as _axios } from "axios";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../context";
import storage from "../utils/storage";

const API_BASE_URL = "/api";
let attemptingRefresh = false;

async function commonHeaders(overrideToken) {
  const resolvedToken = overrideToken
    ? overrideToken
    : storage.getItem(storage.ITEMS.jwt);
  return {
    Accept: "application/json",
    Authorization: "Bearer " + resolvedToken,
  };
}

const headers = async (overrideToken) =>
  Object.assign(await commonHeaders(overrideToken), {
    "Content-Type": "application/json",
  });

const multipart_headers = async () =>
  Object.assign(await commonHeaders(), {
    "Content-Type": "multipart/form-data",
  });

const queryString = (params) => {
  const query = Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join("&");

  return `${query.length ? "?" : ""}${query}`;
};

async function renewTokens(refreshToken) {
  attemptingRefresh = true;
  return axios({
    method: "post",
    url: `${API_BASE_URL}/Public/RefreshTokens/${refreshToken}`,
    data: {},
    headers: await headers(),
  })
    .then((response) => {
      storage.setItem(storage.ITEMS.jwt, response.data.jwt);
      storage.setItem(
        storage.ITEMS.refreshToken,
        response.data.newRefreshToken
      );
      return {
        jwt: response.data.jwt,
        newRefreshToken: response.data.newRefreshToken,
      };
    })
    .catch((err) => {
      console.error("renewTokens failure", err);
    })
    .finally(() => {
      attemptingRefresh = false;
    });
}

async function retry(axiosConfig, overrideJwt) {
  return axios.request({
    ...axiosConfig,
    baseUrl: undefined,
    headers: await headers(overrideJwt),
  });
}

function useApi() {
  const navigate = useNavigate();
  function redirectToLogin() {
    useUserStore.getState().logout();
    storage.removeItem(storage.ITEMS.currentUser);
    storage.removeItem(storage.ITEMS.jwt);
    storage.removeItem(storage.ITEMS.storageCurrentUser);
    storage.removeItem(storage.ITEMS.storageJWT);
    storage.removeItem(storage.ITEMS.refreshToken);
    navigate(`/`);
  }

  const axios = _axios.create({
    baseURL: API_BASE_URL,
    timeout: 10000,
  });

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (
        !attemptingRefresh &&
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        const refreshToken = storage.getItem(storage.ITEMS.refreshToken);
        if (refreshToken) {
          return renewTokens(refreshToken)
            .then((renewResult) => {
              return retry(error.config, renewResult.jwt);
            })
            .catch((renewError) => {
              console.error("failed to renew tokens", renewError);
              redirectToLogin();
            });
        } else {
          redirectToLogin();
        }
      }

      return new Promise((_, reject) => {
        reject(error.response);
      });
    }
  );

  axios.interceptors.response.use(
    (response) => {
      const apiVersion = response.headers["x-app-version"];
      const appVersion = storage.getItem("appVersion");
      if (!appVersion) {
        storage.setItem("appVersion", apiVersion);
      }
      if (appVersion && apiVersion !== appVersion) {
        alert(
          "New app version available. Your browser will refresh automatically."
        );
        storage.setItem("appVersion", apiVersion);
        window.location.reload(true);
      }
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getUserToken = () => storage.getItem(storage.ITEMS.jwt);

  const fetch = async (url, params = {}) => {
    return axios
      .get(`${url}${queryString(params)}`, {
        headers: await headers(),
      })
      .then((res) => res.data);
  };

  const fetchBinary = (url, params = {}) => {
    return axios.get(url, { responseType: "arraybuffer" }).then((res) => {
      return res.data;
    });
  };

  const post = async (url, data) => {
    return axios({
      method: "post",
      url: `${url}`,
      data,
      timeout: 600_000,
      headers: await headers(),
    }).then((res) => res.data);
  };

  const postBinary = async (url, data) => {
    return axios({
      method: "post",
      url: url,
      data: data,
      responseType: "arraybuffer",
      timeout: 600_000,
      // headers: {
      //   ...headers,
      // },
      headers: await headers(),
    }).then((res) => res.data);
  };

  const put = async (url, data) =>
    axios
      .put(`${url}`, data, { headers: await headers() })
      .then((res) => res.data);

  const patch = async (url, data) =>
    axios
      .patch(`${url}`, data, { headers: await headers() })
      .then((res) => res.data);

  const del = async (url) =>
    axios
      .delete(`${url}`, { headers: await headers() })
      .then((res) => res.data);

  const drop = async (url, data) =>
    axios.delete(`${url}`, {
      data: data,
      headers: await headers(),
    });

  const postFormData = async (url, formData) =>
    axios
      .post(`${url}`, formData, {
        headers: await multipart_headers(),
      })
      .then((res) => res.data);

  return {
    getUserToken,
    post,
    fetch,
    put,
    patch,
    del,
    drop,
    postFormData,
    fetchBinary,
    postBinary,
  };
}

export default useApi;
