import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { Loader, Notification } from "./components/Alerts";
import { AuthLayout, PublicLayout } from "./components/Layouts";
import { useUserStore } from "./context";
import {
  Business,
  BusinessLocation,
  BusinessLocations,
  BusinessRequests,
  Businesses,
  BusinessItems,
  BusinessFollowups,
  BusinessFollowupHistories,
  BusinessFollowupHistory,
  Customer,
  CustomerLocationHistory,
  CustomerLocationHistorys,
  Customers,
  Dashboard,
  Demo,
  EmailVerification,
  ForgotPassword,
  Gift,
  Gifts,
  GiftReporting,
  GiftSummaryReport,
  Login,
  NotFound404,
  OrgRequests,
  Organization,
  Organizations,
  Profile,
  RecoBusinessReport,
  CustomMenuItemsReport,
  Register,
  RegistrationReport,
  Reporting,
  ResetPassword,
  ReviewBusinesses,
  Sessions,
  User,
  Users,
    InactiveBusinessReport,
  TermsOfService,
} from "./pages";
import { JsonTryParse } from "./utils/helpers";
import storage from "./utils/storage";

function App() {
  const { currentUser, setCurrentUser, setImpersonating } = useUserStore();
  useEffect(() => {
    setCurrentUser(JsonTryParse(storage.getItem(storage.ITEMS.currentUser)));
    setImpersonating(!!storage.getItem(storage.ITEMS.storageCurrentUser));
  }, [setCurrentUser, setImpersonating]);

  return (
    <div className="w-full h-screen mx-auto bg-backgroundColor overflow-x-hidden overflow-y-auto">
      <Notification />
      <Loader />

      {currentUser ? (
        <Routes>
          <Route element={<AuthLayout />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/users" element={<Users />} />
            <Route path="/users/:id" element={<User />} />
            <Route path="/profile/:id" element={<Profile />} />
            <Route path="/demo" element={<Demo />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/customers/:id" element={<Customer />} />
            <Route path="/business" element={<Business />} />
            <Route path="/businesses" element={<Businesses />} />
            <Route path="/businesses/:id" element={<Business />} />
            <Route path="/businessLocations" element={<BusinessLocations />} />
            <Route
              path="/businessLocations/:id"
              element={<BusinessLocation />}
            />
            <Route path="/businessItems" element={<BusinessItems />} />
            <Route path="/businessItems/:id" element={<BusinessItems />} />
            <Route path="/businessFollowups" element={<BusinessFollowups />} />
            <Route
              path="/businessFollowupHistories"
              element={<BusinessFollowupHistories />}
            />
            <Route
              path="/businessFollowupHistory"
              element={<BusinessFollowupHistory />}
            />
            <Route
              path="/customerLocationHistorys"
              element={<CustomerLocationHistorys />}
            />
            <Route
              path="/customerLocationHistorys/:id"
              element={<CustomerLocationHistory />}
            />
            <Route path="/organizations" element={<Organizations />} />
            <Route path="/organizations/:id" element={<Organization />} />
            <Route path="/reporting" element={<Reporting />} />
            <Route path="/gifts" element={<Gifts />} />
            <Route path="/gifts/:id" element={<Gift />} />
            <Route path="/giftReporting" element={<GiftReporting />} />
            <Route path="/giftSummaryReport" element={<GiftSummaryReport />} />
            <Route
              path="/recoBusinessReport"
              element={<RecoBusinessReport />}
            />
            <Route
              path="/registrationReport"
              element={<RegistrationReport />}
            />
            <Route
              path="/inactiveBusinessReport"
              element={<InactiveBusinessReport />}
            />
            <Route
              path="/CustomMenuItemsReport"
              element={<CustomMenuItemsReport />}
            />
            <Route path="*" element={<NotFound404 />} />
            <Route path="/businessRequests" element={<BusinessRequests />} />
            <Route path="/orgRequests" element={<OrgRequests />} />
            <Route path="/reviewBusinesses" element={<ReviewBusinesses />} />
            <Route path="/sessions" element={<Sessions />} />
            <Route path="/termsOfService" element={<TermsOfService />} />
            {/* Add more routes here */}
          </Route>
        </Routes>
      ) : (
        <Routes>
          <Route element={<PublicLayout />}>
            <Route path="/" element={<Login />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/resetPassword/:token" element={<ResetPassword />} />
            <Route path="/verifyEmail/:token" element={<EmailVerification />} />
            <Route path="/register" element={<Register />} />
            <Route path="/termsOfService" element={<TermsOfService />} />
            <Route path="*" element={<NotFound404 />} />
          </Route>
        </Routes>
      )}
    </div>
  );
}

export default App;
