import { useState } from "react";
import { useParams } from "react-router-dom";
import { useAlert, useApi } from "../hooks";
import { ClipLoader } from "react-spinners";
import constants from "../utils/constants";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { useEffect } from "react";
import storage from "../utils/storage";
import {useUserStore } from "../context";

export default function EmailVerification() {
  const alert = useAlert();
  const { post } = useApi();
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [emailSuccesfullyVerified, setEmailSuccessfullyVerified] = useState(false);
  const {logout } = useUserStore();

  useEffect(logout, []);
  useEffect(verifyEmail, [token])

   function verifyEmail() {
     post("public/verifyEmail", token)
      .then((res) => {
        storage.setItem(storage.ITEMS.username, res.username);
        alert("Success!", "Verified email address", "success");
        setEmailSuccessfullyVerified(true);
      })
      .catch((err) => {
        alert("Unsuccessful email verification attempt", "Cannot verify email: " + err?.data?.message, "error");
        console.error(err);
        setEmailSuccessfullyVerified(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <h1 className="w-full space -y-6 flex text-3xl font-bold text-primaryColor justify-center items-center mt-14">Email Verification</h1>
      

      <div className="w-full space-y-6 flex justify-center items-center mt-14">
        {loading ?
          <ClipLoader
            color={constants.APP_PRIMARY_COLOR}
            size={200}
            speedMultiplier={0.5}
          />
          :
          emailSuccesfullyVerified ?
            <CheckCircleIcon className="text-success w-40"/>
          :
            <XCircleIcon className="text-error w-40"/>
        }
      </div>
    </>
  );
}
