import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { PrimaryButton } from "../components/Buttons";
import { DateInput, TextInput } from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { PrimaryTable, StaticFilterSection } from "../components/Tables";
import { useAlert, useApi, useReportDownloadHelpers } from "../hooks";
import constants from "../utils/constants";
import dateHelpers from "../utils/dateHelpers";

const RecoBusinessTable = ({ filters, data }) => {
  return (
    <PrimaryTable
      totalCount={data?.totalCount}
      displayCount={constants.DISPLAY_PER_PAGE}
      header={
        <PrimaryTable.Header>
          <PrimaryTable.HeaderColumn display="Week Start (Mon)" />
          <PrimaryTable.HeaderColumn display="# of Businesess" />
        </PrimaryTable.Header>
      }
    >
      {data &&
        _.map(data, (r, index) => (
          <PrimaryTable.Row key={`${r.weekStart}-${index}`} index={index}>
            <PrimaryTable.Cell>{r.weekStart}</PrimaryTable.Cell>
            <PrimaryTable.Cell>{r.count}</PrimaryTable.Cell>
          </PrimaryTable.Row>
        ))}
    </PrimaryTable>
  );
};

const RecoBusinessFilter = ({ setFilters, filters, onFiltersChange }) => {
  const handleCurrentLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          onFiltersChange({
            ...filters,
            Latitude: latitude,
            Longitude: longitude,
          });
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.warn("geolocation not available");
    }
  };

  useEffect(handleCurrentLocation, []);

  return (
    <StaticFilterSection filters={filters} showFilterCount={false}>
      <div className="grid grid-cols-12 gap-4 w-full p-4">
        <div className="col-span-2 min-w-full">
          <TextInput
            label="Latitude"
            name="Latitude"
            placeholder="Enter latitude"
            value={filters.Latitude || ""}
            onChange={(e) =>
              onFiltersChange({
                Latitude:
                  e.target.value === "" ? null : parseFloat(e.target.value),
              })
            }
          />
          <button
            onClick={handleCurrentLocation}
            className="text-sm text-left text-blue-700 hover:text-gray-800 font-semibold py-1 ps-1 inline-flex"
          >
            Use Current Location
          </button>
        </div>
        <div className="col-span-2 min-w-full">
          <TextInput
            label="Longitude"
            name="Longitude"
            placeholder="Enter longitude"
            value={filters.Longitude || ""}
            onChange={(e) =>
              onFiltersChange({
                Longitude:
                  e.target.value === "" ? null : parseFloat(e.target.value),
              })
            }
          />
        </div>
        <div className="col-span-1 min-w-full">
          <TextInput
            label="Radius Miles"
            placeholder="Radius Miles"
            value={filters.RadiusMiles}
            onChange={(e) =>
              onFiltersChange({
                ...filters,
                RadiusMiles: e.target.value,
              })
            }
          />
        </div>
        <div className="col-span-1 min-w-full">
          <DateInput
            label="Date From"
            placeholder="Date From"
            value={filters.DateFrom}
            onChange={(e) =>
              onFiltersChange({
                ...filters,
                DateFrom: e.target.value,
              })
            }
          />
        </div>
        <div className="col-span-1 min-w-full">
          <DateInput
            label="Date Through"
            placeholder="Date Through"
            value={filters.DateThrough}
            required
            onChange={(e) =>
              onFiltersChange({
                ...filters,
                DateThrough: e.target.value,
              })
            }
          />
        </div>
        <div className="col-span-5 min-w-full">
          <PrimaryButton 
            text="Reset"
            backgroundColor="gray-500"
            className="float-right mt-5"
            small
            onClick={() => setFilters({})}                    
          />
        </div>
      </div>
      <div className="italic">
        **Download this report in order to see recommended business details.
      </div>
    </StaticFilterSection>
  );
};

export default function RecoBusinessReport() {
  const { downloadFileFromApiPost } = useReportDownloadHelpers();
  const { post } = useApi();
  const alert = useAlert();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tainted, setTainted] = useState(true);
  const [filters, setFilters] = useState({
    Latitude: null,
    Longitude: null,
    RadiusMiles: 10,
    DateFrom: moment().subtract(1, "month").format(dateHelpers.YMD),
    DateThrough: moment().format(dateHelpers.YMD),
  });

  const onFiltersChange = (updatedFilters) => {
    setTainted(true);
    setFilters((prevFilters) => ({ ...prevFilters, ...updatedFilters }));
  };

  function getValidationWarnings() {
    let warnings = [];
    if (!dateHelpers.verifyDate(filters.DateThrough)) {
      warnings.push("Provide a date through.");
    }
    return warnings;
  }

  function validate() {
    const warnings = getValidationWarnings();
    if (warnings.length) {
      alert(warnings.join(", "), "Please try again", "error");
      return false;
    }
    return true;
  }

  function buildPayload() {
    return {
      DateFrom: filters.DateFrom ? filters.DateFrom : null,
      DateThrough: filters.DateThrough,
      RadiusMiles: filters.RadiusMiles,
      ReferenceLocation: {
        Latitude: filters.Latitude || 0,
        Longitude: filters.Longitude || 0,
      },
    };
  }

  const downloadReport = async () => {
    if (!validate()) return;
    downloadFileFromApiPost(
      "/report/GenerateRecommendedBusinessReport",
      buildPayload(),
      "RecommendedBusinessReport.xlsx"
    );
  };

  const executeReport = () => {
    if (!validate()) return;
    setIsLoading(true);
    post("report/RecommendedBusinessReport", buildPayload())
      .then((res) => {
        setData(res);
        setTainted(false);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="w-100 h-auto">
      <div className="flex justify-between">
        <PageHeader title="Recommended Business Report" />
        <div className="flex flex-row justify-between">
          <PrimaryButton
            disabled={!tainted}
            onClick={executeReport}
            text={"Run Report"}
            className="w-1/3 mr-2"
          />
          <PrimaryButton
            onClick={downloadReport}
            //backgroundColor="cyan-950"
            text={"Download Report"}
            className="w-1/3"
          />
        </div>
      </div>

      <div className="w-full h-1/4 justify-center">
        <RecoBusinessFilter
          setFilters={setFilters}
          filters={filters}
          onFiltersChange={onFiltersChange}
        />
      </div>

      {data && !tainted && !isLoading && (
        <RecoBusinessTable
          filters={filters}
          onFiltersChange={onFiltersChange}
          data={data}
        />
      )}
    </div>
  );
}
