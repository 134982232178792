import { LOGO } from "../assets/images";

const APP_NAME = "Klink-iT!";
const APP_VERSION = "0.5";
const APP_PRIMARY_COLOR = "#0B7084";
const APP_SLOGAN =
  "Hi, I'm Klink-iT!, a new app coming your way from W-S and Clemmons.";
const APP_LOGO = LOGO;

const SORT_DIRECTIONS = {
  ASCENDING: "ASC",
  DESCENDING: "DESC",
};

const DISPLAY_PER_PAGE = 25;

const ROLE_IDS = { SYSTEM_ADMIN: 1, ADMIN: 2, REPORTS: 3 };

const ROLES = [
  { label: "System Administrator", value: 1 },
  { label: "Administrator", value: 2 },
  { label: "View Reports", value: 3 },
  //{ label: "Patron", value: 4 },
];

const BUSINESS_ORG_ROLES = [
  { label: "Administrator", value: 2, sublabel: "Manage Gifts and Employees" },
  { label: "Reporter", value: 3, sublabel: "View Reports" },
];

const NOTIFICATION_TYPES = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
};

const SETTING_STATUSES = [
  { label: "No One", value: 1 },
  { label: "Following & Followed", value: 2 },
  { label: "Everyone", value: 3 },
];

const CANCEL_REASONS = [
  { label: "Receiver Cancelled", value: 1 },
  { label: "Server Cancelled", value: 2 },
  { label: "Transaction Timed Out", value: 3 },
  { label: "Sender Cancelled", value: 4 },
  { label: "Business Deleted", value: 5 },
  { label: "Sender Deleted", value: 6 },
  { label: "Admin Cancelled", value: 7 },
];

const CANCEL_REASON_IDS = {
  RECEIVER: 1,
  SERVER: 2,
  TIMED_OUT: 3,
  SENDER_CANCEL: 4,
  BUSINESS: 5,
  SENDER_DELETE: 6,
  ADMIN: 7,
};

const CUSTOMER_TYPES = [
  { label: "Patron", value: 1 },
  { label: "Organization", value: 2 },
];

const MARRIAGE_STATUSES = [
  { label: "Single", value: 1 },
  { label: "Married", value: 2 },
  { label: "Divorced", value: 3 },
  { label: "Separated", value: 4 },
  { label: "Widowed", value: 5 },
  { label: "N/A", value: 6 },
];

const BUSINESS_STATUSES = {
  DRAFT: 1,
  PENDING: 2,
  APPROVED: 3,
  REJECTED: 4,
};

const MIME_TYPES = {
  XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  XLS: "application/vnd.ms-excel",
  PDF: "application/pdf",
  JPEG: "image/jpeg",
  PNG: "image/png",
  GIF: "image/gif",
};
// add constants here

const constants = {
  APP_NAME,
  APP_VERSION,
  APP_SLOGAN,
  APP_PRIMARY_COLOR,
  APP_LOGO,
  DISPLAY_PER_PAGE,
  NOTIFICATION_TYPES,
  SORT_DIRECTIONS,
  ROLES,
  SETTING_STATUSES,
  CANCEL_REASONS,
  CANCEL_REASON_IDS,
  CUSTOMER_TYPES,
  MARRIAGE_STATUSES,
  ROLE_IDS,
  MIME_TYPES,
  BUSINESS_ORG_ROLES,
  BUSINESS_STATUSES,
  // add exports here
};

export default constants;
