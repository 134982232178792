import _ from "lodash";
import moment from "moment";
import React, { 
  // useEffect, 
  useState 
} from "react";
import { PrimaryButton } from "../components/Buttons";
import { DateInput, ToggleInput } from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { PrimaryTable, StaticFilterSection } from "../components/Tables";
import { useApi, useReportDownloadHelpers } from "../hooks";
import constants from "../utils/constants";
import {formatCurrency, convertPenniesToDollars} from "../utils/helpers";
import dateHelpers from "../utils/dateHelpers";
import { useUserStore } from "../context";

const GiftSummaryTable = ({ filters, data }) => {
  return (
    <>
      <PrimaryTable
        totalCount={data?.totalCount}
        displayCount={constants.DISPLAY_PER_PAGE}
        header={
          <PrimaryTable.Header>
            <PrimaryTable.HeaderColumn display="Business name" />
            <PrimaryTable.HeaderColumn display="Gift amount" />
            <PrimaryTable.HeaderColumn display="Gift count" />
            <PrimaryTable.HeaderColumn display="Gift item count" />
            <PrimaryTable.HeaderColumn display="Avg gift amount" />
            <PrimaryTable.HeaderColumn display="Avg items per gift" />
          </PrimaryTable.Header>
        }
      >
        {data &&
          _.map(data, (r, index) => (
            <PrimaryTable.Row key={`${r.BusinessName}-${index}`} index={index}>
              <PrimaryTable.Cell>{r.businessName}</PrimaryTable.Cell>
              <PrimaryTable.Cell className="text-right">{formatCurrency(r.giftAmountDollars)}</PrimaryTable.Cell>
              <PrimaryTable.Cell>{r.giftCount}</PrimaryTable.Cell>
              <PrimaryTable.Cell>{r.giftItemCount}</PrimaryTable.Cell>
              <PrimaryTable.Cell className="text-right">{formatCurrency(convertPenniesToDollars(r.averageGiftAmount))}</PrimaryTable.Cell>
              <PrimaryTable.Cell>{r.averageGiftItemsPerGift}</PrimaryTable.Cell>
            </PrimaryTable.Row>
          ))}
      </PrimaryTable>
    </>
  );
};

const GiftSummaryFilterSection = ({ setFilters, filters, onFiltersChange }) => {
  // const handleCurrentLocation = () => {
  //   if ("geolocation" in navigator) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         const { latitude, longitude } = position.coords;
  //         onFiltersChange({
  //           ...filters,
  //           Latitude: latitude,
  //           Longitude: longitude,
  //         });
  //       },
  //       (error) => {
  //         console.error(error);
  //       }
  //     );
  //   } else {
  //     console.warn("geolocation not available");
  //   }
  // };

  // useEffect(handleCurrentLocation, []);

  return (
    <StaticFilterSection filters={filters} showFilterCount={false}>
      <div className="grid grid-cols-12 gap-4 w-full p-4">
{/*        <div className="col-span-2 min-w-full">
          <TextInput
            label="Latitude"
            name="Latitude"
            placeholder="Enter latitude"
            value={filters.Latitude || ""}
            onChange={(e) =>
              onFiltersChange({
                Latitude:
                  e.target.value === "" ? null : parseFloat(e.target.value),
              })
            }
          />

          <button
            onClick={handleCurrentLocation}
            className="text-sm text-blue-700 hover:text-gray-800 font-semibold py-2 px-4 rounded inline-flex items-center"
          >
            Use Current Location
          </button>
        </div>
        <div className="col-span-2 min-w-full">
          <TextInput
            label="Longitude"
            name="Longitude"
            placeholder="Enter longitude"
            value={filters.Longitude || ""}
            onChange={(e) =>
              onFiltersChange({
                Longitude:
                  e.target.value === "" ? null : parseFloat(e.target.value),
              })
            }
          />
        </div>
        <div className="col-span-1 min-w-full">
          <TextInput
            label="Radius Miles"
            placeholder="Radius Miles"
            value={filters.RadiusMiles}
            onChange={(e) =>
              onFiltersChange({
                ...filters,
                RadiusMiles: e.target.value,
              })
            }
          />
        </div>
        */}
        <div className="col-span-2 min-w-full">
          <DateInput
            label="Date From"
            placeholder="Date From"
            value={filters.DateFrom}
            onChange={(e) =>
              onFiltersChange({
                ...filters,
                DateFrom: e.target.value,
              })
            }
          />
        </div>
        <div className="col-span-2 min-w-full">
          <DateInput
            label="Date Through"
            placeholder="Date Through"
            value={filters.DateThrough}
            required={true}
            onChange={(e) =>
              onFiltersChange({
                ...filters,
                DateThrough: e.target.value,
              })
            }
          />
        </div>
        <div className="col-span-1 min-w-full ml-4">
          <ToggleInput
            label="Redeemed Only"
            enabled={filters.RedeemedOnly}
            setEnabled={(e) =>
              onFiltersChange({
                ...filters,
                RedeemedOnly: !filters.RedeemedOnly,
              })
            }
          />
        </div>
        <div className="col-span-1 min-w-full">
          <ToggleInput
            label="Unredeemed Only"
            enabled={filters.UnredeemedOnly}
            setEnabled={(e) =>
              onFiltersChange({
                ...filters,
                UnredeemedOnly: !filters.UnredeemedOnly,
              })
            }
          />
        </div>
        <div className="col-span-6 min-w-full">
          <PrimaryButton 
            text="Reset"
            backgroundColor="gray-500"
            className="float-right mt-3"
            small
            onClick={() => setFilters({})}                    
          />
        </div>
      </div>
    </StaticFilterSection>
  );
};

export default function GiftSummaryReport() {
  const { downloadFileFromApiPost } = useReportDownloadHelpers();
  const { post } = useApi();
  const { currentUser } = useUserStore();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tainted, setTainted] = useState(true);
  const [filters, setFilters] = useState({
    // Latitude: null,
    // Longitude: null,
    // RadiusMiles: 10,
    // BusinessIdList: [],
    // FromUserId: null,
    // ToUserId: null,
    RedeemedOnly: false,
    UnredeemedOnly: false,
    DateFrom: moment().subtract(1, "month").format(dateHelpers.YMD),
    DateThrough: moment().format(dateHelpers.YMD),
  });

  const isSysAdmin = currentUser.roles.some((role) => role.typeOfRole === constants.ROLE_IDS.SYSTEM_ADMIN);
  const isBusinessAdmin = currentUser.roles.some((role) => role.typeOfRole === constants.ROLE_IDS.ADMIN) 
    && currentUser.businessIdList.length > 0;
  const isOrgAdmin = currentUser.roles.some((role) => role.typeOfRole === constants.ROLE_IDS.ADMIN)
    && currentUser.organizationIdList.length > 0;

  const onFiltersChange = (updatedFilters) => {
    setTainted(true);
    setFilters((prevFilters) => {
      if (prevFilters.RedeemedOnly && updatedFilters.UnredeemedOnly) {
        updatedFilters.RedeemedOnly = false;
      } else if (prevFilters.UnredeemedOnly && updatedFilters.RedeemedOnly) {
        updatedFilters.UnredeemedOnly = false;
      }
      if (moment(updatedFilters.DateThrough).isAfter(moment())) {
        updatedFilters.DateThrough = moment().format(dateHelpers.YMD);
      }
      return ({ ...prevFilters, ...updatedFilters });
    });
  };

  function getValidationWarnings() {
    let warnings = [];
    if (!dateHelpers.isDateValid(filters.DateThrough, dateHelpers.YMD)) {
      warnings.push("Provide a date through.");
    }
    return warnings;
  }

  function validate() {
    const warnings = getValidationWarnings();
    if (warnings.length) {
      alert(warnings.join(", "), "Please try again", "error");
      return false;
    }
    return true;
  }

  function validateUserRole() {
    if (isSysAdmin) return true;

    let updatedFilters = { ...filters };

    if (isBusinessAdmin) {
      if (!updatedFilters.BusinessIdList || updatedFilters.BusinessIdList.length === 0) {
        updatedFilters.BusinessIdList = currentUser.businessIdList;
      }
    } else if (isOrgAdmin) {
      if (!updatedFilters.OrganizationIdList || updatedFilters.OrganizationIdList.length === 0) {
        updatedFilters.OrganizationIdList = currentUser.organizationIdList;
      }
    } else {
      return false;
    }

    setFilters(updatedFilters);
    return true;
  }

  function buildPayload() {
    return {
      BusinessIdList: validateUserRole() ? filters.BusinessIdList : [],
      RedeemedOnly: filters.RedeemedOnly,
      UnredeemedOnly: filters.UnredeemedOnly,
      DateFrom: filters.DateFrom ? filters.DateFrom : null,
      DateThrough: filters.DateThrough,
      RadiusMiles: filters.RadiusMiles,
      ReferenceLocation: {
        Latitude: filters.Latitude || 0,
        Longitude: filters.Longitude || 0,
      },
    };
  }

  const downloadReport = async () => {
    if (!validate()) return;
    downloadFileFromApiPost(
      "/report/GenerateGiftSummaryReport",
      buildPayload(),
      "GiftSummaryReport.xlsx"
    );
  };

  const executeReport = () => {
    if (!validate()) return;
    setIsLoading(true);
    post("report/GiftSummaryReport", buildPayload())
      .then((res) => {
        setData(res);
        setTainted(false);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="w-100 h-auto">
      <div className="flex justify-between">
        <PageHeader title="Gift Summary Report" />
        <div className="flex flex-row justify-between">
          <PrimaryButton
            disabled={!tainted || getValidationWarnings().length}
            onClick={executeReport}
            text={"Run Report"}
            className={"w-1/3 mr-2"}
          />
          <PrimaryButton
            onClick={downloadReport}
            text={"Download Report"}
            className={"w-1/3"}
          />
        </div>
      </div>

      <div className="w-full h-1/4 justify-center">
        <GiftSummaryFilterSection
          filters={filters}
          onFiltersChange={onFiltersChange}
          setFilters={setFilters}
        />
      </div>

      {data && !tainted && !isLoading && (
        <GiftSummaryTable
          filters={filters}
          onFiltersChange={onFiltersChange}
          data={data}
        />
      )}
    </div>
  );
}
