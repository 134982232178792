import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { CheckIcon, Square2StackIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import { ModalWrapper } from ".";
import { PrimaryButton } from "../Buttons";
import { TextInput } from "../Inputs";

export default function RejectionModal({
  modalOpen,
  title,
  message,
  maxLength,
  placeholder,
  confirmButtonText,
  getInput,
  Icon = Square2StackIcon,
  setModalOpen,
  onConfirm,
}) {
  const [userInput, setUserInput] = useState("");
  const [userInputExists, setUserInputExists] = useState(false);

  useEffect(() => {
    if (userInput.length > 0){
      setUserInputExists(true);
      getInput(userInput);}
    else {setUserInputExists(false);}
  }, [userInput]);

  return (
    <ModalWrapper open={modalOpen} setOpen={setModalOpen}>
      <div className="w-[85vw] lg:w-[35vw]">
        <div className="pb-4 text-center flex items-center flex-col">
          <Icon className="w-5 text-gray-500" />
          {title}
        </div>
        {message && (
          <div className="pb-8 mx-6 text-center text-sm text-gray-500">
            {message}
          </div>
        )}

        <div className="mb-8">
          <TextInput
            placeholder={placeholder}
            maxLength={maxLength}
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            iconColor={userInputExists ? "success" : "notification"}
            Icon={userInputExists ? CheckIcon : ExclamationTriangleIcon}
          />
        </div>

        <div className="mt-3">
          <PrimaryButton
            text={confirmButtonText}
            backgroundColor="delete"
            onClick={onConfirm}
            disabled={!userInputExists}
          />
        </div>
      </div>
    </ModalWrapper>
  );
}
