import { Popover } from "@headlessui/react";
import { Filter } from "react-bootstrap-icons";
import { TextInput, ToggleInput } from "../Inputs";
import { countDefinedKeys } from "../../utils/helpers";

export default function FilterSection({ filters, children }) {

  let filterCount = countDefinedKeys(filters);

  return (
    <div className="mb-2">
      <div className="flex"></div>
      <Popover as="div" className="relative ml-3">
        {({ open }) => (
          <>
            <Popover.Button className="flex relative focus:outline-none">
              <div className="flex text-darkerBlue cursor-pointer hover:gray-400">
                <Filter title="Show filters" size={22} />
                <div className="ml-2">
                  {filterCount} filter{filterCount === 1 ? "" : "s"} active
                </div>
              </div>
            </Popover.Button>
            <Popover.Panel
              as="div"
              className="absolute z-50 w-full sm:w-1/3 origin-bottom-right bg-white rounded-md p-4 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none mt-1"
            >
             {children}
            </Popover.Panel>
          </>
        )}
      </Popover>
    </div>
  );
}
