import { Outlet } from "react-router-dom";
import { useLayoutStore } from "../../context";
import { classNames } from "../../utils/helpers";
import { SideNavMenu, TopNavMenu } from "../Menus";

export default function AuthLayout() {
  const [sidebarMenuOpen] = useLayoutStore((state) => [state.sidebarMenuOpen]);

  return (
    <div className="h-screen overflow-x-auto">
      <TopNavMenu />
      <SideNavMenu />
      <div className={classNames(sidebarMenuOpen ? "ml-64" : "ml-20", "mt-16 p-4")}>
        <Outlet />
      </div>
    </div>
  );
}
