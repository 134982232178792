import { ArrowLongLeftIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router";

export default function GoBack({ display = "Go back", navigateTo }) {
  const navigate = useNavigate();

  return (
    <div className="flex cursor-pointer hover:opacity-70 text-primaryColor my-2" onClick={() => navigate(navigateTo || -1)}>
      <ArrowLongLeftIcon className="w-5 mr-2" />
      {display}
    </div>
  );
}
