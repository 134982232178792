import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import _ from "lodash";
import { GoBack, PrimaryButton } from "../components/Buttons";
import { CollapsibleSection } from "../components/Cards";
import {
  PhoneInput,
  TextInput,
  NumberInput,
  CheckboxInput,
  ToggleInput,
  TextAreaInput,
} from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { useAlert, useApi, useDebounce } from "../hooks";
import constants from "../utils/constants";
import AsyncSelect from "react-select/async";
import { PrimaryTable } from "../components/Tables";
import { switchSortDirection } from "../utils/helpers";
import { PlusCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import dateHelpers from "../utils/dateHelpers";
import {
  useLoaderStore, // , useUserStore
} from "../context";

export default function Business() {
  // const { currentUser} = useUserStore();
  let { id } = useParams();
  const navigate = useNavigate();
  const alert = useAlert();
  const isCreatingNew = id === "0";
  const { fetch, post, drop } = useApi();
  const { setShowLoader } = useLoaderStore();
  const [options, setOptions] = useState();
  const [business, setBusiness] = useState({ id });
  const [inputValue, setInputValue] = useState("");
  const [addAdmin, setAddAdmin] = useState(false);
  const [addReporter, setAddReporter] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("id");
  const [sortDirection, setSortDirection] = useState(
    constants.SORT_DIRECTIONS.ASCENDING
  );
  const [displayCount, setDisplayCount] = useState(
    constants.DISPLAY_PER_PAGE ?? 25
  );
  const { debounced: debouncedFilters } = useDebounce({ toDebounce: filters });
  const { debounced: debouncedDisplayCount } = useDebounce({
    toDebounce: displayCount,
  });
  const { data, isLoading } = useSWR(
    !isCreatingNew ? `business/businessInfo/${id}` : null,
    fetch,
    {
      revalidateOnFocus: false,
      onSuccess: () => setShowLoader(false),
      onError: () => setShowLoader(false),
    }
  );

  const { data: businessUserData, mutate } = useSWR(
    [
      "business/BusinessUserlist",
      currentPage,
      debouncedDisplayCount,
      debouncedFilters,
      sortDirection,
      sortField,
    ],
    ([
      url,
      currentPage,
      debouncedDisplayCount,
      debouncedFilters,
      sortDirection,
      sortField,
    ]) =>
      post(url, {
        id: id,
        filters: {
          pageNumber: currentPage,
          pageSize: debouncedDisplayCount,
          sortDirection,
          sortField,
          ...debouncedFilters,
        },
      })
  );

  useEffect(() => {
    if (!isCreatingNew && !data) setShowLoader(true);
  }, [isCreatingNew, data]);

  useEffect(() => {
    if (!data) return;
    setBusiness(data);
  }, [data]);

  useEffect(() => {
    setCurrentPage(1);
    setDisplayCount(25);
  }, [filters]);

  const handleChange = (property, value) => {
    setBusiness({
      ...business,
      [property]: value,
    });
  };

  const handleSave = () => {
    if (!isValid()) return;

    setShowLoader(true);
    post("business/saveBusiness/false", business)
      .then((res) => {
        alert("Success", "Saved business", "success");
        navigate("/businesses");
      })
      .catch((err) => {
        alert(
          "Unable to save business",
          err.data.message || "Please try again",
          "error"
        );
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };
  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(switchSortDirection(sortDirection));
    } else {
      setSortField(field);
    }
  };

  function removeUserFromRole(role, contextName = "Business") {
    setShowLoader(true);
    drop("User/RemoveRole", { userId: role.userId, referenceId: role.id })
      .then((res) => {
        alert("Success", "User removed from " + contextName, "success");
        mutate();
      })
      .finally(() => setShowLoader(false));
  }

  const isValid = () => {
    if (!business.name || !_.trim(business.name)) {
      alert("Form invalid", "Name must have a value", "warning");
      return false;
    }
    if (!business.ein || !_.trim(business.ein)) {
      alert("Form invalid", "E I N must have a value", "warning");
      return false;
    }
    if (!business.email || !_.trim(business.email)) {
      alert("Form invalid", "Email must have a value", "warning");
      return false;
    }
    return true;
  };

  function loadOptions(inputValue, callback) {
    if (!inputValue || !inputValue.length || inputValue.length < 3) return;
    fetch("user/List", { name: inputValue }).then((res) => {
      setOptions(
        _.map(res.list, (item) => ({
          value: item.id,
          label: `${item.firstName} ${item.lastName}`,
        }))
      );
      callback(options);
    });
  }

  const addUser = (admin, reporter) => {
    const rolesToAdd = [];
    if (admin) {
      rolesToAdd.push(constants.ROLE_IDS.ADMIN);
    }
    if (reporter) {
      rolesToAdd.push(constants.ROLE_IDS.REPORTS);
    }
    if (!rolesToAdd.length) {
      alert("Invalid input", "Select at least 1 role to add", "warning");
      return;
    }
    if (!selectedUser) {
      alert(
        "Invalid input",
        "Select a user context to go along with the roles selected",
        "warning"
      );
      return;
    }
    setShowLoader(true);
    post("User/AddBusinessAccess", {
      userId: selectedUser.value,
      referenceId: business.id,
      roles: rolesToAdd,
    })
      .then((res) => {
        setInputValue("");
        alert("Success", "User roles added to business", "success");
        mutate();
      })
      .finally(() => setShowLoader(false));
  };

  // const UserFilterSection = ({ filters, setFilters }) => {
  //   return (
  //     <FilterSection filters={filters}>
  //       <div>

  //         <div className="mb-2">
  //           <TextInput
  //             label="Id"
  //             placeholder="Id"
  //             value={filters.id}
  //             onChange={(e) =>
  //               setFilters({
  //                 ...filters,
  //                 id: e.target.value,
  //               })
  //             }
  //           />
  //         </div>
  //         <div className="mb-2">
  //           <TextInput
  //             label="First Name"
  //             placeholder="First Name"
  //             value={filters.firstName}
  //             onChange={(e) =>
  //               setFilters({
  //                 ...filters,
  //                 firstName: e.target.value,
  //               })
  //             }
  //           />
  //         </div>
  //         <div className="mb-2">
  //           <TextInput
  //             label="Last Name"
  //             placeholder="Last Name"
  //             value={filters.lastName}
  //             onChange={(e) =>
  //               setFilters({
  //                 ...filters,
  //                 lastName: e.target.value,
  //               })
  //             }
  //           />
  //         </div>

  //         <div className="mb-2">
  //           <TextInput
  //             label="Username"
  //             placeholder="Username"
  //             value={filters.username}
  //             onChange={(e) =>
  //               setFilters({
  //                 ...filters,
  //                 username: e.target.value,
  //               })
  //             }
  //           />
  //         </div>
  //       </div>
  //     </FilterSection>
  //   );
  // };

  return (
    <div>
      {/* Header */}
      <PageHeader
        title={
          isCreatingNew || !business
            ? "Create new business"
            : `Manage business ${business.name}`
        }
      />
      <GoBack display="Manage all businesses" navigateTo="/businesses" />

      <div className="bg-gray-100 shadow-md rounded-md shadow-primaryColor/25">
        {/* Form */}
        <CollapsibleSection title="Info">
          <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4">
            <div>
              <TextInput
                label="Name"
                value={business?.name ?? ""}
                onChange={(e) => handleChange("name", e.target.value)}
                required
                maxLength={50}
              />
            </div>
            <div>
              <TextInput
                label="E I N"
                value={business?.ein ?? ""}
                onChange={(e) => handleChange("ein", e.target.value)}
                required
                maxLength={10}
              />
            </div>
            <div>
              <TextInput
                label="Email"
                value={business?.email ?? ""}
                onChange={(e) => handleChange("email", e.target.value)}
                required
                maxLength={150}
              />
            </div>
            <div>
              <PhoneInput
                label="Phone"
                value={business?.phone ?? ""}
                onChange={(e) => handleChange("phone", e)}
              />
            </div>
            <div>
              <TextInput
                label="Timezone"
                value={business?.timezone ?? ""}
                onChange={(e) => handleChange("timezone", e.target.value)}
                maxLength={30}
              />
            </div>
            <div>
              <TextInput
                label="Address Line 1"
                value={business?.address1 ?? ""}
                onChange={(e) => handleChange("address1", e.target.value)}
                maxLength={30}
              />
            </div>
            <div>
              <TextInput
                label="Address Line 2"
                value={business?.address2 ?? ""}
                onChange={(e) => handleChange("address2", e.target.value)}
                maxLength={30}
              />
            </div>
            <div>
              <TextInput
                label="Address Line 3"
                value={business?.address3 ?? ""}
                onChange={(e) => handleChange("address3", e.target.value)}
                maxLength={30}
              />
            </div>
            <div>
              <TextInput
                label="City"
                value={business?.city ?? ""}
                onChange={(e) => handleChange("city", e.target.value)}
                maxLength={30}
              />
            </div>
            <div>
              <TextInput
                label="State"
                value={business?.state ?? ""}
                onChange={(e) => handleChange("state", e.target.value)}
                maxLength={2}
              />
            </div>
            <div>
              <TextInput
                label="Country"
                value={business?.country ?? ""}
                onChange={(e) => handleChange("country", e.target.value)}
                maxLength={30}
              />
            </div>
            <div>
              <TextInput
                label="Postal Code"
                value={business?.postalCode ?? ""}
                onChange={(e) => handleChange("postalCode", e.target.value)}
                maxLength={30}
              />
            </div>
            <div>
              <TextInput
                label="County"
                value={business?.county ?? ""}
                onChange={(e) => handleChange("county", e.target.value)}
                maxLength={30}
              />
            </div>
            <div>
              <NumberInput
                label="Latitude"
                value={business?.latitude ?? 0}
                onChange={(e) => {
                  let value = e.target.value;
                  if (value > 90) value = 90;
                  else if (value < -90) value = -90;
                  handleChange("latitude", value);
                }}
                max={90}
                min={-90}
              />
            </div>
            <div>
              <NumberInput
                label="Longitude"
                value={business?.longitude ?? 0}
                onChange={(e) => {
                  let value = e.target.value;
                  if (value > 180) value = 180;
                  else if (value < -180) value = -180;
                  handleChange("longitude", value);
                }}
                max={180}
                min={-180}
              />
            </div>
            <div>
              <ToggleInput
                label="Unmapped Location"
                enabled={business?.nonStandardLocation ?? false}
                setEnabled={(e) => handleChange("nonStandardLocation", e)}
              />
            </div>
            <div>
              <TextAreaInput
                label="Biography"
                value={business?.biography ?? ""}
                onChange={(e) => handleChange("biography", e.target.value)}
                maxLength={1000}
              />
            </div>
            {/* <div>
              <SelectInput
                label="Last Updated By User"
                options={convertToLabelValueList(lastUpdatedByUserData)}
                onClear={() => handleChange("lastUpdatedByUserId", undefined)}
                value={convertToLabelValue(lastUpdatedByUserData?.find(x => x.id === business?.lastUpdatedByUserId))}
                onChange={(lastUpdatedByUser) => handleChange("lastUpdatedByUserId", lastUpdatedByUser.value)}
                isSearchable
              />
            </div> */}
          </div>
          {isCreatingNew ? null : (
            <CollapsibleSection title="Business Users">
              <div className="p-4 grid grid-cols-12 gap-x-4 gap-y-4">
                <div className="col-span-7 md:col-span-4">
                  <AsyncSelect
                    loadOptions={loadOptions}
                    onInputChange={(value) => setInputValue(value)}
                    onChange={setSelectedUser}
                    inputValue={inputValue}
                    value={selectedUser}
                    placeholder={"Type to search..."}
                  />
                </div>
                <div className="col-span-3 md:col-span-2">
                  <CheckboxInput
                    label="Administrator"
                    checked={addAdmin}
                    setChecked={setAddAdmin}
                  />
                  <CheckboxInput
                    label="Reporter"
                    checked={addReporter}
                    setChecked={setAddReporter}
                  />
                </div>
                <div className="col-span-2">
                  <PlusCircleIcon
                    title="Add User Roles"
                    className="float-left w-8 mx-1 mr-2 text-white bg-klinkYellow rounded-md cursor-pointer"
                    onClick={() => addUser(addAdmin, addReporter)}
                  />
                  <div
                    className="float-left mt-1 cursor-pointer"
                    onClick={() => addUser(addAdmin, addReporter)}
                  >
                    Add Roles
                  </div>
                </div>
              </div>
              {businessUserData?.list?.length ? (
                <PrimaryTable
                  isLoading={isLoading}
                  filters={filters}
                  totalCount={businessUserData?.totalCount}
                  displayCount={displayCount}
                  currentPage={currentPage}
                  setDisplayCount={setDisplayCount}
                  setCurrentPage={setCurrentPage}
                  header={
                    <PrimaryTable.Header>
                      <PrimaryTable.HeaderColumn
                        display="First Name"
                        value={filters.firstName}
                        isSortingBy={sortField === "firstName"}
                        sortDirection={sortDirection}
                        onSort={() => handleSort("firstName")}
                        onClear={() =>
                          setFilters({ ...filters, firstName: "" })
                        }
                        onType={(e) =>
                          setFilters({
                            ...filters,
                            firstName: e.target.value,
                          })
                        }
                      />
                      <PrimaryTable.HeaderColumn
                        display="Last Name"
                        value={filters.lastName}
                        isSortingBy={sortField === "lastName"}
                        sortDirection={sortDirection}
                        onSort={() => handleSort("lastName")}
                        onClear={() => setFilters({ ...filters, lastName: "" })}
                        onType={(e) =>
                          setFilters({
                            ...filters,
                            lastName: e.target.value,
                          })
                        }
                      />
                      <PrimaryTable.HeaderColumn
                        display="User Role"
                        value={filters.userRole}
                        isSortingBy={sortField === "User Role"}
                        sortDirection={sortDirection}
                        onSort={() => handleSort("User Role")}
                        onType={(e) =>
                          setFilters({ ...filters, userRole: e.target.value })
                        }
                        onClear={() => setFilters({ ...filters, userRole: "" })}
                      />

                      <PrimaryTable.HeaderColumn
                        display="Username"
                        value={filters.username}
                        isSortingBy={sortField === "username"}
                        sortDirection={sortDirection}
                        onSort={() => handleSort("username")}
                        onClear={() => setFilters({ ...filters, username: "" })}
                        onType={(e) => {
                          setFilters({
                            ...filters,
                            username: e.target.value,
                          });
                        }}
                      />
                      <PrimaryTable.HeaderColumn display="Last Logged On" />
                      <PrimaryTable.HeaderColumn display="" />
                    </PrimaryTable.Header>
                  }
                >
                  {businessUserData &&
                    businessUserData.list &&
                    _.map(businessUserData.list, (x, i) =>
                      x.roles.map((role, j) => (
                        <PrimaryTable.Row key={`${x.id}-${i}-${j}`} index={i}>
                          <PrimaryTable.Cell>{x.firstName}</PrimaryTable.Cell>
                          <PrimaryTable.Cell>{x.lastName}</PrimaryTable.Cell>
                          <PrimaryTable.Cell>{role.roleName}</PrimaryTable.Cell>
                          <PrimaryTable.Cell>{x.username}</PrimaryTable.Cell>
                          <PrimaryTable.Cell>
                            {dateHelpers.getRelativeTime(x.lastLoggedOnAt)}
                          </PrimaryTable.Cell>
                          <PrimaryTable.Cell>
                            <div className="flex">
                              <XMarkIcon
                                title="Deactivate"
                                className="w-6 mx-1 text-white bg-delete rounded-md p-0.5 cursor-pointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  removeUserFromRole(role);
                                }}
                              />
                            </div>
                          </PrimaryTable.Cell>
                        </PrimaryTable.Row>
                      ))
                    )}
                </PrimaryTable>
              ) : (
                <div className="px-4 pt-2 font-semibold italic">
                  No business users are associated.
                </div>
              )}
            </CollapsibleSection>
          )}
          <div className="flex justify-end">
            <div className="w-1/5">
              <PrimaryButton
                text={isCreatingNew ? "Create" : "Save"}
                backgroundColor="green"
                onClick={handleSave}
              />
            </div>
          </div>
        </CollapsibleSection>
      </div>
    </div>
  );
}
