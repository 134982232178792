export default function SecondaryHeader({
  title, className
}) {

  return(
    <div className={className}>
      <h5 className="text-xl w-full py-2 text-primaryColor-500">{title}</h5>
      <hr/>
    </div>
  );
}