import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { GoBack, PrimaryButton } from "../components/Buttons";
import { CollapsibleSection } from "../components/Cards";
import { PhoneInput, TextInput, NumberInput, ToggleInput, TextAreaInput } from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { useLoaderStore } from "../context";
import { useAlert, useApi } from "../hooks";

export default function Gift() {
  let { id } = useParams();
  const navigate = useNavigate();
  const alert = useAlert();
  const isCreatingNew = id === "0";
  const { fetch, post } = useApi();
  const { setShowLoader } = useLoaderStore();
  const [business, setBusiness] = useState({ id });

  const { data } = useSWR(
    !isCreatingNew ? `business/businessInfo/${id}` : null,
    fetch,
    {
      revalidateOnFocus: false,
      onSuccess: () => setShowLoader(false),
      onError: () => setShowLoader(false),
    }
  );

  // const {data: createdByUserData } = useSWR(
  //   "user/getusersSimple",
  //   (url) => fetch(url, { sortField: "" }),
  // );
  // const {data: lastUpdatedByUserData } = useSWR(
  //   "user/getusersSimple",
  //   (url) => fetch(url, { sortField: "" }),
  // );
  // const {data: deactivatedByUserData } = useSWR(
  //   "user/getusersSimple",
  //   (url) => fetch(url, { sortField: "" }),
  // );

  useEffect(() => {
    if (!isCreatingNew && !data) setShowLoader(true);
  }, [isCreatingNew, data]);

  useEffect(() => {
    if (!data) return;

    setBusiness(data);
  }, [data]);


  const handleChange = (property, value) => {
    setBusiness({
      ...business,
      [property]: value,
    });
  };

  const handleSave = () => {
    if (!isValid()) return;

    setShowLoader(true);
    post("business/saveBusiness", business)
      .then((res) => {
        alert("Success", "Saved business", "success");
        navigate("/businesses");
      })
      .catch((err) => {
        alert(
          "Unable to save business",
          err.data.message || "Please try again",
          "error"
        );
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const isValid = () => {
    if (!business.name || !business.name.trim()) {
      alert("Form invalid", "Name must have a value", "warning");
      return false;
    }
    if (!business.eIN || !business.eIN.trim()) {
      alert("Form invalid", "E I N must have a value", "warning");
      return false;
    }
    if (!business.email || !business.email.trim()) {
      alert("Form invalid", "Email must have a value", "warning");
      return false;
    }
    return true;
  };

  return (
    <div>
      {/* Header */}
      <PageHeader
        title={
          isCreatingNew || !business ? "Create new business" : `Manage business ${business.name}`
        }
      />
      <GoBack display="Manage all businesses" navigateTo="/businesses" />

      <div className="bg-gray-100 shadow-md rounded-md shadow-primaryColor/25">
        {/* Form */}
        <CollapsibleSection title="Info">
          <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4">
            <div>
              <TextInput
                label="Name"
                value={business?.name ?? ""}
                onChange={(e) => handleChange("name", e.target.value)}
                required
                maxLength={50}
              />
            </div>
            <div>
              <TextInput
                label="E I N"
                value={business?.eIN ?? ""}
                onChange={(e) => handleChange("eIN", e.target.value)}
                required
                maxLength={10}
              />
            </div>
            <div>
              <TextInput
                label="Email"
                value={business?.email ?? ""}
                onChange={(e) => handleChange("email", e.target.value)}
                required
                maxLength={150}
              />
            </div>
            <div>
              <PhoneInput
                label="Phone"
                value={business?.phone ?? ""}
                onChange={(e) => handleChange("phone", e)}
              />
            </div>
            <div>
              <TextInput
                label="Timezone"
                value={business?.timezone ?? ""}
                onChange={(e) => handleChange("timezone", e.target.value)}
                maxLength={30}
              />
            </div>
            <div>
              <TextInput
                label="Address Line 1"
                value={business?.address1 ?? ""}
                onChange={(e) => handleChange("address1", e.target.value)}
                maxLength={30}
              />
            </div>
            <div>
              <TextInput
                label="Address Line 2"
                value={business?.address2 ?? ""}
                onChange={(e) => handleChange("address2", e.target.value)}
                maxLength={30}
              />
            </div>
            <div>
              <TextInput
                label="Address Line 3"
                value={business?.address3 ?? ""}
                onChange={(e) => handleChange("address3", e.target.value)}
                maxLength={30}
              />
            </div>
            <div>
              <TextInput
                label="City"
                value={business?.city ?? ""}
                onChange={(e) => handleChange("city", e.target.value)}
                maxLength={30}
              />
            </div>
            <div>
              <TextInput
                label="State"
                value={business?.state ?? ""}
                onChange={(e) => handleChange("state", e.target.value)}
                maxLength={2}
              />
            </div>
            <div>
              <TextInput
                label="Country"
                value={business?.country ?? ""}
                onChange={(e) => handleChange("country", e.target.value)}
                maxLength={30}
              />
            </div>
            <div>
              <TextInput
                label="Postal Code"
                value={business?.postalCode ?? ""}
                onChange={(e) => handleChange("postalCode", e.target.value)}
                maxLength={30}
              />
            </div>
            <div>
              <TextInput
                label="County"
                value={business?.county ?? ""}
                onChange={(e) => handleChange("county", e.target.value)}
                maxLength={30}
              />
            </div>
            <div>
              <NumberInput
                label="Latitude"
                value={business?.latitude ?? 0}
                onChange={(e) => {
                  let value = e.target.value;
                  if(value > 90) value = 90;
                  else if(value < -90) value = -90;
                  handleChange("latitude", value)
                }}
                max={90}
                min={-90}
              />
            </div>
            <div>
              <NumberInput
                label="Longitude"
                value={business?.longitude ?? 0}
                onChange={(e) => {
                  let value = e.target.value;
                  if(value > 180) value = 180;
                  else if(value < -180) value = -180;
                  handleChange("longitude", value)
                }}
                max={180}
                min={-180}
              />
            </div>
            <div>
              <ToggleInput
                label="Unmapped Location"
                enabled={business?.nonStandardLocation ?? false}
                setEnabled={(e) => handleChange("nonStandardLocation", e)}
              />
            </div>
            <div>
              <TextAreaInput
                label="Biography"
                value={business?.biography ?? ""}
                onChange={(e) => handleChange("biography", e.target.value)}
                maxLength={1000}
                />
            </div>
            {/* <div>
              <SelectInput
                label="Last Updated By User"
                options={convertToLabelValueList(lastUpdatedByUserData)}
                onClear={() => handleChange("lastUpdatedByUserId", undefined)}
                value={convertToLabelValue(lastUpdatedByUserData?.find(x => x.id === business?.lastUpdatedByUserId))}
                onChange={(lastUpdatedByUser) => handleChange("lastUpdatedByUserId", lastUpdatedByUser.value)}
                isSearchable
              />
            </div> */}
           {/* Put fields here */}
          </div>
        </CollapsibleSection>

        {/* Submit button */}
        <div className="mt-12 pb-4 px-4">
          <PrimaryButton
            text={isCreatingNew ? "Create" : "Save"}
            onClick={handleSave}
          />
        </div>
      </div>
    </div>
  );
}
