import React from 'react';
import { countDefinedKeys } from "../../utils/helpers";

export default function StaticFilterSection({ filters, children, showFilterCount = true }) {
  const filterCount = countDefinedKeys(filters);
  return (
    <div className="mb-2">
      {showFilterCount
        ? (<div className="mb-2">
            <div className="">
              <span className="ml-2 text-primaryColor">
                {filterCount} filter{filterCount === 1 ? "" : "s"} active
              </span>
            </div>
          </div>)
        : null
      }
      <div className="bg-gray rounded-md p-4 shadow-lg ring-1 ring-black ring-opacity-5">
        <div>
          {children}
        </div>
      </div>
    </div>
  );
}
