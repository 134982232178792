export default function Pill({title, additionalData}) {
  return (
    <div className="inline-block px-6 py-3 bg-primaryColor text-md shadow-lg rounded-full">
      {additionalData ? (
        <>
          <span>{title}</span>
          <span className="ml-1 text-xs">({additionalData})</span>
        </>
      ) : (
        title
      )}
    </div>
  );
}