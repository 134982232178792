import React from "react";
import { classNames } from "../../utils/helpers";

export default function PrimaryButton({
  text,
  disabled,
  backgroundColor = "klinkYellow",
  textColor = "white",
  ButtonSVG,
  onClick,
  className,
  small = false
}) {
  return (
    <button
      disabled={disabled}
      className={classNames(
        "marker:inline-flex items-center border border-transparent shadow-sm rounded-md shadow-gray-300 justify-center focus:outline-none",
        small
          ? "p-2"
          : "min-w-24 h-[38px] px-5 text-sm font-medium w-full",
        disabled
          ? "bg-gray-300 text-gray-700 hover:bg-gray-300"
          : `bg-${backgroundColor} text-${textColor} hover:opacity-70`,
        className
          ? className
          : null
      )}
      onClick={disabled ? () => {} : onClick}
    >
      {ButtonSVG ? (
        <span className="flex flex-row items-center space-x-1">
          <span className="-ml-0.5 m-1 h-4 w-4">
            <ButtonSVG />
          </span>

          <span className="whitespace-nowrap">{text}</span>
        </span>
      ) : (
        <span className="whitespace-nowrap">{text}</span>
      )}
    </button>
  );
}
