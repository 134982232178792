import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import _ from "lodash";
import { GoBack, PrimaryButton } from "../components/Buttons";
import { CollapsibleSection } from "../components/Cards";
import {
  PhoneInput,
  TextInput,
  CheckboxInput,
  TextAreaInput,
} from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { useLoaderStore } from "../context";
import { useAlert, useApi, useDebounce } from "../hooks";
import constants from "../utils/constants";
import AsyncSelect from "react-select/async";
import { PlusCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { PrimaryTable } from "../components/Tables";
import { switchSortDirection } from "../utils/helpers";
import dateHelpers from "../utils/dateHelpers";
export default function Organization() {
  let { id } = useParams();
  const navigate = useNavigate();
  const alert = useAlert();
  const isCreatingNew = id === "0";
  const { fetch, post, drop } = useApi();
  const { setShowLoader } = useLoaderStore();
  const [organization, setOrganization] = useState({ id });
  const [options, setOptions] = useState();
  const [inputValue, setInputValue] = useState("");
  const [addAdmin, setAddAdmin] = useState(false);
  const [addReporter, setAddReporter] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("id");
  const [sortDirection, setSortDirection] = useState(
    constants.SORT_DIRECTIONS.ASCENDING
  );
  const [displayCount, setDisplayCount] = useState(
    constants.DISPLAY_PER_PAGE ?? 25
  );
  const { debounced: debouncedFilters } = useDebounce({ toDebounce: filters });
  const { debounced: debouncedDisplayCount } = useDebounce({
    toDebounce: displayCount,
  });
  const { data, isLoading } = useSWR(
    !isCreatingNew ? `organization/OrgInfo/${id}` : null,
    fetch,
    {
      revalidateOnFocus: false,
      onSuccess: () => setShowLoader(false),
      onError: () => setShowLoader(false),
    }
  );
  const { data: orgUserData, mutate } = useSWR(
    [
      "organization/OrgUserlist",
      currentPage,
      debouncedDisplayCount,
      debouncedFilters,
      sortDirection,
      sortField,
    ],
    ([
      url,
      currentPage,
      debouncedDisplayCount,
      debouncedFilters,
      sortDirection,
      sortField,
    ]) =>
      post(url, {
        id: id,
        filters: {
          pageNumber: currentPage,
          pageSize: debouncedDisplayCount,
          sortDirection,
          sortField,
          ...debouncedFilters,
        },
      })
  );

  useEffect(() => {
    if (!isCreatingNew && !data) setShowLoader(true);
  }, [isCreatingNew, data]);

  useEffect(() => {
    if (!data) return;
    setOrganization(data);
  }, [data]);

  const handleChange = (property, value) => {
    setOrganization({
      ...organization,
      [property]: value,
    });
  };

  const handleSave = () => {
    if (!isValid()) return;

    setShowLoader(true);
    post("organization/SaveOrg", organization)
      .then((res) => {
        alert("Success", "Saved Organization", "success");
        navigate("/organizations");
      })
      .catch((err) => {
        alert(
          "Unable to save Org",
          err.data.message || "Please try again",
          "error"
        );
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };
  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(switchSortDirection(sortDirection));
    } else {
      setSortField(field);
    }
  };

  function removeUserFromRole(role, contextName = "Organization") {
    setShowLoader(true);
    drop("User/RemoveRole", { userId: role.userId, referenceId: role.id })
      .then((res) => {
        alert("Success", "User removed from " + contextName, "success");
        mutate();
      })
      .finally(() => setShowLoader(false));
  }

  const isValid = () => {
    if (!organization.name || !organization.name.trim()) {
      alert("Form invalid", "Name must have a value", "warning");
      return false;
    }
    if (!organization.email || !organization.email.trim()) {
      alert("Form invalid", "Email must have a value", "warning");
      return false;
    }
    return true;
  };

  function loadOptions(inputValue, callback) {
    if (!inputValue || !inputValue.length || inputValue.length < 3) return;
    fetch("user/List", { name: inputValue }).then((res) => {
      setOptions(
        _.map(res.list, (item) => ({
          value: item.id,
          label: `${item.firstName} ${item.lastName}`,
        }))
      );
      callback(options);
    });
  }

  const addUser = (admin, reporter) => {
    const rolesToAdd = [];
    if (admin) {
      rolesToAdd.push(constants.ROLE_IDS.ADMIN);
    }
    if (reporter) {
      rolesToAdd.push(constants.ROLE_IDS.REPORTS);
    }
    if (!rolesToAdd.length) {
      alert("Invalid input", "Select at least 1 role to add", "warning");
      return;
    }
    if (!selectedUser) {
      alert(
        "Invalid input",
        "Select a user context to go along with the roles selected",
        "warning"
      );
      return;
    }
    setShowLoader(true);
    post("User/AddOrgAccess", {
      userId: selectedUser.value,
      referenceId: organization.id,
      roles: rolesToAdd,
    })
      .then((res) => {
        setInputValue("");
        alert("Success", "User roles added to business", "success");
        mutate();
      })
      .finally(() => setShowLoader(false));
  };

  return (
    <div>
      {/* Header */}
      <PageHeader
        title={
          isCreatingNew || !organization
            ? "Create new organization"
            : `Manage organization ${organization.name}`
        }
      />
      <GoBack display="Manage all organizations" navigateTo="/organizations" />
      <div className="bg-gray-100 shadow-md rounded-md shadow-primaryColor/25">
        <CollapsibleSection title="Info">
          <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4">
            <div>
              <TextInput
                label="Name"
                value={organization?.name ?? ""}
                onChange={(e) => handleChange("name", e.target.value)}
                required
                maxLength={50}
              />
            </div>
            <div>
              <TextInput
                label="Email"
                value={organization?.email ?? ""}
                onChange={(e) => handleChange("email", e.target.value)}
                required
                maxLength={150}
              />
            </div>
            <div>
              <PhoneInput
                label="Phone"
                value={organization?.phone ?? ""}
                onChange={(e) => handleChange("phone", e)}
              />
            </div>
            <div>
              <TextInput
                label="Timezone"
                value={organization?.timezone ?? ""}
                onChange={(e) => handleChange("timezone", e.target.value)}
                maxLength={30}
              />
            </div>
            <div>
              <TextAreaInput
                label="Biography"
                value={organization?.biography ?? ""}
                onChange={(e) => handleChange("biography", e.target.value)}
                maxLength={1000}
              />
            </div>
          </div>
          {isCreatingNew ? null : (
            <div className="px-4">
              <CollapsibleSection title="Organization Users">
                <div className="p-4 grid grid-cols-12 gap-x-4 gap-y-4">
                  <div className="col-span-7 md:col-span-4">
                    <AsyncSelect
                      loadOptions={loadOptions}
                      onInputChange={(value) => setInputValue(value)}
                      onChange={setSelectedUser}
                      inputValue={inputValue}
                      value={selectedUser}
                      placeholder={"Type to search..."}
                    />
                  </div>
                  <div className="col-span-3 md:col-span-2">
                    <CheckboxInput
                      label="Administrator"
                      checked={addAdmin}
                      setChecked={setAddAdmin}
                    />
                    <CheckboxInput
                      label="Reporter"
                      checked={addReporter}
                      setChecked={setAddReporter}
                    />
                  </div>
                  <div className="col-span-2">
                    <PlusCircleIcon
                      title="Add User Roles"
                      className="float-left w-8 mx-1 mr-2 text-white bg-klinkYellow rounded-md cursor-pointer"
                      onClick={() => addUser(addAdmin, addReporter)}
                    />
                    <div
                      className="float-left mt-1 cursor-pointer"
                      onClick={() => addUser(addAdmin, addReporter)}
                    >
                      Add Roles
                    </div>
                  </div>
                </div>
                {orgUserData?.list?.length ? (
                  <PrimaryTable
                    isLoading={isLoading}
                    filters={filters}
                    totalCount={orgUserData?.totalCount}
                    displayCount={displayCount}
                    currentPage={currentPage}
                    setDisplayCount={setDisplayCount}
                    setCurrentPage={setCurrentPage}
                    header={
                      <PrimaryTable.Header>
                        <PrimaryTable.HeaderColumn
                          display="First Name"
                          value={filters.firstName}
                          isSortingBy={sortField === "firstName"}
                          sortDirection={sortDirection}
                          onSort={() => handleSort("firstName")}
                          onClear={() =>
                            setFilters({ ...filters, firstName: "" })
                          }
                          onType={(e) =>
                            setFilters({
                              ...filters,
                              firstName: e.target.value,
                            })
                          }
                        />
                        <PrimaryTable.HeaderColumn
                          display="Last Name"
                          value={filters.lastName}
                          isSortingBy={sortField === "lastName"}
                          sortDirection={sortDirection}
                          onSort={() => handleSort("lastName")}
                          onClear={() =>
                            setFilters({ ...filters, lastName: "" })
                          }
                          onType={(e) =>
                            setFilters({
                              ...filters,
                              lastName: e.target.value,
                            })
                          }
                        />
                        <PrimaryTable.HeaderColumn
                          display="User Role"
                          value={filters.userRole}
                          isSortingBy={sortField === "User Role"}
                          sortDirection={sortDirection}
                          onSort={() => handleSort("User Role")}
                          onType={(e) =>
                            setFilters({ ...filters, userRole: e.target.value })
                          }
                          onClear={() =>
                            setFilters({ ...filters, userRole: "" })
                          }
                        />
                        <PrimaryTable.HeaderColumn
                          display="Username"
                          value={filters.username}
                          isSortingBy={sortField === "username"}
                          sortDirection={sortDirection}
                          onSort={() => handleSort("username")}
                          onClear={() =>
                            setFilters({ ...filters, username: "" })
                          }
                          onType={(e) => {
                            setFilters({
                              ...filters,
                              username: e.target.value,
                            });
                          }}
                        />
                        <PrimaryTable.HeaderColumn display="Last Logged On" />
                        <PrimaryTable.HeaderColumn display="" />
                      </PrimaryTable.Header>
                    }
                  >
                    {orgUserData &&
                      orgUserData.list &&
                      _.map(orgUserData.list, (x, i) =>
                        x.roles.map((role, j) => (
                          <PrimaryTable.Row key={`${x.id}-${i}-${j}`} index={i}>
                            <PrimaryTable.Cell>{x.firstName}</PrimaryTable.Cell>
                            <PrimaryTable.Cell>{x.lastName}</PrimaryTable.Cell>
                            <PrimaryTable.Cell>
                              {role.roleName}
                            </PrimaryTable.Cell>
                            <PrimaryTable.Cell>{x.username}</PrimaryTable.Cell>
                            <PrimaryTable.Cell>
                              {dateHelpers.getRelativeTime(x.lastLoggedOnAt)}
                            </PrimaryTable.Cell>
                            <PrimaryTable.Cell>
                              <div className="flex">
                                <XMarkIcon
                                  title="Deactivate"
                                  className="w-6 mx-1 text-white bg-delete rounded-md p-0.5 cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeUserFromRole(role);
                                  }}
                                />
                              </div>
                            </PrimaryTable.Cell>
                          </PrimaryTable.Row>
                        ))
                      )}
                  </PrimaryTable>
                ) : (
                  <div className="px-4 pt-2 font-semibold italic">
                    No organization users are associated.
                  </div>
                )}
              </CollapsibleSection>
            </div>
          )}
          <div className="mt-4 pb-4 px-4">
            <PrimaryButton
              text={isCreatingNew ? "Create" : "Save"}
              onClick={handleSave}
            />
          </div>
        </CollapsibleSection>
      </div>
    </div>
  );
}
