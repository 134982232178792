import { useState, useEffect } from "react";
import useSWR from "swr";
import {
  TextInput,
  ToggleInput,
  DateInput,
  SelectInput,
} from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { FilterSection, PrimaryTable } from "../components/Tables";
import { useLoaderStore } from "../context";
import _ from "lodash";
import { useAlert, useApi, useDebounce } from "../hooks";
import constants from "../utils/constants";
import dateHelpers from "../utils/dateHelpers";
import { switchSortDirection } from "../utils/helpers";
import {
  getCountryCallingCode,
  formatPhoneNumber,
} from "react-phone-number-input";
import { PrimaryButton } from "../components/Buttons";
import { RejectionModal } from "../components/Modals";

export default function BusinessRequests() {
  const alert = useAlert();
  const { post, fetch } = useApi();
  const { setShowLoader } = useLoaderStore();
  const [singleSelect, setSingleSelect] = useState();
  const [rejectionModalOpen, setRejectionModalOpen] = useState(false);
  const [rejectionId, setRejectionId] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [businessRoles, setBusinessRoles] = useState([]);
  const [roleArray, setRoleArray] = useState([]);
  const [filters, setFilters] = useState({ activeOnly: true });
  const [currentPage, setCurrentPage] = useState(1);
  const [sortDirection, setSortDirection] = useState(
    constants.SORT_DIRECTIONS.ASCENDING
  );
  const [sortField, setSortField] = useState("id");
  const [displayCount, setDisplayCount] = useState(
    constants.DISPLAY_PER_PAGE ?? 25
  );

  const { debounced: debouncedFilters } = useDebounce({ toDebounce: filters });
  const { debounced: debouncedDisplayCount } = useDebounce({
    toDebounce: displayCount,
  });

  const { data, isLoading, mutate } = useSWR(
    [
      "admin/UserBusinessRequestList",
      currentPage,
      debouncedDisplayCount,
      debouncedFilters,
      sortDirection,
      sortField,
    ],
    ([
      url,
      currentPage,
      debouncedDisplayCount,
      debouncedFilters,
      sortDirection,
      sortField,
    ]) =>
      fetch(url, {
        pageNumber: currentPage,
        pageSize: debouncedDisplayCount,
        sortDirection,
        sortField,
        isExact: false,
        ...debouncedFilters,
      })
  );

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(switchSortDirection(sortDirection));
    } else {
      setSortField(field);
    }
  };

  const approveRequest = (businessRequestId, typeOfRole) => {
    setShowLoader(true);
    let payload = {
      businessRequestId,
      typeOfRole,
    };
    
    post("admin/ApproveBusinessRequest", payload)
      .then((res) => {
        alert(
          "Success",
          res.message || "User request has been approved",
          "success"
        );
        mutate();
      })
      .catch((err) => {
        alert(
          "Error",
          err.data.message || "User request could not be approved",
          "error"
        );
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const handleReject = (requestId) => {
    setRejectionModalOpen(true)
    setRejectionId(requestId)}

  const getInputValue = (value) => {
    setInputValue(value)
    }
  const handleConfirm = () => {
    const rejectionData = {
      id: rejectionId,
      rejectionMessage: inputValue
    }
    rejectRequest(rejectionData)
    setRejectionModalOpen(false);
  }
  const rejectRequest = (rejectionData) => {
    setShowLoader(true);
    post("admin/RejectBusinessRequest", rejectionData)
      .then((res) => {
        alert(
          "Success",
          res.message || "User request has been rejected",
          "success"
        );
        mutate();
      })
      .catch((err) => {
        alert(
          "Error",
          err.data.message || "User request could not be rejected",
          "error"
        );
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
        setRejectionModalOpen(false);
      });
  };

  useEffect(() => {
    fetch("admin/getRoles")
      .then(businessRoles => {
        setBusinessRoles(businessRoles);
        const businessRolesOptions = _.chain(businessRoles)
          .map(br => ({ label: br.name, value: br.id, sublabel: br.description }))
        setRoleArray(businessRolesOptions.value());
      })
      .catch((err) => {
        //report error
        console.error(err);
      });
  }, []);

  return (
    <div>
      {/* Header */}
      <div className="flex justify-between">
        <PageHeader title="Manage User Access Requests" />
      </div>

      {/* Filter section */}
      <UserFilterSection filters={filters} setFilters={setFilters} />

      {/* Table */}
      <PrimaryTable
        isLoading={isLoading}
        filters={filters}
        totalCount={data?.totalCount}
        displayCount={displayCount}
        currentPage={currentPage}
        setDisplayCount={setDisplayCount}
        setCurrentPage={setCurrentPage}
        header={
          <PrimaryTable.Header>
            <PrimaryTable.HeaderColumn
              display="Requested Business"
              value={filters.businessName}
              isSortingBy={sortField === "businessName"}
              sortDirection={sortDirection}
              onSort={() => handleSort("businessName")}
              onClear={() => setFilters({ ...filters, businessName: "" })}
              onType={(e) =>
                setFilters({
                  ...filters,
                  businessName: e.target.value,
                })
              }
            />
            <PrimaryTable.HeaderColumn
              display="Name"
              value={filters.name}
              isSortingBy={sortField === "name"}
              sortDirection={sortDirection}
              onSort={() => handleSort("name")}
              onClear={() => setFilters({ ...filters, name: "" })}
              onType={(e) =>
                setFilters({
                  ...filters,
                  name: e.target.value,
                })
              }
            />
            <PrimaryTable.HeaderColumn
              display="Username"
              value={filters.username}
              isSortingBy={sortField === "username"}
              sortDirection={sortDirection}
              onSort={() => handleSort("username")}
              onClear={() => setFilters({ ...filters, username: "" })}
              onType={(e) => {
                setFilters({
                  ...filters,
                  username: e.target.value,
                });
              }}
            />
            <PrimaryTable.HeaderColumn
              display="Email"
              value={filters.email}
              onType={(e) => setFilters({ ...filters, email: e.target.value })}
              onClear={() => setFilters({ ...filters, email: "" })}
            />
            <PrimaryTable.HeaderColumn
              display="Phone #"
              value={filters.mobilePhoneNumber}
            />
            <PrimaryTable.HeaderColumn
              display="Requested On"
              isSortingBy={sortField === "createdAt"}
              sortDirection={sortDirection}
              onSort={() => handleSort("createdAt")}
            />
            <PrimaryTable.HeaderColumn display="" />
          </PrimaryTable.Header>
        }
      >
        {data &&
          data.list &&
          data.list.map((x, i) => (
            <PrimaryTable.Row key={`${x.id}-${i}`} index={i}>
              <PrimaryTable.Cell>{x.businessName}</PrimaryTable.Cell>
              <PrimaryTable.Cell>{x.name}</PrimaryTable.Cell>
              <PrimaryTable.Cell>{x.username}</PrimaryTable.Cell>
              <PrimaryTable.Cell>{x.email}</PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {x.countryCode !== null && x.countryCode !== undefined
                  ? formatPhoneNumber(
                      `+${getCountryCallingCode(x.countryCode)} ${
                        x.mobilePhoneNumber
                      }`
                    )
                  : formatPhoneNumber(x.mobilePhoneNumber)}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {dateHelpers.monthDayAndYear(x.createdAt)}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                <div className="flex flex-row justify-between">
                  {/* Reject */}
                  {!x.RejectedAt && !x.ApprovedAt && !x.deactivatedAt && (
                    <PrimaryButton
                      text="Reject"
                      backgroundColor="delete"
                      className="mr-5 mt-6"
                      onClick={() => {
                        handleReject(x.id)
                      }}
                    />
                  )}
                  {/* Approve */}
                  {!x.RejectedAt && !x.ApprovedAt && !x.deactivatedAt && (
                    <SelectInput
                      options={roleArray}
                      label="Assign Role & Approve"
                      onClear={() => setSingleSelect({})}
                      value={null}
                      placeholder="Role"
                      customStyling={"absolute z-10 mt-1 max-h-60 w-full whitespace-normal bg-white py-1 sm:text-sm shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none"}
                      onChange={(value) => {
                        // setSingleSelect(value);
                        approveRequest(x.id, value.value);
                      }}
                    />
                  )}
                </div>
              </PrimaryTable.Cell>
            </PrimaryTable.Row>
          ))}
      </PrimaryTable>
      <RejectionModal
          modalOpen={rejectionModalOpen}
          setModalOpen={setRejectionModalOpen}
          title="Deny User Access Request?"
          message="Please add a reason for denying this user access to the business."
          maxLength={200}
          placeholder="Optional"
          confirmButtonText="Deny Request"
          getInput={getInputValue}
          onConfirm={(e) => handleConfirm(e.target.value)}
        />
    </div>
  );
}

const UserFilterSection = ({ filters, setFilters }) => {
  return (
    <FilterSection filters={filters}>
      <div>
        <div className="mb-2">
          <ToggleInput
            label="Show active records only"
            enabled={filters.activeOnly}
            setEnabled={() =>
              setFilters({
                ...filters,
                activeOnly: !filters.activeOnly,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Requested Business"
            placeholder="Business Name"
            value={filters.businessName}
            onChange={(e) =>
              setFilters({
                ...filters,
                businessName: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Name"
            placeholder="Name"
            value={filters.name}
            onChange={(e) =>
              setFilters({
                ...filters,
                name: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Username"
            placeholder="Username"
            value={filters.username}
            onChange={(e) =>
              setFilters({
                ...filters,
                username: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Email"
            placeholder="Email"
            value={filters.email}
            onChange={(e) =>
              setFilters({
                ...filters,
                email: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <DateInput
            label="Requests Before"
            value={filters.Before}
            onChange={(e) =>
              setFilters({
                ...filters,
                Before: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <DateInput
            label="Requests Since"
            value={filters.Since}
            onChange={(e) =>
              setFilters({
                ...filters,
                Since: e.target.value,
              })
            }
          />
        </div>
      </div>
    </FilterSection>
  );
};
