import { useEffect, useState } from "react";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { useNavigate, useParams } from "react-router-dom";
import { GoBack, PrimaryButton } from "../components/Buttons";
import { CollapsibleSection } from "../components/Displays";
import _ from "lodash";
import AsyncSelect from "react-select/async";
import { TelephoneInput, TextInput, CheckboxInput, DateInput } from "../components/Inputs";
import { PageHeader, SecondaryHeader } from "../components/Layouts";
import { useLoaderStore } from "../context";
import { useAlert, useApi } from "../hooks";
import constants from "../utils/constants";
import { isValidEmail } from "../utils/helpers";
import { XMarkIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import dateHelpers from "../utils/dateHelpers";
const DEFAULT_COUNTRY = "US";

export const handleSave = (
  user,
  navigate,
  alert,
  post,
  setShowLoader,
  selectedCountry
) => {
  if (!isValid(user, alert, selectedCountry)) return;
  setShowLoader(true);
  post("user/saveUser", { ...user, countryCode: selectedCountry })
    .then((res) => {
      alert("Success", "Saved user", "success");
      navigate("/users");
    })
    .catch((err) => {
      alert(
        "Unable to save user",
        err.data.message || "Please try again",
        "error"
      );
      console.error(err);
    })
    .finally(() => {
      setShowLoader(false);
    });
};

export const isValid = (user, alert, selectedCountry) => {
  if (
    !user.firstName ||
    !user.firstName.trim() ||
    user.firstName.trim().length <= 1
  ) {
    alert("Invalid input", "First name is not valid", "warning");
    return false;
  }

  if (
    !user.lastName ||
    !user.lastName.trim() ||
    user.lastName.trim().length <= 1
  ) {
    alert("Invalid input", "Last name is not valid", "warning");
    return false;
  }

  if (!user.email || !user.email.trim() || !isValidEmail(user.email)) {
    alert("Invalid input", "Email is not valid", "warning");
    return false;
  }

  if (
    !user.username ||
    !user.username.trim() ||
    user.username.trim().length <= 1
  ) {
    alert("Invalid input", "Username is not valid", "warning");
    return false;
  }

  if (!user.mobilePhoneNumber || !user.mobilePhoneNumber.trim()) {
    alert("Invalid input", "Phone number is not valid", "warning");
    return false;
  }
  
  if (!isPossiblePhoneNumber(user.mobilePhoneNumber.trim(), selectedCountry)) {
    alert("Invalid input", "Phone number is not valid", "warning");
    return false;
  }
  
  if (!user.dateOfBirth || dateHelpers.isDateValid(user.dateOfBirth)) {
    alert("Invalid input", "Date of birth is not valid", "warning");
    return false;
  }

  // if (!user.roles || user.roles.length === 0) {
  //   alert("Invalid input", "User must have at least one role", "warning");
  //   return false;
  // }

  return true;
};

export default function User() {
  let { id } = useParams();
  const navigate = useNavigate();
  const alert = useAlert();
  const isCreatingNew = id === "0";
  const { fetch, post, drop } = useApi();
  const { setShowLoader } = useLoaderStore();
  const [user, setUser] = useState(undefined);
  const [selectedCountry, setSelectedCountry] = useState(DEFAULT_COUNTRY);
  const [selectedBusiness, setSelectedBusiness] = useState();
  const [selectedOrg, setSelectedOrg] = useState();
  const [options, setOptions] = useState();
  const [inputValue, setInputValue] = useState("");
  const [orgInputValue, setOrgInputValue] = useState("");
  const [addAdmin, setAddAdmin] = useState(false);
  const [addReporter, setAddReporter] = useState(false);
  const [addOrgAdmin, setAddOrgAdmin] = useState(false);
  const [addOrgReporter, setAddOrgReporter] = useState(false);
  const [isSysAdmin, setIsSysAdmin] = useState(false);
  const now = moment(new Date()).hour(0).minute(0);
  const minDate = moment(now).subtract(100, 'year');
  const maxDateString = now.format("YYYY-MM-DD",);
  const minDateString = minDate.format("YYYY-MM-DD");

  useEffect(() => {
    if (!isCreatingNew) {
      refreshUserData();
    }
  }, [isCreatingNew]);

  function refreshUserData() {
    setShowLoader(true);
    fetch(`user/getUserInfo/${id}`)
      .then((data) => {
        if (!data) return;
        for (let i = 0; i < data.roles.length; i++) {
          let role = data.roles[i];
          if (role.businessId) {
            const biz = _.find(
              data.businesses,
              (b) => b.id === role.businessId
            );
            if (biz) {
              role.businessName = biz.name;
            } else {
              console.warn(
                "Could not resolve business with id of " + role.businessId
              );
            }
          } else if (role.orgId) {
            const org = _.find(data.orgs, (o) => o.id === role.orgId);
            if (org) {
              role.orgName = org.name;
            } else {
              console.warn("Could not resolve org with id of " + role.orgId);
            }
          } else if (role.typeOfRole === constants.ROLE_IDS.SYSTEM_ADMIN) {
            setIsSysAdmin(true);
          }
        }
        setUser(data);
        setSelectedCountry(data.countryCode);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  }

  const handleChange = (property, value) => {
    setUser({
      ...user,
      [property]: value,
    });
  };

  function removeUserFromRole(userId, userRoleId, contextName) {
    setShowLoader(true);
    drop("User/RemoveRole", { userId: userId, referenceId: userRoleId })
      .then((res) => {
        alert("Success", "User removed from " + contextName, "success");
        refreshUserData();
      })
      .finally(() => setShowLoader(false));
  }

  const loadOptions = async (inputValue, callback) => {
    if (!inputValue || !inputValue.length || inputValue.length < 3) return;
    post("business/BusinessTypeAheadList", { name: inputValue }).then((res) => {
      setOptions(
        res.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    });
    callback(options);
  };

  const orgLoadOptions = async (orgInputValue, callback) => {
    if (!orgInputValue || !orgInputValue.length || orgInputValue.length < 3)
      return;
    post("organization/OrgTypeAheadList", {
      name: orgInputValue,
      isExact: false,
    }).then((res) => {
      setOptions(
        res.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    });
    callback(options);
  };

  const addBusinessRoles = (userId, admin, reporter) => {
    let rolesToAdd = [];
    if (admin) {
      rolesToAdd.push(constants.ROLE_IDS.ADMIN);
    }
    if (reporter) {
      rolesToAdd.push(constants.ROLE_IDS.REPORTS);
    }
    if (!rolesToAdd.length) {
      alert("Invalid input", "Select at least 1 role to add", "warning");
      return;
    }
    if (!selectedBusiness) {
      alert(
        "Invalid input",
        "Select a business context to go along with the roles selected",
        "warning"
      );
      return;
    }
    setInputValue("");
    addBusinessAccess(userId, selectedBusiness.value, rolesToAdd);
  };

  const addOrgRoles = (userId, admin, reporter) => {
    let rolesToAdd = [];
    if (admin) {
      rolesToAdd.push(constants.ROLE_IDS.ADMIN);
    }
    if (reporter) {
      rolesToAdd.push(constants.ROLE_IDS.REPORTS);
    }
    if (!rolesToAdd.length) {
      alert("Invalid input", "Select at least 1 role to add", "warning");
      return;
    }
    if (!selectedOrg) {
      alert(
        "Invalid input",
        "Select an organization context to go along with the roles selected",
        "warning"
      );
      return;
    }
    setOrgInputValue("");
    addOrgAccess(userId, selectedOrg.value, rolesToAdd);
  };

  const addBusinessAccess = (userId, referenceId, rolesToAdd) => {
    setShowLoader(true);
    post("User/AddBusinessAccess", {
      userId: userId,
      referenceId: referenceId,
      roles: rolesToAdd,
    }).then((res) => {
      alert("Success", "User roles added to  business", "success");
      setShowLoader(false);
      refreshUserData();
    });
  };

  const addOrgAccess = (userId, referenceId, rolesToAdd) => {
    setShowLoader(true);
    post("User/AddOrgAccess", {
      userId: userId,
      referenceId: referenceId,
      roles: rolesToAdd,
    }).then((res) => {
      alert("Success", "User roles added to org", "success");
      setShowLoader(false);
      refreshUserData();
    });
  };

  function getOrderedBusinessRoles() {
    return _.chain(user.roles)
      .filter((r) => r.businessId)
      .sortBy((r) => r.businessName)
      .value();
  }

  function getOrderedOrgRoles() {
    return _.chain(user.roles)
      .filter((r) => r.orgId)
      .sortBy((r) => r.orgName)
      .value();
  }
  const uniqueRoles = user?.roles?.reduce((unique, role) => {
    return unique.some((u) => u.value === role.typeOfRole)
      ? unique
      : [...unique, { value: role.typeOfRole, label: role.roleName }];
  }, []);

  const handleSysAdminChange = () => {
    if (isSysAdmin) {
      setIsSysAdmin(false);
      setUser({
        ...user,
        roles: user?.roles?.filter(
          (role) => role.typeOfRole !== constants.ROLE_IDS.SYSTEM_ADMIN
        ),
      });
    } else {
      setIsSysAdmin(true);
      let roleToAdd = constants.ROLES.find(
        (role) => role.value === constants.ROLE_IDS.SYSTEM_ADMIN
      );
      setUser({
        ...user,
        roles: [
          ...(user?.roles || []),
          {
            userID: user.id,
            typeOfRole: constants.ROLE_IDS.SYSTEM_ADMIN,
            roleName: roleToAdd.label,
          },
        ],
      });
    }
  };

  return (
    <div>
      {/* Header */}
      <PageHeader
        title={
          isCreatingNew || !user ? "Create new user" : `Manage ${user.username}`
        }
      />
      <GoBack display="Manage all users" navigateTo="/users" />

      <div className="bg-gray-100 shadow-md rounded-md shadow-primaryColor/25">
        {/* Form */}
        <CollapsibleSection title="Info">
          <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4">
            <div>
              <TextInput
                label="First Name"
                value={user?.firstName ?? ""}
                onChange={(e) => handleChange("firstName", e.target.value)}
                maxLength={30}
                required
              />
            </div>
            <div>
              <TextInput
                label="Last Name"
                value={user?.lastName ?? ""}
                onChange={(e) => handleChange("lastName", e.target.value)}
                maxLength={30}
                required
              />
            </div>
            <div>
              <TextInput
                label="Email"
                value={user?.email ?? ""}
                onChange={(e) => handleChange("email", e.target.value)}
                maxLength={50}
                required
              />
            </div>
            <div>
              <TextInput
                label="Username"
                value={user?.username ?? ""}
                onChange={(e) => handleChange("username", e.target.value)}
                maxLength={30}
                required
              />
            </div>
            <div>
              <TelephoneInput
                label="Phone Number"
                value={user?.mobilePhoneNumber ?? ""}
                onChange={(e) =>
                  handleChange("mobilePhoneNumber", e.target.value)
                }
                selectedCountry={selectedCountry || DEFAULT_COUNTRY}
                setSelectedCountry={setSelectedCountry}
                required
              />
            </div>
            {isCreatingNew ? (
              <div>
                <DateInput 
                  label="Date Of Birth"
                  value={user?.dateOfBirth}                
                  onChange={(e) =>
                    handleChange("dateOfBirth", dateHelpers.formatDateForServer(e.target.value))
                  }
                  required
                  min={minDateString}
                  max={maxDateString}
                />
              </div>
            ) : null}
            <div>
              <TextInput
                label="Roles"
                value={uniqueRoles?.map((role) => role.label).join(", ") ?? ""}
                isDisabled
              />
            </div>
            <div className="self-center">
              <CheckboxInput
                label="Is System Administrator"
                checked={isSysAdmin}
                setChecked={() => handleSysAdminChange()}
                disabled={!isSysAdmin}
              />
            </div>
          </div>
          {isCreatingNew ? null : (
            <>
              <SecondaryHeader className="px-4" title="Business Roles" />
              <div className="p-4 grid grid-cols-12 gap-x-4 gap-y-4">
                <div className="col-span-7 md:col-span-4">
                  <AsyncSelect
                    loadOptions={loadOptions}
                    onInputChange={(value) => setInputValue(value)}
                    onChange={setSelectedBusiness}
                    inputValue={inputValue}
                    value={selectedBusiness}
                    placeholder={"Type to search..."}
                  />
                </div>
                <div className="col-span-3 md:col-span-2">
                  <CheckboxInput
                    label="Administrator"
                    checked={addAdmin}
                    setChecked={setAddAdmin}
                  />
                  <CheckboxInput
                    label="Reporter"
                    checked={addReporter}
                    setChecked={setAddReporter}
                  />
                </div>
                <div className="col-span-2">
                  <PlusCircleIcon
                    title="Add User Roles"
                    className="float-left w-8 mx-1 mr-2 text-white bg-klinkYellow rounded-md cursor-pointer"
                    onClick={() => {
                      addBusinessRoles(user.id, addAdmin, addReporter);
                    }}
                  />
                  <div
                    className="float-left mt-1 cursor-pointer"
                    onClick={() =>
                      addBusinessRoles(user.id, addAdmin, addReporter)
                    }
                  >
                    Add Roles
                  </div>
                </div>
              </div>
              <div className="px-6 py-1 w-full md:w-3/4">
                {user &&
                  user.roles &&
                  _.map(getOrderedBusinessRoles(), (businessRole) => (
                    <div
                      className="grid grid-cols-5 gap-x-1 py-1"
                      key={businessRole.id}
                    >
                      <div className="col-span-3">
                        {businessRole.businessName}: {businessRole.roleName}
                      </div>
                      <div>
                        <XMarkIcon
                          title="Deactivate"
                          className="w-6 mx-1 text-white bg-delete rounded-md cursor-pointer"
                          onClick={() =>
                            removeUserFromRole(
                              user.id,
                              businessRole.id,
                              "Business"
                            )
                          }
                        />
                      </div>
                    </div>
                  ))}
              </div>

              <SecondaryHeader className="px-4" title="Organization Roles" />
              <div className="p-4 grid grid-cols-12 gap-x-4 gap-y-4">
                <div className="col-span-7 md:col-span-4">
                  <AsyncSelect
                    loadOptions={orgLoadOptions}
                    onInputChange={(value) => setOrgInputValue(value)}
                    onChange={setSelectedOrg}
                    inputValue={orgInputValue}
                    value={selectedOrg}
                    placeholder={"Type to search..."}
                  />
                </div>
                <div className="col-span-3 md:col-span-2">
                  <CheckboxInput
                    label="Administrator"
                    checked={addOrgAdmin}
                    setChecked={setAddOrgAdmin}
                  />
                  <CheckboxInput
                    label="Reporter"
                    checked={addOrgReporter}
                    setChecked={setAddOrgReporter}
                  />
                </div>
                <div className="col-span-2">
                  <PlusCircleIcon
                    title="Add User Roles"
                    className="float-left w-8 mx-1 mr-2 text-white bg-klinkYellow rounded-md cursor-pointer"
                    onClick={() => {
                      addOrgRoles(user.id, addOrgAdmin, addOrgReporter);
                    }}
                  />
                  <div
                    className="float-left mt-1 cursor-pointer"
                    onClick={() =>
                      addOrgRoles(user.id, addOrgAdmin, addOrgReporter)
                    }
                  >
                    Add Roles
                  </div>
                </div>
              </div>
              <div className="px-6 py-1 w-full md:w-3/4 lg:w-1/2">
                {user &&
                  user.roles &&
                  _.map(getOrderedOrgRoles(), (orgRole) => (
                    <div
                      className="grid grid-cols-2 gap-x-1 py-1"
                      key={orgRole.id}
                    >
                      <div>
                        {orgRole.orgName}: {orgRole.roleName}
                      </div>
                      <div>
                        <XMarkIcon
                          title="Remove Role"
                          className="w-6 mx-1 text-white bg-delete rounded-md  cursor-pointer"
                          onClick={() =>
                            removeUserFromRole(
                              user.id,
                              orgRole.id,
                              "Organization"
                            )
                          }
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}
        </CollapsibleSection>
        {/* Submit button */}
        <div className="mt-4 pb-4 px-4">
          <PrimaryButton
            text={isCreatingNew ? "Create" : "Save"}
            onClick={() => {
              handleSave(
                user,
                navigate,
                alert,
                post,
                setShowLoader,
                selectedCountry
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}
