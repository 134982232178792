import {
  ArrowPathIcon,
  IdentificationIcon,
  PlusCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import useSWR from "swr";
import { PrimaryButton } from "../components/Buttons";
import { TextInput, ToggleInput } from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { FilterSection, PrimaryTable } from "../components/Tables";
import { useLoaderStore, useUserStore } from "../context";
import { useAlert, useApi, useDebounce } from "../hooks";
import constants from "../utils/constants";
import dateHelpers from "../utils/dateHelpers";
import { switchSortDirection } from "../utils/helpers";
import {
  getCountryCallingCode,
  formatPhoneNumber,
} from "react-phone-number-input";

export default function Users() {
  const navigate = useNavigate();
  const alert = useAlert();
  const { post, fetch } = useApi();
  const { setShowLoader } = useLoaderStore();
  const { currentUser, impersonate, impersonating } = useUserStore();

  const [filters, setFilters] = useState({ activeOnly: true });
  const [currentPage, setCurrentPage] = useState(1);
  const [sortDirection, setSortDirection] = useState(
    constants.SORT_DIRECTIONS.ASCENDING
  );
  const [sortField, setSortField] = useState("id");
  const [displayCount, setDisplayCount] = useState(
    constants.DISPLAY_PER_PAGE ?? 25
  );

  const { debounced: debouncedFilters } = useDebounce({ toDebounce: filters });
  const { debounced: debouncedDisplayCount } = useDebounce({
    toDebounce: displayCount,
  });

  const { data, isLoading, mutate } = useSWR(
    [
      "user/list",
      currentPage,
      debouncedDisplayCount,
      debouncedFilters,
      sortDirection,
      sortField,
    ],
    ([
      url,
      currentPage,
      debouncedDisplayCount,
      debouncedFilters,
      sortDirection,
      sortField,
    ]) =>
      fetch(url, {
        pageNumber: currentPage,
        pageSize: debouncedDisplayCount,
        sortDirection,
        sortField,
        isExact: false,
        ...debouncedFilters,
      })
  );

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(switchSortDirection(sortDirection));
    } else {
      setSortField(field);
    }
  };

  const toggleActiveStatus = (user) => {
    setShowLoader(true);
    post(`user/toggleUserActive/${user.id}`)
      .then((res) => {
        alert(
          "Success",
          res.message || "User active status toggled",
          "success"
        );
        mutate();
      })
      .catch((err) => {
        alert(
          "Error",
          err.data.message || "User active status could not be toggled",
          "error"
        );
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  function canImpersonate(u) {
    if (impersonating) return false;
    if (currentUser.id === u.id) return false;
    if (u.deactivatedAt) return false;
    return _.some(u.roleTypeIdList, rtId => rtId === constants.ROLE_IDS.ADMIN);
  }

  const handleImpersonation = (user) => {
    if (impersonating) {
      alert(
        "Already impersonating",
        "Stop impersonating to impersonate a different user",
        "warning"
      );
      return;
    }
    setShowLoader(true);

    post(`user/impersonate/${user.id}`)
      .then((res) => {
        impersonate(res.user, res.token);
        alert("Success", `Impersonating ${user.username}`, "success");
        navigate(`/`);
      })
      .catch((err) => {
        alert(
          "Error",
          err.data.message || "Could not impersonate user",
          "error"
        );
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  return (
    <div className="w-100 h-auto">
      {/* Header */}
      <div className="flex justify-between">
        <PageHeader title="Manage Users" />
        <PlusCircleIcon
          className="w-8 text-sandyOrange hover:text-yellow/50 cursor-pointer"
          onClick={() => navigate("/users/0")}
        />
      </div>

      {/* Filter section */}
      <UserFilterSection filters={filters} setFilters={setFilters} />

      {/* Table */}
      <PrimaryTable
        isLoading={isLoading}
        filters={filters}
        totalCount={data?.totalCount}
        displayCount={displayCount}
        currentPage={currentPage}
        setDisplayCount={setDisplayCount}
        setCurrentPage={setCurrentPage}
        header={
          <PrimaryTable.Header>
            <PrimaryTable.HeaderColumn
              display="Id"
              value={filters.id}
              sortable
              onSort={() => handleSort("id")}
              isSortingBy={sortField === "id"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, id: "" })}
              onType={(e) => setFilters({ ...filters, id: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="First Name"
              value={filters.firstName}
              isSortingBy={sortField === "firstName"}
              sortDirection={sortDirection}
              onSort={() => handleSort("firstName")}
              onClear={() => setFilters({ ...filters, firstName: "" })}
              onType={(e) =>
                setFilters({
                  ...filters,
                  firstName: e.target.value,
                })
              }
            />
            <PrimaryTable.HeaderColumn
              display="Last Name"
              value={filters.lastName}
              isSortingBy={sortField === "lastName"}
              sortDirection={sortDirection}
              onSort={() => handleSort("lastName")}
              onClear={() => setFilters({ ...filters, lastName: "" })}
              onType={(e) =>
                setFilters({
                  ...filters,
                  lastName: e.target.value,
                })
              }
            />
            <PrimaryTable.HeaderColumn
              display="Email"
              value={filters.email}
              isSortingBy={sortField === "email"}
              sortDirection={sortDirection}
              onSort={() => handleSort("email")}
              onType={(e) => setFilters({ ...filters, email: e.target.value })}
              onClear={() => setFilters({ ...filters, email: "" })}
            />
            <PrimaryTable.HeaderColumn
              display="Birth Date"
              // value={filters.mobilePhoneNumber}
              // onType={(e) =>
              //   setFilters({
              //     ...filters,
              //     mobilePhoneNumber: e.target.value,
              //   })
              // }
              // onClear={() => setFilters({ ...filters, mobilePhoneNumber: "" })}
            />
            <PrimaryTable.HeaderColumn
              display="Phone #"
              value={filters.mobilePhoneNumber}
              onType={(e) =>
                setFilters({
                  ...filters,
                  mobilePhoneNumber: e.target.value,
                })
              }
              onClear={() => setFilters({ ...filters, mobilePhoneNumber: "" })}
            />
            <PrimaryTable.HeaderColumn
              display="Username"
              value={filters.username}
              isSortingBy={sortField === "username"}
              sortDirection={sortDirection}
              onSort={() => handleSort("username")}
              onClear={() => setFilters({ ...filters, username: "" })}
              onType={(e) => {
                setFilters({
                  ...filters,
                  username: e.target.value,
                });
              }}
            />
            <PrimaryTable.HeaderColumn display="Last Logged On" colSpan="2" />
          </PrimaryTable.Header>
        }
      >
        {data &&
          data.list &&
          data.list.map((x, i) => (
            <PrimaryTable.Row
              key={`${x.id}-${i}`}
              index={i}
              onClick={(e) => {
                if (!x.deactivatedAt) {
                  navigate(`/users/${x.id}`);
                }
              }}
            >
              <PrimaryTable.Cell>{x.id}</PrimaryTable.Cell>
              <PrimaryTable.Cell>{x.firstName}</PrimaryTable.Cell>
              <PrimaryTable.Cell>{x.lastName}</PrimaryTable.Cell>
              <PrimaryTable.Cell>{x.email}</PrimaryTable.Cell>
              <PrimaryTable.Cell>{dateHelpers.toDateStringNoDayOfWeek(x.dateOfBirth)} ({x.age})</PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {x.countryCode !== null && x.countryCode !== undefined
                  ? formatPhoneNumber(
                      `+${getCountryCallingCode(x.countryCode)} ${
                        x.mobilePhoneNumber
                      }`
                    )
                  : formatPhoneNumber(x.mobilePhoneNumber)}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>{x.username}</PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {dateHelpers.getRelativeTime(x.lastLoggedOnAt)}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                <div className="flex">
                  {/* Activate/deactivate */}
                  {currentUser.id !== x.id &&
                    (x.deactivatedAt ? (
                      <ArrowPathIcon
                        title="Activate"
                        className="w-6 mx-1 text-green-900 rounded-md p-0.5 cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleActiveStatus(x);
                        }}
                      />
                    ) : (
                      <XMarkIcon
                        title="Deactivate"
                        className="w-6 mx-1 text-white bg-delete rounded-md p-0.5 cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleActiveStatus(x);
                        }}
                      />
                    ))}

                  {/* Impersonation */}
                  {canImpersonate(x)
                    ? (<IdentificationIcon
                        title="Impersonate"
                        className="w-6 mx-1 text-white bg-darkerBlue rounded-md p-0.5 cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleImpersonation(x);
                        }}
                      />)
                    : null
                  }
                </div>
              </PrimaryTable.Cell>
            </PrimaryTable.Row>
          ))}
      </PrimaryTable>
    </div>
  );
}

const UserFilterSection = ({ filters, setFilters }) => {
  return (
    <FilterSection filters={filters}>
      <div>
        <PrimaryButton 
          text="Reset"
          backgroundColor="gray-500"
          className="float-right"
          small
          onClick={() => setFilters({})}                    
        />
        <div className="mb-2">
          <ToggleInput
            label="Show active records only"
            enabled={filters.activeOnly}
            setEnabled={() =>
              setFilters({
                ...filters,
                activeOnly: !filters.activeOnly,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Id"
            placeholder="Id"
            value={filters.id}
            onChange={(e) =>
              setFilters({
                ...filters,
                id: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="First Name"
            placeholder="First Name"
            value={filters.firstName}
            onChange={(e) =>
              setFilters({
                ...filters,
                firstName: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Last Name"
            placeholder="Last Name"
            value={filters.lastName}
            onChange={(e) =>
              setFilters({
                ...filters,
                lastName: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Email"
            placeholder="Email"
            value={filters.email}
            onChange={(e) =>
              setFilters({
                ...filters,
                email: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Username"
            placeholder="Username"
            value={filters.username}
            onChange={(e) =>
              setFilters({
                ...filters,
                username: e.target.value,
              })
            }
          />
        </div>
      </div>
    </FilterSection>
  );
};
