"use client";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useEffect, useId, useMemo, useRef, useState } from "react";
import { Label } from ".";
import { classNames, equivalentObjects } from "../../utils/helpers";

const SelectInputDropdown = ({
  isSearchable,
  options, // list of label value pairs ie [{ label: "System Administrator", value: 1 }]
  value, // selected label value pair
  handleOptionClick,
  customStyling,
}) => {
  const searchBarRef = useRef(null);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const hasSublabel = (x) => {
     return Array.isArray(x) && x.some((x) => x.hasOwnProperty("sublabel"));
  };
  const defaultDropDownStyle = "absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-white py-1 sm:text-sm shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none";
  const handleSearchChange = (e) => {
    const searchString = e.target.value;
    const filtered = options.filter((x) =>
      x.label.toLowerCase().includes(searchString.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  useEffect(() => {
    if (searchBarRef.current) searchBarRef.current.focus();
  }, []);

  return (
    <div className={customStyling ? customStyling : defaultDropDownStyle}>
      {/* Search bar */}
      {isSearchable && (
        <div className="flex bg-white py-2 px-4 sticky top-0 z-10 text-sm text-gray-400 border-b mb-2">
          <span className="absolute inset-y-0 left-0 pl-2 py-4 text-sm flex items-center">
            <MagnifyingGlassIcon className="w-4" />
          </span>
          <input
            type="text"
            ref={searchBarRef}
            className={classNames(
              "w-full pl-4 text-sm outline-none border-gray-300 placeholder-gray-400"
            )}
            placeholder="Search"
            onChange={handleSearchChange}
            autoComplete="off"
          />
        </div>
      )}
      {/* Dropdowns */}
      {filteredOptions &&
        filteredOptions.map((x, i) =>
          hasSublabel(filteredOptions) ? (
            <div
              key={x.label + i}
              className={`relative cursor-pointer select-none py-1 pl-4 pr-4 text-gray-600 hover:bg-gray-100 text-sm ${
                equivalentObjects(x, value)
                  ? "border-l-2 border-primaryColor bg-gray-100"
                  : ""
              }`}
              onClick={() => handleOptionClick(x)}
            >
              <span
                className={`block truncate ${
                  equivalentObjects(x, value) ? "font-medium" : "font-normal"
                }`}
              >
                {x.label}
              </span>
              <span className="font-thin text-xs">
                {x.sublabel}
              </span>
            </div>
          ) : (
            <div
              key={x.label + i}
              className={`relative cursor-pointer select-none py-1 pl-4 pr-4 text-gray-600 hover:bg-gray-100 text-sm ${
                equivalentObjects(x, value)
                  ? "border-l-2 border-primaryColor bg-gray-100"
                  : ""
              }`}
              onClick={() => handleOptionClick(x)}
            >
              <span
                className={`block truncate ${
                  equivalentObjects(x, value) ? "font-medium" : "font-normal"
                }`}
              >
                {x.label}
              </span>
            </div>
          )
        )}
    </div>
  );
};

export default function SelectInput({
  options, // list of label value pairs ie [{ label: "System Administrator", value: 1 }]
  value, // selected label value pair
  label,
  placeholder = "",
  required = false,
  onChange,
  onClear,
  isSearchable,
  customStyling,
  customContainerStyling
}) {
  const id = useId();
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  const clearSelection = () => {
    if (onClear) {
      onClear();
      setIsOpen(false);
    }
  };

  const selectedOption = useMemo(
    () => options.find((x) => equivalentObjects(x, value)),
    [options, value]
  );

  useEffect(() => {
    const handler = (e) => {
      const dropdown = document.getElementById(`selectInput${id}`);

      if (dropdown && !dropdown.contains(e.target) && isOpen) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handler);
    return () => document.removeEventListener("mousedown", handler);
  }, [id, isOpen, setIsOpen]);

  return (
    <div id={`selectInput${id}`} className={customContainerStyling ? customContainerStyling : "relative"}>
      {/* Label/required asterisk */}
      {label && <Label label={label} required={required} />}

      {/* X icon if clearable */}
      {onClear && value && (
        <XMarkIcon
          className="h-4 w-4 text-gray-400 absolute right-0 mr-2 my-auto inset-y-0 cursor-pointer"
          onClick={clearSelection}
        />
      )}

      <div className={classNames("relative", label ? "mt-1" : "")}>
        <div
          className={classNames(
            "w-full min-h-[2rem] cursor-pointer sm:text-sm rounded-md  bg-white p-2 pl-3 pr-10 text-left shadow-md focus:outline-none placeholder-gray-400"
          )}
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className={`h-5 w-5 ${
                isOpen ? "transform rotate-180" : ""
              }`}
              aria-hidden="true"
            />
          </span>
          {placeholder &&
            (!selectedOption || selectedOption.label.length === 0) && (
              <span className="block truncate text-gray-400">
                {placeholder}
              </span>
            )}
          <span className="block truncate">{selectedOption?.label}</span>
        </div>

        {isOpen && (
          <SelectInputDropdown
            isSearchable={isSearchable}
            handleOptionClick={handleOptionClick}
            value={value}
            options={options}
            customStyling={customStyling}
          />
        )}
      </div>
    </div>
  );
}
