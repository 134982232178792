import { Menu } from "@headlessui/react";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import {
  MENU_ALT_2, 
  MENU_ALT_3,
  NEW_LOGO_HORZ
} from "../../assets/images";
import { useLayoutStore, useUserStore } from "../../context";
import constants from "../../utils/constants";
import { classNames } from "../../utils/helpers";
import { useNavigate } from "react-router";
import { useAlert } from "../../hooks";
import { useEffect, useMemo } from "react";
//const LOGO = require("../../assets/images/logo-only.png");

export default function TopNavMenu() {
  const { sidebarMenuOpen, setSidebarMenuOpen } = useLayoutStore();
  const { impersonating, stopImpersonating, currentUser, logout } = useUserStore();
  const navigate = useNavigate();
  const alert = useAlert();

  const handleStopImpersonation = () => {
    stopImpersonating();
    navigate("/");
    alert("Success", "No longer impersonating", "success");
  };

  const profileDropdownItems = useMemo(() => [
    {
      href: `/profile/${currentUser.id}`,
      name: "Profile",
    },
    {
      onClick: logout,
      href: "/",
      name: "Sign Out",
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [currentUser]);

  return (
    <div className="z-50 mx-auto px-2 sm:px-6 lg:pl-4 lg:pr-8 bg-klinkYellow  flex h-16 items-center justify-between fixed w-screen top-0 left-0">
      <div className="flex flex-1">
        {/* Sidebar opener/closer */}
        <div className="mr-6 flex items-center space-x-4 cursor-pointer">
          {sidebarMenuOpen ? (
            <img
              className="h-8 w-auto"
              src={MENU_ALT_3}
              alt="logo"
              onClick={() => setSidebarMenuOpen(false)}
            />
          ) : (
            <img
              className="h-8 w-auto"
              src={MENU_ALT_2}
              alt="logo"
              onClick={() => setSidebarMenuOpen(true)}
            />
          )}
        </div>

        {/* Logo  */}
        <Link to="/" className="flex flex-shrink-0 items-center " style={{height: 120, width: 120}}>
          <img src={NEW_LOGO_HORZ} alt="Klink-iT! Logo"/>
{/*          <p className="mr-2 w-auto mt-2 font-thin text-2xl justify-center text-center" style={{whiteSpace: "nowrap"}}>Klink-iT!</p>
*/}        </Link>
      </div>

      <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
        {/* Profile dropdown */}
        <Menu as="div" className="relative ml-3">
          <Menu.Button className="flex rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primaryColor">
            <span className="sr-only">Open user menu</span>
            <img className="w-12 h-12 rounded-full" src={currentUser.imageUrl} />
            {/*<UserCircleIcon className="w-10 text-white " />*/}
          </Menu.Button>
          <Menu.Items className="absolute right-0 z-50 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {impersonating && (<Menu.Item>
                {({ active }) => (
                  <Link
                    to=""
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      "block px-4 py-2 text-sm text-gray-700"
                    )}
                    onClick={handleStopImpersonation}
                  >
                  Stop impersonating
                  </Link>
                )}
              </Menu.Item>
            )}
            {profileDropdownItems.map((subItem, i) => (
              <Menu.Item key={subItem.href + i}>
                {({ active }) => (
                  <Link
                    to={subItem.href}
                    onClick={subItem.onClick}
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      "block px-4 py-2 text-sm text-gray-700"
                    )}
                  >
                    {subItem.name}
                  </Link>
                )}
              </Menu.Item>
            ))}            
          </Menu.Items>
        </Menu>
      </div>
    </div>
  );
}
