import { NumberInput } from "../Inputs";

const getLowEndOfRange = (currentPage, rowCount, total) => {
  let lowEnd = rowCount * (currentPage - 1) + 1;
  if (Number.isNaN(lowEnd)) return 1;
  return total < lowEnd ? total : lowEnd;
};

const getHighEndOfRange = (currentPage, rowCount, total) => {
  let highEnd = rowCount * currentPage;
  if (Number.isNaN(highEnd)) return 1;
  return total < highEnd ? total : highEnd;
};

// Pagination helper to show dynamic # of items per page
export default function PaginationOptions({
  total,
  rowCount,
  currentPage,
  setRowCount,
}) {

  return (
    <div className="flex py-4 px-2 text-xs items-center justify-end">
      <div className="mr-4 text-gray-400">Items per page</div>
      <div className="flex justify-center items-center">
        <NumberInput
          name="clientPagination"
          value={rowCount}
          min={1}
          onChange={(e) => {
            setRowCount(e.target.valueAsNumber);
          }}
        />
      </div>
      <div className="text-gray-400 ml-3 border-l pl-3">
        showing {getLowEndOfRange(currentPage, rowCount, total)} -{" "}
        {getHighEndOfRange(currentPage, rowCount, total)} of {total} items
      </div>
    </div>
  );
}
