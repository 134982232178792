import {
  ArrowPathIcon,
  PlusCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { TextInput, ToggleInput } from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { FilterSection, PrimaryTable } from "../components/Tables";
import { useLoaderStore } from "../context";
import { useAlert, useApi, useDebounce } from "../hooks";
import constants from "../utils/constants";
import dateHelpers from "../utils/dateHelpers";
import { switchSortDirection } from "../utils/helpers";

export default function CustomerLocationHistorys() {
  const navigate = useNavigate();
  const alert = useAlert();
  const { post, fetch } = useApi();
  const { setShowLoader } = useLoaderStore();

  const [filters, setFilters] = useState({ activeOnly: true });
  const [currentPage, setCurrentPage] = useState(1);
  const [sortDirection, setSortDirection] = useState(
    constants.SORT_DIRECTIONS.ASCENDING
  );
  const [sortField, setSortField] = useState("id");
  const [displayCount, setDisplayCount] = useState(
    constants.DISPLAY_PER_PAGE ?? 25
  );

  const { debounced: debouncedFilters } = useDebounce({ toDebounce: filters });
  const { debounced: debouncedDisplayCount } = useDebounce({
    toDebounce: displayCount,
  });

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    [
      "customer/customerLocationHistoryList",
      currentPage,
      debouncedDisplayCount,
      debouncedFilters,
      sortDirection,
      sortField,
    ],
    ([
      url,
      currentPage,
      debouncedDisplayCount,
      debouncedFilters,
      sortDirection,
      sortField,
    ]) =>
      fetch(url, {
        pageNumber: currentPage,
        pageSize: debouncedDisplayCount,
        sortDirection,
        sortField,
        isExact: false,
        ...debouncedFilters,
      })
  );

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(switchSortDirection(sortDirection));
    } else {
      setSortField(field);
    }
  };

const toggleActiveStatus = (customerLocationHistory) => {
    setShowLoader(true);
    post(`customer/toggleCustomerLocationHistoryActive/${customerLocationHistory.id}`)
      .then((res) => {
        alert("Success",
          res.message || "CustomerLocationHistory active status toggled",
          "success");
        mutate();
      })
      .catch((err) => {
        alert("Error",
          err.data.message || "CustomerLocationHistory active status could not be toggled",
          "error");
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
};
  // Insert toggleActiveStatus here

  return (
    <div>
      {/* Header */}
      <div className="flex justify-between">
        <PageHeader title="Manage CustomerLocationHistorys" />
        <PlusCircleIcon
          className="w-8 text-sandyOrange hover:text-yellow/50 cursor-pointer"
          onClick={() => navigate("/customerLocationHistorys/0")}
        />
      </div>

      {/* Filter section */}
      <CustomerLocationHistoryFilterSection filters={filters} setFilters={setFilters} />

      {/* Table */}
      <PrimaryTable
        isLoading={isLoading}
        filters={filters}
        totalCount={data?.totalCount}
        displayCount={displayCount}
        currentPage={currentPage}
        setDisplayCount={setDisplayCount}
        setCurrentPage={setCurrentPage}
        header={
          <PrimaryTable.Header>
            <PrimaryTable.HeaderColumn
              display="Id"
              value={filters.id}
              sortable
              onSort={() => handleSort("id")}
              isSortingBy={sortField === "id"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, id: "" })}
              onType={(e) => setFilters({ ...filters, id: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Customer"
              value={filters.customer}
              sortable
              onSort={() => handleSort("customer")}
              isSortingBy={sortField === "customer"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, customer: "" })}
              onType={(e) => setFilters({ ...filters, customer: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Business Location"
              value={filters.businessLocation}
              sortable
              onSort={() => handleSort("businessLocation")}
              isSortingBy={sortField === "businessLocation"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, businessLocation: "" })}
              onType={(e) => setFilters({ ...filters, businessLocation: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Check In At"
              value={filters.checkInAt}
              sortable
              onSort={() => handleSort("checkInAt")}
              isSortingBy={sortField === "checkInAt"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, checkInAt: "" })}
              onType={(e) => setFilters({ ...filters, checkInAt: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Check Out At"
              value={filters.checkOutAt}
              sortable
              onSort={() => handleSort("checkOutAt")}
              isSortingBy={sortField === "checkOutAt"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, checkOutAt: "" })}
              onType={(e) => setFilters({ ...filters, checkOutAt: e.target.value })}
            />
            {/* Insert column headers here */}
            <PrimaryTable.HeaderColumn display="" />
          </PrimaryTable.Header>
        }
      >
        {data &&
          data.list &&
          data.list.map((x, i) => (
            <PrimaryTable.Row
              key={`${x.id}-${i}`}
              index={i}
              onClick={(e) => {
                if (!x.deactivatedAt) {
                  navigate(`/customerLocationHistorys/${x.id}`);
                }
              }}
            >
              <PrimaryTable.Cell>{x.id}</PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {x.customer}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {x.businessLocation}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {dateHelpers.monthDayAndYear(x.checkInAt)}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {dateHelpers.monthDayAndYear(x.checkOutAt)}
              </PrimaryTable.Cell>
              {/* Insert cells here */}
              <PrimaryTable.Cell>
                <div className="flex">
{x.deactivatedAt ? (
    <ArrowPathIcon
        title="Activate"
        className="w-6 mx-1 text-green-900 rounded-md p-0.5 cursor-pointer"
        onClick={(e) => {
            e.stopPropagation();
            toggleActiveStatus(x);
        }}
    />
) : (
    <XMarkIcon
        title="Deactivate"
        className="w-6 mx-1 text-white bg-delete rounded-md p-0.5 cursor-pointer"
        onClick={(e) => {
            e.stopPropagation();
            toggleActiveStatus(x);
        }}
    />
)}
                  {/* Insert flip active status here */}
                </div>
              </PrimaryTable.Cell>
            </PrimaryTable.Row>
          ))}
      </PrimaryTable>
    </div>
  );
}

const CustomerLocationHistoryFilterSection = ({ filters, setFilters }) => {
  return (
    <FilterSection filters={filters}>
      <div>
        <div className="mb-2">
          <ToggleInput
            label="Show active records only"
            enabled={filters.activeOnly}
            setEnabled={() =>
              setFilters({
                ...filters,
                activeOnly: !filters.activeOnly,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Id"
            placeholder="Id"
            value={filters.id}
            onChange={(e) =>
              setFilters({
                ...filters,
                id: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Customer"
            placeholder="Customer"
            value={filters.customer}
            onChange={(e) =>
              setFilters({
                ...filters,
                customer: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Business Location"
            placeholder="Business Location"
            value={filters.businessLocation}
            onChange={(e) =>
              setFilters({
                ...filters,
                businessLocation: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Check In At"
            placeholder="Check In At"
            value={filters.checkInAt}
            onChange={(e) =>
              setFilters({
                ...filters,
                checkInAt: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Check Out At"
            placeholder="Check Out At"
            value={filters.checkOutAt}
            onChange={(e) =>
              setFilters({
                ...filters,
                checkOutAt: e.target.value,
              })
            }
          />
        </div>
        {/* Add filters here */}
      </div>
    </FilterSection>
  );
};
