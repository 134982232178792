import _ from "lodash";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { PrimaryButton } from "../components/Buttons";
import {
  DateInput,
  SelectInput,
  TextInput,
  ToggleInput,
} from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { FilterSection, PrimaryTable } from "../components/Tables";
import { useLoaderStore, useUserStore } from "../context";
import { useAlert, useApi, useDebounce } from "../hooks";
import { ConfirmationModal } from "../components/Modals";
import constants from "../utils/constants";
import dateHelpers from "../utils/dateHelpers";
import { formatCurrency, convertPenniesToDollars, switchSortDirection } from "../utils/helpers";

export default function Gifts() {
  const { currentUser } = useUserStore();
  const alert = useAlert();
  const { post, fetch } = useApi();
  const { setShowLoader } = useLoaderStore();
  // const [userBusinesses, setUserBusinesses] = useState([]);
  const [businessArray, setBusinessArray] = useState([]);
  const [pendingCancelGiftId, setPendingCancelGiftId] = useState(null);
  const [filters, setFilters] = useState({ isRedeemable: true });
  const [currentPage, setCurrentPage] = useState(1);
  const [sortDirection, setSortDirection] = useState(
    constants.SORT_DIRECTIONS.ASCENDING
  );
  const [sortField, setSortField] = useState("id");
  const [displayCount, setDisplayCount] = useState(
    constants.DISPLAY_PER_PAGE ?? 25
  );
  const { debounced: debouncedFilters } = useDebounce({ toDebounce: filters });
  const { debounced: debouncedDisplayCount } = useDebounce({
    toDebounce: displayCount,
  });

  const isSysAdmin = currentUser.roles.some(
    (role) => role.typeOfRole === constants.ROLE_IDS.SYSTEM_ADMIN
  );

  useEffect(() => {
    post("admin/fullBusinessList", {})
      .then((userBusinesses) => {
        // setUserBusinesses(userBusinesses);
        const distinctBusinessList = _.chain(userBusinesses)
          .map((ub) => ({ label: ub.name, value: ub.id }))
          .sortBy("name")
          .uniq();
        setBusinessArray(distinctBusinessList.value());
      })
      .catch((err) => {
        // report error
        console.error(err);
      });
  }, []);

  useEffect(() => {
    // Reset page number and page size when filters change
    setCurrentPage(1);
    setDisplayCount(25);
  }, [filters]);

function redeemableSum(gift) {
  return convertPenniesToDollars(_.chain(gift.giftItems)
    .filter(x => x.redeemable)
    .sumBy(x => x.unitPrice)
    .value());
}

  const { data, isLoading, mutate } = useSWR(
    [
      "admin/giftList",
      currentPage,
      debouncedDisplayCount,
      debouncedFilters,
      sortDirection,
      sortField,
    ],

    ([
      url,
      currentPage,
      debouncedDisplayCount,
      debouncedFilters,
      sortDirection,
      sortField,
    ]) =>
      fetch(url, {
        pageNumber: currentPage,
        pageSize: debouncedDisplayCount,
        sortDirection,
        sortField,
        isExact: false,
        ...debouncedFilters,
      })
  );

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(switchSortDirection(sortDirection));
    } else {
      setSortField(field);
    }
  };

  const cancelGift = (giftId) => {
    setShowLoader(true);
    post("gift/cancelGift", {
      giftId: giftId,
      cancelReason: constants.CANCEL_REASON_IDS.ADMIN
    }).then((res) => {
        alert(
          "Success",
          res.message || "Gift canceled successfully",
          "success"
        );
        setPendingCancelGiftId(null);
        mutate();
      })
      .catch((err) => {
        alert(
          "Error",
          err.data.message || "Gift could not be canceled",
          "error"
        );
        console.error(err);
      })
      .finally(() => setShowLoader(false));
  };

  return (
    <div className="w-100 h-auto">
      {/* Header */}
      <div className="flex justify-between">
        <PageHeader title="Manage Gifts" />
        <div style={{width: "250px"}}>
          <SelectInput
            options={businessArray}
            onClear={() => {
              let newFilters = Object.assign({}, filters);
              delete newFilters.businessId;
              setFilters(newFilters);
            }}
            value={_.find(businessArray, b => b.value === filters.businessId)}
            placeholder="Select Business"
            onChange={(value) =>
              setFilters({
                ...filters,
                businessId: value.value,
              })
            }
          />
        </div>
      </div>

      {/* Filter section */}
      <GiftFilterSection filters={filters} setFilters={setFilters} />

      {/* Table */}
      <PrimaryTable
        isLoading={isLoading}
        filters={filters}
        totalCount={data?.totalCount}
        displayCount={displayCount}
        currentPage={currentPage}
        setDisplayCount={setDisplayCount}
        setCurrentPage={setCurrentPage}
        header={
          <PrimaryTable.Header>
            <PrimaryTable.HeaderColumn
              display="From"
              value={filters.fromName}
              sortable
              onSort={() => handleSort("fromName")}
              isSortingBy={sortField === "fromName"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, fromName: "" })}
              onType={(e) =>
                setFilters({ ...filters, fromName: e.target.value })
              }
            />
            <PrimaryTable.HeaderColumn
              display="To"
              value={filters.toName}
              sortable
              onSort={() => handleSort("toName")}
              isSortingBy={sortField === "toName"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, toName: "" })}
              onType={(e) => setFilters({ ...filters, toName: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Total Amount"
              value={filters.amount}
              sortable
              onSort={() => handleSort("amount")}
              isSortingBy={sortField === "amount"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, amount: "" })}
              onType={(e) => setFilters({ ...filters, amount: e.target.value })}
            />
            <PrimaryTable.HeaderColumn
              display="Redeemable Amount"
            />
            <PrimaryTable.HeaderColumn
              display="Item Count"
              value={filters.giftItemCount}
              sortable
              onSort={() => handleSort("giftItemCount")}
              isSortingBy={sortField === "giftItemCount"}
              sortDirection={sortDirection}
            />
            <PrimaryTable.HeaderColumn
              display="Business"
              value={filters.businessName}
              sortable
              onSort={() => handleSort("businessName")}
              isSortingBy={sortField === "businessName"}
              sortDirection={sortDirection}
              onClear={() => setFilters({ ...filters, businessName: "" })}
              onType={(e) =>
                setFilters({ ...filters, businessName: e.target.value })
              }
            />
            <PrimaryTable.HeaderColumn
              display="Sent On"
              sortable
              onSort={() => handleSort("sentOn")}
              isSortingBy={sortField === "sentOn"}
              sortDirection={sortDirection}
            />
            <PrimaryTable.HeaderColumn
              display="Expires On"
              sortable
              onSort={() => handleSort("expiresOn")}
              isSortingBy={sortField === "expiresOn"}
              sortDirection={sortDirection}
            />
            <PrimaryTable.HeaderColumn
              display="Message"
              colSpan={isSysAdmin ? 2 : 1}
            />
          </PrimaryTable.Header>
        }
      >
        {data &&
          data.list &&
          data.list.map((x, i) => (
            <PrimaryTable.Row
              key={`${x.id}-${i}`}
              index={i}
            >
              <PrimaryTable.Cell>
                {x.fromUserId
                  ? `${x.fromUserFirstName} ${x.fromUserLastName}`
                  : x.fromOrgName}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {x.toUserFirstName} {x.toUserLastName}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell className="text-right">
                {formatCurrency(x.amountDollars)}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell className="text-right">
                {formatCurrency(redeemableSum(x))}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {x.giftItemCount}
              </PrimaryTable.Cell>
              {/* <PrimaryTable.Cell>{x.items}</PrimaryTable.Cell> */}
              <PrimaryTable.Cell>{x.businessName}</PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {dateHelpers.monthDayAndYear(x.createdAt)}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>
                {dateHelpers.monthDayAndYear(x.expiresAt)}
              </PrimaryTable.Cell>
              <PrimaryTable.Cell>{x.message}</PrimaryTable.Cell>
              {/* Cancel Gift */}
              {isSysAdmin &&
                !x.cancelledAt &&
                !x.deactivatedAt &&
                !x.redemptionStartedAt && (
                  <PrimaryTable.Cell>
                    <div className="flex flex-row justify-between">
                      <PrimaryButton
                        text="Cancel"
                        backgroundColor="delete"
                        small
                        onClick={() => setPendingCancelGiftId(x.id)}
                      />
                    </div>
                  </PrimaryTable.Cell>
                )}
            </PrimaryTable.Row>
          ))}
      </PrimaryTable>
      <ConfirmationModal
        modalOpen={pendingCancelGiftId ? true : false}
        setModalOpen={() => setPendingCancelGiftId(null)}
        title="Cancel Gift"
        message="Type 'delete' to confirm this"
        textToMatch="delete"
        confirmButtonText="Permanently Delete"
        onConfirm={() => cancelGift(pendingCancelGiftId)}
      />
    </div>
  );
}

const GiftFilterSection = ({ filters, setFilters }) => {
  return (
    <FilterSection filters={filters}>
      <div>
        <PrimaryButton 
          text="Reset"
          backgroundColor="gray-500"
          className="float-right"
          small
          onClick={() => setFilters({})}                    
        />
        <div className="mb-2">
          <ToggleInput
            className="display: none"
            label="Redeemable Only"
            enabled={filters.isRedeemable}
            setEnabled={() =>
              setFilters({
                ...filters,
                isRedeemable: !filters.isRedeemable,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Total Amount"
            placeholder="Amount"
            value={filters.amount}
            onChange={(e) =>
              setFilters({
                ...filters,
                amount: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Business Id"
            placeholder="Id"
            value={filters.businessId} //where business_id = businessData.list.id ?
            onChange={(e) =>
              setFilters({
                ...filters,
                businessId: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Business Name"
            placeholder="Name"
            value={filters.businessName}
            onChange={(e) =>
              setFilters({
                ...filters,
                businessName: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="From"
            placeholder="Name"
            value={filters.fromName}
            onChange={(e) =>
              setFilters({
                ...filters,
                fromName: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="To"
            placeholder="Name"
            value={filters.toName}
            onChange={(e) =>
              setFilters({
                ...filters,
                toName: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          {/* <SelectInput
          options={businessArray}
          onClear={() => setSingleSelect({})}
          value={null}
          onChange={(e) =>
            setFilters({
              ...filters,
              businessId: e.target.value,
            })
          }
          placeholder="Business Name"
          isSearchable
        /> */}
        </div>
        <div className="mb-2">
          <DateInput
            label="Sent Before"
            value={filters.sentOnMax}
            onChange={(e) =>
              setFilters({
                ...filters,
                sentOnMax: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-2">
          <DateInput
            label="Sent Since"
            value={filters.sentOnMin}
            onChange={(e) =>
              setFilters({
                ...filters,
                sentOnMin: e.target.value,
              })
            }
          />
        </div>
        {/* Add filters here */}
      </div>
    </FilterSection>
  );
};
